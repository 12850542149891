import {Injectable}                                      from '@angular/core';
import {Store}                                           from '../index';
import {IStateStore}                                     from '../../ap-interface';
import {NetTypes, NStatisticLoad, NStatisticLoadSuccess} from 'invoker-transport';
import {filter}                                          from 'rxjs/operators';
import {SafeBehaviorSubject}                             from 'ts-tooling';
import {ApSignalrService}                                from '../../ap-core/services/ap-signalr.service';
import INdiStatistic = Data.NDI.INdiStatistic;
import IGuid = System.IGuid;

export interface INdiState extends IStateStore<INdiStatistic> {
}

@Injectable({providedIn: 'root'})
export class NdiStore extends Store<INdiState> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
    });

    backend.registerObservable(NStatisticLoadSuccess)
      .pipe(filter(d => !!d && Array.isArray(d.Data)))
      .subscribe(d => {
        this.Mutate(s => s.data, () => d.Data as INdiStatistic[]);
        this.SetLoadFinishState();
      });
  }

  public get NdiStatistics$(): SafeBehaviorSubject<INdiStatistic[]> {
    return this.Listen(s => s.data);
  }

  public loadNStatistics(fieldGeomIds: IGuid[]): void {
    this.Mutate(s => s.data, () => []);
    this.SetLoadState();
    this.backend.send(new NStatisticLoad([
      {Type: NetTypes.GUID + '[]', Name: 'fieldGeomIds', Value: fieldGeomIds},
    ]));
  }
}
