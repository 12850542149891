import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';
import {APP_CONFIGURATION}                           from '../../ap-core/config';
import {FileDownloadProcessCallback}                 from '../components/ap-file-upload/ap-file-upload.types';

/**
 * configuration for upload control
 */
export class ApDynformsConfigUpload extends ApDynformsConfigBase<any> {
  controlType = ApDynformsControltype.Upload;
  autoUpload: boolean;
  uploadSaveUrl: string;
  uploadRemoveUrl: string;
  withCredentials: boolean;
  uploadProgressEventHandler: any;
  style: any;
  successEventHandler: any;
  uploadEventHandler: any;
  errorEventHandler: any;
  removeEventHandler: any;
  restrictions: any;
  dropFilesHereText: string;
  selectText: string;
  uploadSelectedFilesText: string;
  clearSelectedFilesText: string;
  multiple: boolean;
  showFilePreview: boolean;
  validateWithForm: boolean;
  onlyUniqueFiles: boolean;
  allowDownload: boolean;
  downloadFileCallback: FileDownloadProcessCallback | undefined;

  constructor(options: {
    key?: string,
    value?: any,
    autoUpload?: boolean,
    uploadSaveUrl?: string,
    uploadRemoveUrl?: string,
    withCredentials?: boolean,
    uploadProgressEventHandler?: any,
    style?: any,
    successEventHandler?: any,
    uploadEventHandler?: any,
    errorEventHandler?: any,
    removeEventHandler?: any;
    restrictions?: any,
    minFileSize?: number,
    maxFileSize?: number,
    dropFilesHereText?: string,
    selectText?: string,
    uploadSelectedFilesText?: string,
    clearSelectedFilesText?: string,
    multiple?: boolean,
    showFilePreview?: boolean,
    validateWithForm?: boolean,
    onlyUniqueFiles?: boolean,
    allowDownload?: boolean,
    downloadFileCallback?: FileDownloadProcessCallback
  } = {}) {
    super(options);
    this.uploadSaveUrl = options.uploadSaveUrl || APP_CONFIGURATION.UploadSaveUrl;
    this.uploadRemoveUrl = options.uploadRemoveUrl || APP_CONFIGURATION.UploadRemoveUrl;
    this.selectText = options.selectText || 'kendo.upload.select';
    this.uploadSelectedFilesText = options.uploadSelectedFilesText || 'UploadUpload';
    this.clearSelectedFilesText = options.clearSelectedFilesText || 'Global__Cancel';
    this.dropFilesHereText = options.dropFilesHereText || 'kendo.upload.dropFilesHere';
    this.autoUpload = options.autoUpload || false;
    this.multiple = options.multiple || true;
    this.showFilePreview = options.showFilePreview || false;
    this.validateWithForm = options.validateWithForm || true;
    this.onlyUniqueFiles = options.onlyUniqueFiles || false;
    this.allowDownload = options.allowDownload || false;
    this.style = options.style || 'background-color: transparent;';
    this.removeEventHandler = options.removeEventHandler || ((_: any) => true);
    this.uploadEventHandler = options.uploadEventHandler || ((_: any) => true);
    this.errorEventHandler = options.errorEventHandler || ((_: any) => true);
    this.successEventHandler = options.successEventHandler || ((_: any) => true);
    this.uploadProgressEventHandler = options.uploadProgressEventHandler || ((_: any) => true);
    this.restrictions = this._setRestrictions(options) || {allowedExtensions: ['.txt', '.csv']};
    this.downloadFileCallback = options.downloadFileCallback || undefined;
  }

  private _setRestrictions(options: any): any | undefined {
    if (!options.restrictions && !Number.isFinite(options.maxFileSize) && !Number.isFinite(options.minFileSize)) {
      return undefined;
    }
    return {
      allowedExtensions: options.restrictions,
      maxFileSize: options.maxFileSize,
      minFileSize: options.minFileSize
    };
  }
}
