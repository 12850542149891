<kendo-combobox *ngIf="(selection$ | async).length > 1"
                [data]="selection$ | async"
                [textField]="'text'"
                [valueField]="'value'"
                [allowCustom]="false"
                [clearButton]="false"
                [value]="selected$ | async"
                (valueChange)="selectedChange.emit($event)"></kendo-combobox>
<kendo-chart [zoomable]="true" [pannable]="true" [ngStyle]="(selection$ | async).length > 1 ? {'height': 'calc(100% - 30px)'} : {}">
  <ng-template kendoChartDonutCenterTemplate>
    {{'Nutrients__TotalArea' | translate}}
    <h3>{{getCenterCount(data)}} {{centerUnit}}</h3>
  </ng-template>
  <kendo-chart-title class="ap-section-header-caption" text="{{title}}" position="top" [color]="strokeColor"></kendo-chart-title>
  <kendo-chart-area background="none"></kendo-chart-area>
  <kendo-chart-tooltip>
    <ng-template kendoChartSeriesTooltipTemplate
                 let-value="value"
                 let-category="category"
                 let-series="series"
                 let-dataItem="data">
      <span [style.color]="tooltipTextColor(data)">
        {{ category }}:<br>{{ value | getRoundNumeric:(settings$ | async).DigitsAfterDecimalPoint }} {{centerUnit}} ({{value / getCenterCount(data) * 100 | getRoundNumeric:(settings$ | async).DigitsAfterDecimalPoint}}%)
      </span>
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-series>
    <kendo-chart-series-item *ngFor="let series of data; let outermost = last;"
                             type="donut" [startAngle]="150"
                             [name]="series.name" [data]="series.data"
                             field="value" categoryField="category" colorField="color">
      <kendo-chart-series-item-labels *ngIf="outermost"
                                      [position]="'outsideEnd'" [background]="'none'"
                                      [content]="labelContent" [color]="strokeColor">
      </kendo-chart-series-item-labels>
    </kendo-chart-series-item>
  </kendo-chart-series>
  <kendo-chart-legend [title]="legendTitle" [position]="'bottom'" [labels]="{color: strokeColor}" [inactiveItems]="{labels:{color: inactiveColor}}">
  </kendo-chart-legend>
</kendo-chart>
