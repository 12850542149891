import {CategoryBaseUnit, SeriesType} from '@progress/kendo-angular-charts';

/**
 * Generic chart configuration
 */
export class GenericChartConfig {
  /**
   * there can be multiple data series per chart. Each series might have a different color and type (line, column etc.)
   */
  seriesConfigs: Array<GenericSeriesConfig> = [];
  title = '';
  zoomable = true;
  transitions = true;
  pannable = true;
  tooltipDelegate?: (item: any, chart: GenericChartConfig) => string;
  noteDelegate?: (item: any, chart: GenericChartConfig) => string;
  noteField?: string;
  xAxisConfig = new GenericXAxisConfig();
  yAxisConfigs: GenericYAxisConfig[] = [];
  /**
   * Aligns the additional value axis to the left or right.
   * Example from telerik:
   * https://www.telerik.com/kendo-angular-ui/components/charts/elements/axes
   * Align the first two value axes to the left
   * and the last two to the right.
   *
   * Right alignment is done by specifying a
   * crossing value greater than or equal to
   * the number of categories.
   * public crossingValues: number[] = [0, 0, 10, 10];
   */
  axisCrossingValue: number[] = [];
}

/**
 * Generic chart's x-axis configuration.
 * This axis is also called category-axis and there is only 1 instance allowed.
 */
export class GenericXAxisConfig {
  title: string;
  type: 'category' | 'date';
  baseUnit: CategoryBaseUnit;
  min: number;
  max: number;
  categories: string[];
}

/**
 * The configuration of y-/value axis.
 * Each chart may have multiple value axis.
 * Ref.: https://www.telerik.com/kendo-angular-ui/components/charts/elements/axes
 */
export class GenericYAxisConfig {
  id: string;
  title: string;
  type: 'log' | 'numeric';
  min: number;
  max: number;
  labelDelegate?: (value: number) => string;
}

/**
 * Generic chart's data series configuration.
 * This actually configures the series appearance and holds the series values
 */
export class GenericSeriesConfig {
  axisId: string;
  seriesData: Array<GenericSeriesData> = [];
  name: string;
  type: SeriesType = 'line';
  color: string;
}

/**
 * The actual series data object.
 * It contains of the x and y value and an additionalValue (might be a json)
 * which might be necessary when showing more detailed information in a tooltip.
 */
export class GenericSeriesData {
  xValue: number | string;
  yValue: number;
  additionalData: string;
}
