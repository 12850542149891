import {OperationModesEnum} from '../../../stores/common/operation-modes.store';
import {
  ApplicationSettingsStore,
  IApplicationSettingsParameter
}                           from '../../../stores/agronomic/application-settings-store.service';
import {NotifyStore}        from '../../../stores/dialog/notify.store';
import {Injectable}         from '@angular/core';
import {ApStringService}    from '../../../ap-utils/service/ap-string.service';
import {
  ApTranslationService
}                           from '../../../ap-utils/service/ap-translation.service';
import IApplicationSettings = Data.Agronomic.IApplicationSettings;
import ApValidationLevel = Data.Api.Validation.ApValidationLevel;
import IApValidationResult = Data.Api.Validation.IApValidationResult;

@Injectable()
export class NFertilizationApplicationSettingsService {
  constructor(private notifyStore: NotifyStore,
              private applicationSettingsStore: ApplicationSettingsStore,
              private stringService: ApStringService,
              private translationService: ApTranslationService) {
  }

  public getApplicationSettings(operationModeKey: OperationModesEnum, eppoCode: string): IApplicationSettings | undefined {
    if ((operationModeKey !== OperationModesEnum.ApplicationMap
        && operationModeKey !== OperationModesEnum.ApplicationMapAbsolute
        && operationModeKey !== OperationModesEnum.Pp_Target_Map)
      || !eppoCode) {
      return undefined;
    }
    const applicationSettingsByOperationMode = this.applicationSettingsStore.Applications.filter(x => x.OperationMode.Key === operationModeKey);
    if (applicationSettingsByOperationMode.length <= 0) {
      return undefined;
    }
    return applicationSettingsByOperationMode.find(x => {
      return x.EppoGroup.EppoCodes.some(y => y === eppoCode);
    });
  }

  public isApplicationSettingValidForField(ecValue: number | undefined,
                                           applicationSettings: IApplicationSettings | undefined): boolean {
    if (!applicationSettings || !Number.isFinite(ecValue)) {
      return false;
    }
    return applicationSettings.EcMin <= ecValue && ecValue <= applicationSettings.EcMax;
  }

  public onApplicationSettingsTextWarning(selectedEcValues: number[], applicationSettings: IApplicationSettings): void {
    if (!selectedEcValues || selectedEcValues.length <= 0 || !applicationSettings) {
      return;
    }
    let warningText: string;
    const validSelectedEcValues = selectedEcValues.filter(x => {
      return this.isApplicationSettingValidForField(x, applicationSettings);
    });
    if (validSelectedEcValues.length > 0 && selectedEcValues.length !== validSelectedEcValues.length) {
      const translatedText = this.translationService.translate('PlanCreationOutsideOfLimitation');
      warningText = this.stringService.format(translatedText, validSelectedEcValues.length, selectedEcValues.length);
    } else {
      return;
    }
    this.notifyStore.addMessage({
      Level: ApValidationLevel.Warning,
      Counter: 1,
      ErrorKey: warningText,
      DisplayTimeout: 5
    } as IApValidationResult);
  }

  public getApplicationSettingParameters(applicationSettings: IApplicationSettings | undefined): IApplicationSettingsParameter | undefined {
    if (!applicationSettings?.Parameter) {
      return undefined;
    }
    return JSON.parse(applicationSettings.Parameter) as IApplicationSettingsParameter;
  }
}
