import {Injectable}                                                                  from '@angular/core';
import {
  ApplicationDatesData,
  TooltipsData
}                                                                                    from '../components/configs/nutrient-management-common.types';
import {
  ApDateService,
  DateSort
}                                                                                    from '../../ap-core/services/ap-date-service';
import {
  ApTooltipContentService
}                                                                                    from '../../ap-utils/service/ap-tooltip-content.service';
import {
  CampaignYearService
}                                                                                    from '../../services/data/campaign-year.service';
import {ObjectFactory}                                                               from 'ts-tooling';
import {
  ApNutrientPlanningComponent
}                                                                                    from '../../entry-components/ap-nutrients-management-entry-components/ap-nutrient-planning.component';
import {
  LoginStore
}                                                                                    from '../../stores/login/login.store';
import {
  BasicFertilisationSummariesStore
}                                                                                    from '../../stores/taskmanagement/basic.fertilisation.summaries.store';
import {
  FormStore
}                                                                                    from '../../stores/layout/form.store';
import {MapStore}                                                                    from '../../stores/map/map.store';
import {
  RouterStore
}                                                                                    from '../../stores/router/router.store';
import {
  FieldStore
}                                                                                    from '../../stores/farm/field.store';
import {
  StatisticStore
}                                                                                    from '../../stores/statistic/statistic.store';
import {
  ApOperationMode
}                                                                                    from '../../ap-interface/enums/ap-operation-mode.enum';
import {
  ApLegendScrollerService
}                                                                                    from '../../map/components/layer-controller/ap-legend-scroller.service';
import {
  NutrientManagementService
}                                                                                    from './nutrient-management.service';
import {
  ApNutrientBookingComponent
}                                                                                    from '../../entry-components/ap-nutrients-management-entry-components/ap-nutrient-booking.component';
import {
  IApNutrientBookingComponentData
}                                                                                    from '../../entry-components/ap-nutrients-management-entry-components/ap-nutrient-booking-configs/nutrient-booking-types';
import {skip}                                                                        from 'rxjs/operators';
import {
  ModalDialogStore
}                                                                                    from '../../stores/dialog/modal.dialog.store';
import {
  ModalDialogPresets
}                                                                                    from '../../stores/dialog/modal.dialog.presets';
import {
  PlanningEntryComponentDataType
} from '../../entry-components/ap-nutrients-management-entry-components/ap-nutrient-planning-configs/nutrient-planning-types';
import {BasicFertilisationPlanBooksSaveFail, BasicFertilisationPlanBooksSaveSuccess} from 'invoker-transport';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  BasicFertilisationSummariesSaveFail,
  BasicFertilisationSummariesSaveSuccess
} from '../../../../projects/invoker-transport/src/lib/actions/taskmanagement';
import IGuid = System.IGuid;
import IBasicFertilisationSummaries = Data.TaskManagement.IBasicFertilisationSummaries;
import IBasicFertilisationPlanBooks = Data.TaskManagement.IBasicFertilisationPlanBooks;
import {
  GenericChartConfig, GenericSeriesConfig,
  GenericSeriesData, GenericYAxisConfig
} from '../../statistics/components/charts/ap-generic-chart.types';
import {GetRoundNumericPipe} from '../../ap-utils';
import {CropGroupStore} from '../../stores/base-data/crop.groups.store';
import {FertilizerService} from '../../services/data/fertilizer.service';
import {GetRoundNumericService} from '../../ap-utils/service/get-round-numeric.service';
import {FertilizerStore} from '../../stores/base-data/fertilizer.store';

@Injectable({providedIn: 'root'})
export class BaseFertilizationCommonService {
  private _triggerGridUpdate = new BehaviorSubject<boolean>(true);
  private _previouslySelectedSummaryId: IGuid | undefined;
  private _mainComponentSelectionAvailability = true;

  public gridUpdate$: Observable<boolean> = this._triggerGridUpdate.asObservable();

  constructor(private mapStore: MapStore,
              private formStore: FormStore,
              private loginStore: LoginStore,
              private fieldStore: FieldStore,
              private routerStore: RouterStore,
              private statisticStore: StatisticStore,
              private modalDialogStore: ModalDialogStore,
              private modalDialogPresets: ModalDialogPresets,
              private basicFertilisationSummariesStore: BasicFertilisationSummariesStore,
              private dateService: ApDateService,
              private campaignYearService: CampaignYearService,
              private legendScrollerService: ApLegendScrollerService,
              private tooltipContentService: ApTooltipContentService,
              private nutrientManagementService: NutrientManagementService,
              private roundNumericPipe: GetRoundNumericPipe,
              private cropGroupStore: CropGroupStore,
              private fertilizerStore: FertilizerStore,
              private fertilizerService: FertilizerService,
              private roundNumericService: GetRoundNumericService ) {
  }

  public get selectedSummaryId(): IGuid | undefined {
    return this._previouslySelectedSummaryId;
  }

  public get mainComponentSelectionAvailability(): boolean {
    return this._mainComponentSelectionAvailability;
  }

  public setMainComponentSelectionAvailability(availableToUpdate: boolean): void {
    this._mainComponentSelectionAvailability = availableToUpdate;
  }

  public setIsAllowGridItemsUpdate(allow: boolean): void {
    this._triggerGridUpdate.next(allow);
  }

  public setSelectedSummaryKey(summaryId: IGuid | undefined): void {
    this._previouslySelectedSummaryId = summaryId;
  }

  public redirectSummaryToShowView(summaryId: IGuid, isBooked: boolean,
                                   returnUrl: string, fieldId: IGuid | null = null): void {
    this._redirectToTaskManagement(true, summaryId, isBooked, returnUrl, fieldId);
  }

  public redirectSummaryToExportView(summaryId: IGuid, isBooked: boolean,
                                     returnUrl: string, fieldId: IGuid | null = null): void {
    this._redirectToTaskManagement(false, summaryId, isBooked, returnUrl, fieldId);
  }

  public replanSummary(summary: IBasicFertilisationSummaries): void {
    const copiedSummary = ObjectFactory.Copy(summary);
    const planBooksToDelete = copiedSummary.BasicFertilisationPlanBooks.filter(x => !x.DeletedAt && !x.BookedAt).map(x => x.Id);
    if (!planBooksToDelete || planBooksToDelete.length <= 0) {
      return;
    }
    this.loginStore.setLongOperationStatus(true);
    this.setIsAllowGridItemsUpdate(false);
    this.basicFertilisationSummariesStore.deleteBasicFertilisationPlanBooks(copiedSummary.Id, planBooksToDelete, false, true)
      .watchStream({
        action: BasicFertilisationPlanBooksSaveSuccess,
        todo: payload => {
          this.loginStore.setLongOperationStatus(false);
          if (!payload.Error) {
            this.formStore.openForm({
              component: ApNutrientPlanningComponent,
              data: {
                Summary: copiedSummary,
                Type: PlanningEntryComponentDataType.Edit
              }
            });
          }
        },
      })
      .watchStream({
        action: BasicFertilisationPlanBooksSaveFail,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
          this.loginStore.setLongOperationStatus(false);
        }
      });
  }

  public saveSummary(summary: IBasicFertilisationSummaries): void {
    const copiedSummary = ObjectFactory.Copy(summary);
    this.setIsAllowGridItemsUpdate(false);
    this.basicFertilisationSummariesStore.createBasicFertilisation(copiedSummary, true, true)
      .watchStream({
        action: BasicFertilisationSummariesSaveSuccess,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        },
      })
      .watchStream({
        action: BasicFertilisationSummariesSaveFail,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        }
      });
  }

  public revertSummary(summary: IBasicFertilisationSummaries): void {
    const copiedSummary = ObjectFactory.Copy(summary);
    copiedSummary.DeletedAt = undefined;
    copiedSummary.DeletedBy = undefined;
    this.setIsAllowGridItemsUpdate(false);
    this.basicFertilisationSummariesStore.updateBasicFertilisation(copiedSummary, false, true)
      .watchStream({
        action: BasicFertilisationSummariesSaveSuccess,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        },
      })
      .watchStream({
        action: BasicFertilisationSummariesSaveFail,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        }
      });
  }

  public revertSummaryPlanBooks(planBooks: IBasicFertilisationPlanBooks[]): void {
    const copiedPlanBooks = ObjectFactory.Copy(planBooks);
    copiedPlanBooks.forEach(item => {
      item.DeletedAt = undefined;
      item.DeletedBy = undefined;
    });
    this.setIsAllowGridItemsUpdate(false);
    this.basicFertilisationSummariesStore.updateBasicFertilisationPlanBooks(copiedPlanBooks, false, true)
      .watchStream({
        action: BasicFertilisationPlanBooksSaveSuccess,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        },
      })
      .watchStream({
        action: BasicFertilisationPlanBooksSaveFail,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        }
      });
  }

  public bookPlanBooks(summary: IBasicFertilisationSummaries, fieldId: IGuid | null = null): void {
    this.formStore.openForm({
      component: ApNutrientBookingComponent,
      data: {
        Summary: summary,
        FieldIdToSelect: fieldId,
        IsBooked: false
      } as IApNutrientBookingComponentData
    });
  }

  public unbookPlanBooks(summary: IBasicFertilisationSummaries, fieldId: IGuid | null = null): void {
    this.formStore.openForm({
      component: ApNutrientBookingComponent,
      data: {
        Summary: summary,
        FieldIdToSelect: fieldId,
        IsBooked: true
      } as IApNutrientBookingComponentData
    });
  }

  public deleteSummary(summary: IBasicFertilisationSummaries): void {
    this._deleteDialog(summary, (dataItem: IBasicFertilisationSummaries) => {
      const deletedAt = new Date();
      const deletedBy = this.loginStore.UserId;
      let canSummaryDelete = true;
      const copiedSummary = ObjectFactory.Copy(dataItem);
      copiedSummary.BasicFertilisationPlanBooks.forEach(i => {
        if (i.DeletedAt) {
          return;
        }
        if (!i.BookedBy) {
          i.DeletedBy = deletedBy;
          i.DeletedAt = deletedAt;
        } else {
          canSummaryDelete = false;
        }
      });
      if (canSummaryDelete) {
        copiedSummary.DeletedAt = deletedAt;
        copiedSummary.DeletedBy = deletedBy;
      }
      this.basicFertilisationSummariesStore.updateBasicFertilisation(copiedSummary, true, true);
    });
  }

  public unbookSummaryPlanBook(summary: IBasicFertilisationSummaries, planBookId: IGuid): void {
    const planBook = summary.BasicFertilisationPlanBooks.find(x => x.Id === planBookId);
    if (!planBook) {
      return;
    }
    const copiedPlanBook = ObjectFactory.Copy(planBook);
    copiedPlanBook.BookedAt = undefined;
    copiedPlanBook.BookedBy = undefined;
    this.basicFertilisationSummariesStore.updateBasicFertilisationPlanBooks([copiedPlanBook]);
  }

  public deleteSummaryPlanBook(summary: IBasicFertilisationSummaries, planBookId: IGuid): void {
    this._deleteDialog(summary, (dataItem: IBasicFertilisationSummaries) => {
      const deletedAt = new Date();
      const deletedBy = this.loginStore.UserId;
      const copiedSummary = ObjectFactory.Copy(dataItem);
      copiedSummary.BasicFertilisationPlanBooks.forEach(planBook => {
        if (planBookId === planBook.Id && planBook.BookedBy === null) {
          planBook.DeletedBy = deletedBy;
          planBook.DeletedAt = deletedAt;
        }
      });
      const isAtLeastOnePlanBookExist = copiedSummary.BasicFertilisationPlanBooks.some(x => !x.DeletedAt);
      if (!isAtLeastOnePlanBookExist) {
        copiedSummary.DeletedAt = deletedAt;
        copiedSummary.DeletedBy = deletedBy;
        this.basicFertilisationSummariesStore.updateBasicFertilisation(copiedSummary);
      } else {
        const planBook = copiedSummary.BasicFertilisationPlanBooks.find(x => x.Id === planBookId && !x.BookedBy);
        this.basicFertilisationSummariesStore.updateBasicFertilisationPlanBooks([ObjectFactory.Copy(planBook)]);
      }
    });
  }

  public revertLegends(): void {
    const legends = [
      'NutrientPlanningLegend',
      'TaskMgmtLegend',
      'NeedLegend'
    ];
    legends.forEach(legend => {
      this.mapStore.Legends.addLegend(this.mapStore.Legends[legend]);
    });
  }

  public handleLayers(disableLayers: string[], enableLayers: string[]): void {
    const layers = [
      'NutrientPlanningLayer',
      'TaskManagementLayer',
      'NeedLayer'
    ];
    const toDisable = disableLayers?.filter(x => !!x) ?? [];
    const toEnable = enableLayers?.filter(x => !!x) ?? [];
    if (toDisable.length <= 0 && toEnable.length <= 0) {
      return;
    }
    layers.forEach(layer => {
      const layerName = this.mapStore.Layers[layer]?.name;
      if (!layerName) {
        return;
      }
      if (toDisable.includes(layerName)) {
        this.mapStore.Layers[layer].clear();
        this.mapStore.Layers[layer].Visibility = false;
      }
      if (toEnable.includes(layerName)) {
        this.mapStore.Layers[layer].Visibility = true;
      }
    });
  }

  public handleLegends(selectedItemsLength: number, disableLegends: string[] = []): void {
    const legends = [
      'NutrientPlanningLegend',
      'TaskMgmtLegend',
      'NeedLegend',
    ];
    const layers = [
      'NutrientPlanningLayer',
      'TaskManagementLayer',
      'NeedLayer'
    ];
    const toDisable = disableLegends?.filter(x => !!x) ?? [];
    if (!selectedItemsLength || selectedItemsLength <= 0) {
      legends.forEach(legend => {
        this.mapStore.Legends.removeLegend(this.mapStore.Legends[legend]);
      });
    } else {
      legends.forEach((legend, index) => {
        if (this.mapStore.Layers[layers[index]]?.Visibility
          && this.mapStore.Legends[legend]?.title
          && !toDisable.includes(legend)) {
          this.mapStore.Legends.addLegend(this.mapStore.Legends[legend]);
        }
      });
    }
    legends.forEach(legend => {
      if (toDisable.includes(legend)) {
        this.mapStore.Legends.removeLegend(this.mapStore.Legends[legend]);
      }
    });
  }

  public onSelectionChange(summary: IBasicFertilisationSummaries | null, isBooking: boolean): void {
    if (summary) {
      this.statisticStore.removeStatisticData();
      const currentUrl = this.routerStore.Listen(s => s.url).getValue();
      let planBooks: IBasicFertilisationPlanBooks[];
      if (isBooking) {
        planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy != null);
      } else {
        planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy == null);
      }
      const ids = planBooks.map(_ => _.Id);
      const fieldsIds = planBooks.map(_ => _.FieldId);
      const nutrient = planBooks[0].Element;
      const productId = planBooks[0].Product_Id;
      let n = nutrient.ShortDescription;
      if (n === 'CaO') {
        n = 'ph';
      }
      if (summary.Statistic != null) {
        this.mapStore.Legends.onNutrientPlanningChanged.emit({
          ProductId: productId,
          ElementId: nutrient.Id,
          Statistic: summary.Statistic
        });
        this.mapStore.Layers.NutrientPlanningLayerSourceInit(currentUrl, n, ids);
        setTimeout(() => this._updateNutrientPlanningLayer(planBooks[0]), 1);
      }
      this.fieldStore.changeSelectedField(fieldsIds.map((item) => item.toString()));
      // bind visibility of taskmanagement layer (showing field border of planning) to the nutrient planning layer
      // Furthermore move the nutrient planning layer (contours) behind the field layer to see the current field geometry
      // as well as the planning geometry and contours
      this.mapStore.Legends.refreshLegend(this.mapStore.Legends.NutrientPlanningLegend, true);
      this.mapStore.Layers.NutrientPlanningLayer.layer.setZIndex(this.mapStore.Layers.FieldsLayer.layer.getZIndex() - 1);
      this.mapStore.Layers.TaskManagementLayer.replaceSource(planBooks, this.fieldStore);
    } else {
      this.mapStore.Legends.onNutrientPlanningChanged.emit(null);
      this.statisticStore.removeStatisticData();
      this.fieldStore.zoomFarmFields();
    }
  }

  public getPlanBooksFieldsCount(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): string {
    if (!planBooks || planBooks.length <= 0) {
      return '0/0';
    }
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    return filteredPlanBooks.length + '/' + nonDeletedPlanBooks.length;
  }

  public getFieldIds(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): string[] {
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    if (!filteredPlanBooks || filteredPlanBooks.length <= 0) {
      return [];
    }
    return filteredPlanBooks.map(x => x.FieldId?.toString());
  }

  public getApplicationDatesData(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): ApplicationDatesData | null {
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    if (!filteredPlanBooks || filteredPlanBooks.length <= 0) {
      return null;
    }
    const planBooksApplicationDates = this._getPlanBookApplicationDates(planBooks, isBooked);
    const uniqueApplicationDates = this.dateService.getUniqDates(planBooksApplicationDates, DateSort.Ascending);
    return {
      Tooltip: this.tooltipContentService.planBooksApplicationDatesTooltip(uniqueApplicationDates.map(x => ({ApplicationDate: x}))),
      IsMoreThanOneUniqDate: uniqueApplicationDates.length > 1,
      NewestDate: uniqueApplicationDates[uniqueApplicationDates.length - 1]
    };
  }

  public getFieldCropsTooltip(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): string {
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    if (!filteredPlanBooks || filteredPlanBooks.length <= 0) {
      return '';
    }
    const tooltipData = filteredPlanBooks.map(x => {
      const applicationDate = new Date(x.ApplicationDate);
      const campaignYear = this.campaignYearService.getCampaignYearByDate(applicationDate);
      const item: TooltipsData = {
        FieldId: x.FieldId,
        ApplicationDate: applicationDate,
        Year: campaignYear.CampaignYear
      };
      return item;
    });
    return this.tooltipContentService.getFieldsCropsTooltipForBaseFertilization(tooltipData);
  }

  /**
   * Generates a chartConfig and series data for all given planbooks
   * Grouped by product (=series)
   * Grouped by Month/Year (=category/y-axis)
   * @param planBooks the selected items
   * @private
   */
  public generateStatChartConfig(planBooks: IBasicFertilisationPlanBooks[]): GenericChartConfig {
    // setup basic chart configuration and its axes
    // even if we have a relation between amount and a date/month
    // we cannot use type 'date' here because it would generate gaps for all the
    // empty months between the plans. Therefore we need to use 'category' type
    const chartConfig = new GenericChartConfig();
    chartConfig.pannable = false;
    chartConfig.zoomable = false;
    chartConfig.xAxisConfig.type = 'category';
    chartConfig.axisCrossingValue = [];
    chartConfig.xAxisConfig.categories = [];
    chartConfig.noteDelegate = (item: GenericSeriesData, _: GenericChartConfig) =>
      `${this.roundNumericPipe.transform(item?.yValue, 0)} ${item?.additionalData}`;

    const productColors: { [productId: string]: string; } = {};

    planBooks = planBooks.sort((pB1, pB2) =>
      new Date(pB1.ApplicationDate).getTime() - new Date(pB2.ApplicationDate).getTime());
    const planBooksGroupedByMonth = planBooks.GroupBy(pB => {
      const applDate = new Date(pB.ApplicationDate);
      return `${applDate.getMonth() + 1}/${applDate.getFullYear()}`;
    });
    const planBooksGroupedByProduct = planBooks.GroupBy(pB => pB.Product_Id);
    const availableColors = this.cropGroupStore?.getCropGroups()?.map(c => c.Colordefault);

    for (const monthYearExpr of Object.keys(planBooksGroupedByMonth)) {
      const planBooksForMonth = planBooksGroupedByMonth[monthYearExpr] as IBasicFertilisationPlanBooks[];
      if (!chartConfig.xAxisConfig.categories.Any(c => c === monthYearExpr)) {
        chartConfig.xAxisConfig.categories.push(monthYearExpr);
      }

      for (const productId of Object.keys(planBooksGroupedByProduct)) {
        const seriesPlanBooksForMonthAndProduct = planBooksForMonth.FindAll(pB => pB.Product_Id.toString() === productId);
        // here we need the product even if it is not contained in the currently processed month
        // kendo charts require to have a value for every category (=month) for every series
        const product = this.fertilizerStore.getFertilizer(+productId);
        const productName = this.fertilizerService.getFertilizerProductName(product);
        const productUnit = this.fertilizerService.getFertilizerUnit(product);
        let seriesConfig = chartConfig.seriesConfigs.FirstOrDefault(s => s.name === productName);
        // if there is no series for this product => create a new one
        if (seriesConfig == null) {
          seriesConfig = new GenericSeriesConfig();
          seriesConfig.type = 'column';
          seriesConfig.name =  productName;
          seriesConfig.axisId = productUnit;
          if (!Object.keys(productColors).Any(p => p === productId)) {
            productColors[productId] = availableColors[Object.keys(productColors).length % availableColors.length];
          }
          seriesConfig.color = productColors[productId];
        }

        // in case the product is not contained in the current month => 0
        const sumProductByMonth = seriesPlanBooksForMonthAndProduct?.reduce((sum, pB) =>
          sum + Math.abs(this.nutrientManagementService.getCalculateSumFertilizerBySinglePlanBook(pB)), 0);
        const seriesData = new GenericSeriesData();
        seriesData.xValue = monthYearExpr;
        seriesData.yValue = this.roundNumericService.roundAsNumber(sumProductByMonth, 0);
        seriesData.additionalData = productUnit;
        seriesConfig.seriesData.push(seriesData);

        // for each new unit we create a new y-axis
        if (!chartConfig.yAxisConfigs.Any(c => c.id === productUnit)) {
          const yAxisConfig = new GenericYAxisConfig();
          yAxisConfig.title = productUnit;
          yAxisConfig.id = productUnit;
          yAxisConfig.labelDelegate =  (value) => this.roundNumericPipe.transform(value, 0);
          chartConfig.yAxisConfigs.push(yAxisConfig);
          chartConfig.axisCrossingValue.push(chartConfig.yAxisConfigs.length % 2 === 0 ? 0 : Number.MAX_SAFE_INTEGER);
        }
        // add or replace the series config and its data
        if (!chartConfig.seriesConfigs.Any(s => s.name === productName)) {
          chartConfig.seriesConfigs.push(seriesConfig);
        } else {
          chartConfig.seriesConfigs.Replace(s => s.name === seriesConfig.name, seriesConfig);
        }
      }
    }
    return chartConfig;
  }

  // #region "private Methods"

  private _getPlanBookApplicationDates(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): Date[] {
    if (!planBooks || planBooks.length <= 0) {
      return [];
    }
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    const planBooksApplicationDates = filteredPlanBooks.filter(x => x.ApplicationDate)
      .map(x => x.ApplicationDate);
    return planBooksApplicationDates.map(x => {
      const farmDate = this.dateService.toFarmDate(new Date(x)).toDate();
      return this.dateService.getDateMidnight(farmDate);
    });
  }

  private _updateNutrientPlanningLayer(planBook: IBasicFertilisationPlanBooks): void {
    if (this.mapStore.Layers.NutrientPlanningLayer.Legend && this.mapStore.Layers.NutrientPlanningLayer?.Legend()?.selectionLists?.length > 0) {
      const legendItems = this.mapStore.Layers.NutrientPlanningLayer.Legend().selectionLists.FirstOrDefault();
      const selectedLegendElement = (legendItems.values as any[])
        .Find(_ => _?.value === this._getElementShort(planBook, planBook.Operation_Mode.Id === ApOperationMode.Const).toUpperCase()); // To upper case because we need product instead of element by default
      if (selectedLegendElement) {
        legendItems.selectedValue.next(selectedLegendElement);
        this.legendScrollerService.selectionChange.emit({
          legend: this.mapStore.Legends.NutrientPlanningLegend,
          selectionKeys: this.legendScrollerService.generateSelectionKey(this.mapStore.Legends.NutrientPlanningLegend).Split('_'),
        });
      }
    }
  }

  private _getElementShort(planBook: IBasicFertilisationPlanBooks, isConstant: boolean): string {
    if (isConstant) {
      const leadingElement = this.nutrientManagementService.getFertilizerLeadingElement(planBook.Product_Id);
      switch (leadingElement) {
        case 1:
          return 'p';
        case 2:
          return 'k';
        case 3:
          return 'mg';
        case 4:
          return 'ph';
      }
      return 'p';
    }
    let elementShort = planBook?.Element?.ShortDescription?.toLowerCase();
    if (!elementShort || elementShort === '') {
      elementShort = 'p';
    } else if (elementShort === 'cao') {
      elementShort = 'ph';
    }

    return elementShort;
  }

  private _deleteDialog(dataItem: any, deleteCallback: (dataItem: any) => any): void {
    this.modalDialogStore.setModalDialogData(this.modalDialogPresets.DeleteDialog());
    const modalDialogSubscription = this.modalDialogStore.Listen(s => s.result)
      .pipe(skip(1))
      .subscribe((result) => {
        modalDialogSubscription.unsubscribe();
        if (result.key === 'delete') {
          deleteCallback(dataItem);
        }
      });
  }

  private _redirectToTaskManagement(isShowView: boolean, summaryId: IGuid, isBooked: boolean, returnUrl: string, fieldId: IGuid | null): void {
    if (!returnUrl) {
      returnUrl = '';
    }
    const navigateObject = {
      id: summaryId,
      url: returnUrl
    };
    if (isShowView) {
      navigateObject['show'] = true;
    }
    if (isBooked) {
      navigateObject['bookings'] = true;
    }
    if (fieldId) {
      navigateObject['fieldId'] = fieldId;
    }
    this.routerStore.navigate(['task-management/overview'], navigateObject).then();
  }

  //#endregion "private Methods"
}
