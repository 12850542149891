import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
}                                                                    from '@angular/core';
import {
  ClearEvent,
  ErrorEvent,
  FileRestrictions,
  FileState,
  RemoveEvent,
  SelectEvent,
  SuccessEvent,
  UploadComponent,
  UploadEvent,
  UploadProgressEvent
}                                                                    from '@progress/kendo-angular-upload';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {BehaviorSubject, interval, Subscription}                     from 'rxjs';
import {
  Base64FileData,
  DefaultRemoveRequestUrl,
  DefaultUploadRequestUrl,
  FileDownloadProcessCallback,
  FileInfoData,
  FilePreviewModalDialogData,
  PreviewFileData
}                                                                    from './ap-file-upload.types';
import {distinctUntilChanged, filter, map}                           from 'rxjs/operators';
import {MediaFileService}                                            from '../../../services/common/media-file.service';
import {FileType, ThumbnailParam}                                    from '../../../types/file.types';
import {FileService}                                                 from '../../../services/common/file.service';
import {ObjectFactory}                                               from 'ts-tooling';
import {
  ApFileUploadComponentService
}                                                                    from '../../services/ap-file-upload-component.service';
import {ItemChangedEvent}                                            from '@progress/kendo-angular-scrollview';
import {saveAs}                                                      from '@progress/kendo-file-saver';
import {FileUploadHtmlService}                                       from '../../services/ap-file-upload-html.service';
import {DomSanitizer, SafeUrl}                                       from '@angular/platform-browser';
import {
  DialogComponent
}                                                                    from '@progress/kendo-angular-dialog/dist/es2015/dialog/dialog.component';

@Component({
  selector: 'ap-file-upload',
  templateUrl: 'ap-file-upload.component.html',
  styleUrls: ['ap-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApFileUploadComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private fileService: FileService,
              private mediaFileService: MediaFileService,
              private fileUploadHtmlService: FileUploadHtmlService,
              private fileUploadComponentService: ApFileUploadComponentService,
              private elementRef: ElementRef,
              private sanitizer: DomSanitizer) {
  }

  public get getAllFiles(): FileInfoData[] {
    return (this.uploadFileComponent?.fileList?.filesFlat as FileInfoData[]) ?? [];
  }

  @Input()
  public id: string;
  @Input()
  public control: FormControl;
  @Input()
  public saveUrl = DefaultUploadRequestUrl;
  @Input()
  public removeUrl = DefaultRemoveRequestUrl;
  @Input()
  public restrictions: FileRestrictions = {allowedExtensions: ['.txt', '.csv']};
  @Input()
  public autoUpload = false;
  @Input()
  public multiple = false;
  @Input()
  public showFilePreview = false;
  @Input()
  public viewFileOnClick = false;
  @Input()
  public withCredentials = false;
  @Input()
  public validateWithForm = false;
  @Input()
  public allowOnlyUniqFiles = false;
  @Input()
  public allowDownload = false;
  @Input()
  public disabled = false;
  @Input()
  public style: any = undefined;
  @Input()
  public dropFilesHereText = 'kendo.upload.dropFilesHere';
  @Input()
  public selectText = 'kendo.upload.select';
  @Input()
  public uploadSelectedFilesText = 'UploadUpload';
  @Input()
  public clearSelectedFilesText = 'Global__Cancel';
  @Input()
  public fileDownloadProcessCallback: FileDownloadProcessCallback | undefined;

  @Output()
  public fileUploadProgress = new EventEmitter<any>();
  @Output()
  public fileUploadSuccess = new EventEmitter<any>();
  @Output()
  public fileUpload = new EventEmitter<any>();
  @Output()
  public fileSelect = new EventEmitter<any>();
  @Output()
  public fileRemove = new EventEmitter<any>();
  @Output()
  public fileError = new EventEmitter<any>();

  @ViewChild('uploadFileComponent', {read: ElementRef})
  public uploadComponentElementRef: ElementRef;

  public filePreviewModalDialogData: FilePreviewModalDialogData;
  public previewFilesData: PreviewFileData[] = [];

  protected readonly FileType = FileType;

  @ViewChild('uploadFileComponent')
  private uploadFileComponent: UploadComponent;
  @ViewChild('dialogComponent')
  public dialogComponent: DialogComponent;

  private _viewContainer: HTMLElement;
  private _readyToUploadStatusClassName = 'ready-to-upload-status';
  private _initialFiles: FileInfoData[] = [];
  private _thumbnailSettings: ThumbnailParam;
  private _subscriptions: Subscription[] = [];
  private _isModalDialogOpened = false;

  public ngOnInit(): void {
    this._viewContainer = this.elementRef.nativeElement.closest('ap-view-container');
    this._thumbnailSettings = this._settingsForThumbnail();
    this.filePreviewModalDialogData = this._generateFilePreviewModalDialogData();
    if (this.validateWithForm) {
      this.control.addValidators(this._filesValidator());
      this.control.updateValueAndValidity();
    }
    this._subscriptions.push(
      interval(250).pipe(
        map(() => this.uploadFileComponent?.fileList?.filesFlat?.map(file => ({...file} as FileInfoData))),
        filter(files => !!files),
        distinctUntilChanged((previous, current) => ObjectFactory.Equal(previous, current)),
      ).subscribe(files => {
        this.control.setValue(files, {emitEvent: true, emitModelToViewChange: false});
      })
    );
  }

  public ngAfterViewInit(): void {
    this._initialFiles = this.uploadFileComponent?.fileList?.filesFlat?.map(file => ({...file} as FileInfoData)) ?? [];
    if (this._initialFiles.length > 0) {
      this._adjustFileHtmlData(this._initialFiles).then();
    }
  }

  public ngOnDestroy(): void {
    this._clearPreviewFilesData(this.getAllFiles);
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this._resizeFilePreviewModal();
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    this._closeFilePreviewModal(event.target);
  }

  /**
   * Handles file selection events by processing selected files,
   * adding previews if enabled, and updating internal files collection.
   * Emits fileSelect event for parent component handling.
   * @param event File selection event containing selected files information
   * @public
   */
  public onFileSelect(event: SelectEvent): void {
    const fileDataInfos = event.files as FileInfoData[];
    this._onSelectFilesValidation(fileDataInfos);
    this._adjustInformationForFilesOnSelect(fileDataInfos);
    this._adjustFileHtmlData(fileDataInfos).then();
    this.fileSelect.emit(event);
  }

  /**
   * Handles file removal by updating internal files collection
   * and emitting removal event for parent component handling
   * @param event RemoveEvent containing information about removed files
   * @public
   */
  public onFileRemove(event: RemoveEvent): void {
    this.fileRemove.emit(event);
    if (!event.isDefaultPrevented()) {
      this._clearPreviewFilesData(event.files as FileInfoData[]);
      const uploadedFiles = event.files.filter(x => x.state === FileState.Uploaded) as FileInfoData[];
      if (this.removeUrl === DefaultRemoveRequestUrl && uploadedFiles.length > 0) {
        const filesIdToRemove = uploadedFiles.map(x => x.uid);
        const filesToKeep = this.getAllFiles.filter(x => !filesIdToRemove.includes(x.uid));
        this.control.setValue(filesToKeep);
        this._adjustFileHtmlData(filesToKeep).then();
      }
    }
  }

  /**
   * Handles clearing of selected files while preserving initial files.
   * Prevents default clear behavior if initial files exist and filters
   * the file list to keep only initial files.
   * @param event ClearEvent containing clear operation information
   * @public
   */
  public onFilesClear(event: ClearEvent): void {
    if (this._initialFiles.length > 0) {
      event.preventDefault();
      const filesToKeep = this._initialFiles.map(x => x.uid);
      const updatedFiles = this.getAllFiles.filter(x => filesToKeep.includes(x.uid));
      this._clearPreviewFilesData(updatedFiles.filter(x => !filesToKeep.includes(x.uid)));
      this.control.setValue(updatedFiles);
      this._adjustFileHtmlData(updatedFiles).then();
    } else {
      this._clearPreviewFilesData(this.getAllFiles);
    }
  }

  /**
   * Handles file upload initialization and emits upload event
   * for parent component processing
   * @param event UploadEvent containing upload information
   * @public
   */
  public onFileUpload(event: UploadEvent): void {
    this.fileUploadHtmlService.removeAllReadyToUpload(this.uploadComponentElementRef,
      this._readyToUploadStatusClassName);
    this.fileUpload.emit(event);
  }

  /**
   * Tracks and emits file upload progress events to parent component
   * @param event Upload progress event containing completion percentage
   * @public
   */
  public onFileUploadProgress(event: UploadProgressEvent): void {
    this.fileUploadProgress.emit(event);
  }

  /**
   * Handles successful file uploads and emits success event to parent component
   * @param event SuccessEvent containing upload result information
   * @public
   */
  public onFileUploadSuccess(event: SuccessEvent): void {
    this.fileUploadSuccess.emit(event);
  }

  /**
   * Handles file upload errors and emits error events to parent component
   * @param event ErrorEvent containing error information
   * @public
   */
  public onFileUploadError(event: ErrorEvent): void {
    this.fileError.emit(event);
  }

  /**
   * Closes the file preview dialog and resets all preview-related properties
   * @public
   */
  public onFilePreviewDialogClose(): void {
    this._isModalDialogOpened = false;
    this.filePreviewModalDialogData.ShowDialog = false;
    this.filePreviewModalDialogData.MediaFileData = undefined;
    this.filePreviewModalDialogData.ActiveIndex = 0;
  }

  public selectedFilePreview(event$: ItemChangedEvent): void {
    this.filePreviewModalDialogData.MediaFileData = event$.item;
    this.filePreviewModalDialogData.ActiveIndex = event$.index;
    this._loadFileData(event$.item);
  }

  public getSafeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  //#region Private Helper Methods

  private _filesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const files = control.value as FileInfoData[];
      if (!files?.length) {
        return null;
      }
      const allFilesUploaded = files.some(x => x.state !== FileState.Uploaded);
      const someOfFileAreInvalid = files.some(x => x.validationErrors?.length > 0);
      return allFilesUploaded || someOfFileAreInvalid ? {required: true} : null;
    };
  }

  private _onSelectFilesValidation(filesInfoData: FileInfoData[]): void {
    if (this.allowOnlyUniqFiles) {
      const allSelectedFiles = this.getAllFiles;
      filesInfoData.forEach(fileInfo => {
        if ((!fileInfo.validationErrors || fileInfo.validationErrors.length === 0)
          && this.fileUploadComponentService.isSameFileIsExist(fileInfo, allSelectedFiles)) {
          fileInfo.validationErrors = ['fileIsNotUnique'];
        }
      });
    }
  }

  private _adjustInformationForFilesOnSelect(files: FileInfoData[] | undefined): void {
    files?.forEach(fileDataInfo => {
      if (!fileDataInfo.rawFile) {
        return;
      }
      fileDataInfo.MimeType = fileDataInfo.rawFile.type;
      fileDataInfo.FileType = this.fileService.getFileType(fileDataInfo.rawFile.type);
    });
  }

  private async _adjustFileHtmlData(files: FileInfoData[] | undefined): Promise<void> {
    for (const fileInfoData of files) {
      let previewFileData: PreviewFileData;
      if (this.previewFilesData.some(x => x.FileInfoData.uid === fileInfoData.uid)) {
        previewFileData = this.previewFilesData.find(x => x.FileInfoData.uid === fileInfoData.uid);
      } else {
        previewFileData = {
          FileInfoData: fileInfoData,
          ThumbnailDataUrl: undefined,
          ContentDataUrl: undefined,
          IsError$: new BehaviorSubject<boolean>(false),
          IsFileLoaded$: new BehaviorSubject<boolean>(false),
        };
        this.previewFilesData.push(previewFileData);
      }
      if (this.showFilePreview) {
        if (this.fileUploadComponentService.isMediaFile(fileInfoData.FileType)) {
          if (!fileInfoData.ThumbnailFile) {
            fileInfoData.ThumbnailFile = await this.mediaFileService.generateThumbnail(
              fileInfoData.rawFile, this._thumbnailSettings);
          }
          const contentDataUrl = previewFileData.ContentDataUrl ?? this._createObjectUrl(fileInfoData.rawFile);
          previewFileData.ContentDataUrl = contentDataUrl;
          previewFileData.ThumbnailDataUrl = this._createObjectUrl(fileInfoData.ThumbnailFile);
          previewFileData.IsFileLoaded$.next(!!contentDataUrl);
          setTimeout(() => this.fileUploadHtmlService.addMediaFileThumbnail(this.uploadComponentElementRef,
            previewFileData, this._openPreview.bind(this, previewFileData)));
        } else {
          setTimeout(() => this.fileUploadHtmlService.addFileIconClick(this.uploadComponentElementRef,
            previewFileData, this._openPreview.bind(this, previewFileData)));
        }
      }
      if (!fileInfoData.validationErrors || fileInfoData.validationErrors.length === 0) {
        if (!!fileInfoData.UploadDate) {
          setTimeout(() => this.fileUploadHtmlService.addUploadDateToFile(this.uploadComponentElementRef, fileInfoData));
        }
        if (!this.autoUpload && this.fileUploadComponentService.isFileHaveInitOrSelectState(fileInfoData)) {
          setTimeout(() => this.fileUploadHtmlService.addReadyToUploadInfo(this.uploadComponentElementRef,
            fileInfoData, this._readyToUploadStatusClassName));
        }
        if (this.allowDownload) {
          setTimeout(() => this.fileUploadHtmlService.addDownloadButton(this.uploadComponentElementRef,
            previewFileData, this._onFileDownload.bind(this)));
        }
      }
    }
  }

  private _openPreview(previewFileData: PreviewFileData): void {
    const mediaDataIndex = this.previewFilesData.findIndex(x => x.FileInfoData.uid === previewFileData.FileInfoData.uid);
    if (mediaDataIndex === -1) {
      return;
    }
    this.filePreviewModalDialogData.MediaFileData = previewFileData;
    this.filePreviewModalDialogData.ActiveIndex = mediaDataIndex;
    this.filePreviewModalDialogData.Height$.next(this._getFilePreviewModalHeight());
    this.filePreviewModalDialogData.ShowDialog = true;
    this._loadFileData(previewFileData);
    setTimeout(() => this._isModalDialogOpened = true, 10);
  }

  private _onFileDownload(previewFileData: PreviewFileData): void {
    if (previewFileData.IsFileLoaded$.getValue()) {
      saveAs(previewFileData.ContentDataUrl, previewFileData.FileInfoData.name);
    } else {
      this.fileUploadHtmlService.downloadButtonState(this.uploadComponentElementRef, previewFileData.FileInfoData.uid, true);
      this._loadFileData(previewFileData, (previewFileDataAfterDownload: PreviewFileData | undefined) => {
        if (!!previewFileDataAfterDownload) {
          saveAs(previewFileDataAfterDownload.ContentDataUrl, previewFileDataAfterDownload.FileInfoData.name);
        }
        this.fileUploadHtmlService.downloadButtonState(this.uploadComponentElementRef, previewFileData.FileInfoData.uid, false);
      });
    }
  }

  private _loadFileData(previewFileData: PreviewFileData,
                        afterDownloadCallBack?: (previewFileData: PreviewFileData | undefined) => void): void {
    if (!!previewFileData.ContentDataUrl) {
      previewFileData.IsError$.next(false);
      previewFileData.IsFileLoaded$.next(true);
    } else {
      if (!!this.fileDownloadProcessCallback) {
        try {
          previewFileData.IsError$.next(false);
          previewFileData.IsFileLoaded$.next(false);
          this.fileDownloadProcessCallback(previewFileData.FileInfoData.uid, (base64FileData: Base64FileData | undefined) => {
            if (!!base64FileData && !!base64FileData.Base64) {
              this.fileUploadComponentService.updateRawFileData(previewFileData.FileInfoData, base64FileData);
              previewFileData.ContentDataUrl = this._createObjectUrl(previewFileData.FileInfoData.rawFile);
              previewFileData.IsError$.next(false);
              previewFileData.IsFileLoaded$.next(true);
              if (!!afterDownloadCallBack) {
                afterDownloadCallBack(previewFileData);
              }
            } else {
              previewFileData.IsError$.next(false);
              previewFileData.IsFileLoaded$.next(true);
              if (!!afterDownloadCallBack) {
                afterDownloadCallBack(previewFileData);
              }
            }
          });
        } catch (error) {
          console.warn(error);
          previewFileData.IsError$.next(true);
          previewFileData.IsFileLoaded$.next(false);
          if (!!afterDownloadCallBack) {
            afterDownloadCallBack(previewFileData);
          }
        }
      } else {
        previewFileData.IsError$.next(true);
        previewFileData.IsFileLoaded$.next(false);
        if (!!afterDownloadCallBack) {
          afterDownloadCallBack(previewFileData);
        }
      }
    }
  }

  private _clearPreviewFilesData(filesToRemove: FileInfoData[]): void {
    const fileIdsToRemove = filesToRemove.map(x => x.uid);
    const filePreviewsToDelete = this.previewFilesData.filter(x => fileIdsToRemove.includes(x.FileInfoData.uid));
    filePreviewsToDelete.forEach(filePreview => {
      if (!!filePreview.ContentDataUrl) {
        URL.revokeObjectURL(filePreview.ContentDataUrl);
      }
      if (!!filePreview.ThumbnailDataUrl) {
        URL.revokeObjectURL(filePreview.ThumbnailDataUrl);
      }
    });
    this.previewFilesData = this.previewFilesData.filter(x => !fileIdsToRemove.includes(x.FileInfoData.uid));
  }

  private _createObjectUrl(file: File | undefined): string | undefined {
    return !!file ? URL.createObjectURL(file) : undefined;
  }

  private _resizeFilePreviewModal(): void {
    if (this._isModalDialogOpened) {
      this.filePreviewModalDialogData.Height$.next(this._getFilePreviewModalHeight());
    }
  }

  private _closeFilePreviewModal(eventTarget: EventTarget): void {
    if (this._isModalDialogOpened) {
      const element = document.querySelector('.k-window.k-dialog');
      if (!element) {
        return;
      }
      const dialogComponentElementRef = new ElementRef<any>(element);
      if (!!dialogComponentElementRef && !dialogComponentElementRef.nativeElement.contains(eventTarget)) {
        this.dialogComponent.close.emit();
      }
    }
  }

  private _getFilePreviewModalHeight(): number {
    if (!this._viewContainer) {
      return 700;
    }
    return this._viewContainer.offsetHeight - 200;
  }

  private _settingsForThumbnail(): ThumbnailParam {
    return {
      Width: 200,
      Height: 200,
      Quality: 0.2,
      MimeType: 'image/webp',
      Name: 'thumbnail.webp'
    };
  }

  private _generateFilePreviewModalDialogData(): FilePreviewModalDialogData {
    return {
      ShowDialog: false,
      MediaFileData: undefined,
      ActiveIndex: 0,
      Height$: new BehaviorSubject<number>(700)
    };
  }

  //#endregion
}
