<ng-container *ngIf="chartConfigs?.length === 0">
  <div class="empty-message">
    <p>{{emptyMessage | translate}}</p>
  </div>
</ng-container>
<!--multiple charts => ngfor -->
<div class="ap-generic-chart-container">
  <ng-container *ngIf="chartConfigs?.length > 0">
    <h3>{{ title | translate }}</h3>
    <div class="ap-generic-chart-inner-container">
      <kendo-chart *ngFor="let chartConfig of chartConfigs"
                   [transitions]="chartConfig.transitions"
                   [style.height]="(calculateChartHeight())+'%'"
                   [style.min-height]="(calculateChartHeight())+'%'"
                   [zoomable]="chartConfig.zoomable"
                   [seriesDefaults]="chartConfig.seriesConfigs?.length > 2 ? {column:{gap:0, spacing: 0}} : {}"
                   [pannable]="chartConfig.pannable">
        <kendo-chart-series>
          <kendo-chart-title [color]="strokeColor" [text]="chartConfig.title | translate"></kendo-chart-title>
          <!-- multiple data series per chart, each series might have another chart type -->
          <kendo-chart-series-item *ngFor="let seriesConfig of chartConfig.seriesConfigs"
                                   [name]="seriesConfig.name" [style]="'smooth'"
                                   [categoryField]="'xValue'" [field]="'yValue'" [color]="seriesConfig.color"
                                   [axis]="seriesConfig.axisId"
                                   [type]="seriesConfig.type" [data]="seriesConfig.seriesData"
                                   [noteTextField]="(chartConfig?.noteDelegate || chartConfig?.noteField )? 'yValue' : undefined"
                                   [notes]="(chartConfig?.noteDelegate || chartConfig?.noteField) ? {
                                        label: {
                                        position: 'outside',
                                        visible: !!(chartConfig?.noteDelegate || chartConfig?.noteField),
                                        content: getNote(chartConfig)
                                    }} : {}">
            <kendo-chart-series-item-tooltip *ngIf="chartConfig.tooltipDelegate"  [background]="backgroundColor" [color]="'#000'" >
              <ng-template let-dataItem="dataItem">
                <span [innerHTML]="getTooltip(dataItem, chartConfig)"></span>
              </ng-template>
            </kendo-chart-series-item-tooltip>
          </kendo-chart-series-item>

          <!-- axis definitions -->
          <kendo-chart-value-axis>
            <kendo-chart-value-axis-item *ngFor="let yAxisConfig of chartConfig.yAxisConfigs"
                                         [labels]="{margin: 0, padding: 0}"
                                         [name]="yAxisConfig.id" [title]="{ text: yAxisConfig.title }"
                                         [type]="yAxisConfig.type" [color]="strokeColor">
              <kendo-chart-value-axis-item-labels [content]="getLabel(yAxisConfig)">
              </kendo-chart-value-axis-item-labels>
            </kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [type]="chartConfig.xAxisConfig.type"
                                            [categories]="chartConfig.xAxisConfig.type === 'category' ? chartConfig.xAxisConfig.categories : undefined"
                                            [axisCrossingValue]="chartConfig.axisCrossingValue"
                                            [title]="{ text: chartConfig.xAxisConfig.title }"
                                            [labels]="{rotation:'auto'}"
                                            [min]="chartConfig.xAxisConfig.type === 'category' ? undefined : chartConfig.xAxisConfig.min"
                                            [max]="chartConfig.xAxisConfig.type === 'category' ? undefined : chartConfig.xAxisConfig.max"
                                            [baseUnit]="chartConfig.xAxisConfig.type === 'category' ? undefined : chartConfig.xAxisConfig.baseUnit"
                                            [color]="strokeColor">
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart-series>
        <kendo-chart-legend position="bottom" orientation="horizontal" [labels]="{color: strokeColor}" [inactiveItems]="{labels:{color: inactiveColor}}"></kendo-chart-legend>
      </kendo-chart>
    </div>
  </ng-container>
</div>
