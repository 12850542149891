import {
  IModalDialogData, IModalDialogResult
} from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {Injectable} from '@angular/core';
import {TranslationStore} from '../translation/translation.store';

export enum ModalDialogButtonKeys {
  Delete = 'delete',
  Confirm = 'confirm'
}

@Injectable({providedIn: 'root'})
export class ModalDialogPresets {
  constructor(private translationStore: TranslationStore) {
  }

  DeleteDialog(options?: { title?: string, message?: string, resultDelegate?: (data: any, dialogResult: IModalDialogResult) => void }): IModalDialogData {
    return {
      title: this.translationStore.FindTranslationForSelectedLanguage(options?.title ?? 'Global__Dialog_Title_Delete'),
      message: this.translationStore.FindTranslationForSelectedLanguage(options?.message ?? 'Global__Dialog_Message_Delete'),
      show: true,
      resultDelegate: options?.resultDelegate,
      buttons: [
        {key: '', text: 'Global__Cancel', primary: false},
        {key: ModalDialogButtonKeys.Delete, text: 'Global__Delete', primary: true}
      ],
    } as IModalDialogData;
  }

  UnsavedChangesDialog(options?: { title?: string, message?: string, resultDelegate?: (data: any, dialogResult: IModalDialogResult) => void }): IModalDialogData {
    return {
      title: this.translationStore.FindTranslationForSelectedLanguage(options?.title ?? 'Global__Warning'),
      message: this.translationStore.FindTranslationForSelectedLanguage(options?.message ?? 'Global__Unsaved_Data'),
      show: true,
      resultDelegate: options?.resultDelegate,
      buttons: [
        {key: ModalDialogButtonKeys.Confirm, text: 'Global__Ok', primary: true},
        {key: '', text: 'Global__Cancel', primary: false}
      ],
    } as IModalDialogData;
  }

  ExistPlanBooksDialog(options?: { title?: string, message?: string, confirmKey?: string }): IModalDialogData {
    return {
      title: this.translationStore.FindTranslationForSelectedLanguage(options?.title ?? 'HdrConfirm'),
      message: this.translationStore.FindTranslationForSelectedLanguage(options?.message ?? 'Global__Dialog_Message_CheckPlanBooks'),
      show: true,
      buttons: [
        {key: '', text: 'Global__Cancel', primary: false},
        {key: options.confirmKey ?? ModalDialogButtonKeys.Confirm, text: 'Global__Confirm', primary: true}
      ],
    } as IModalDialogData;
  }

  public ExtendLicenseDialog(options?: { message?: string, resultDelegate?: (data: any, dialogResult: IModalDialogResult) => void }): IModalDialogData {
    return {
      title: this.translationStore.FindTranslationForSelectedLanguage('TextInfo__Adjust_License_Request'),
      message: this.translationStore.FindTranslationForSelectedLanguage(options?.message ?? ''),
      show: true,
      resultDelegate: options?.resultDelegate,
      buttons: [
        {key: '', text: 'Global__Ignore', primary: false},
        {key: ModalDialogButtonKeys.Confirm, text: 'Global__Adjust_License', primary: true}
      ],
    } as IModalDialogData;
  }
}
