<div class="donutpiecontainer">
  <kendo-dropdownlist class="ap-dropdown-bright dropdown"
    [valuePrimitive]="true"
    [data]="types"
    [value]="type"
    [textField]="'text'"
    [valueField]="'value'"
    (valueChange)="selectedValueChanged($event)"
  >
  </kendo-dropdownlist>
  <kendo-chart #chart *ngFor="let key of (data | apDonutPieChart | getStatisticKeys)" [zoomable]="true" [pannable]="true" style="height: 895px;">
    <ng-template kendoChartDonutCenterTemplate>
      {{'Nutrients__TotalArea' | translate}}
      <h3>{{centerCount}} {{centerUnit}}</h3>
    </ng-template>
    <kendo-chart-series>
      <kendo-chart-title class="ap-section-header-caption" text="{{title}}" position="top" [color]="strokeColor" ></kendo-chart-title>
      <kendo-chart-legend
        [position]="'bottom'"
        [orientation]="'horizontal'"
        [align]="'start'"
        [background]="'#fcf7f8'"
        [border]="{color:'rgba(0, 0, 0, 0.05)', dashType: 'solid', width: 1}"
        [margin]="10"
        [padding]="15"
        [width]="150"
        [reverse]="true"
        [visible]="true"
        [labels]="{color: strokeColor}"
        [inactiveItems]="{labels:{color: inactiveColor}}"
      >
      </kendo-chart-legend>
      <kendo-chart-series-item
        type="{{type}}" [data]="(data | apDonutPieChart)[key]"
        field="value" categoryField="category" colorField="color">
        <kendo-chart-series-item-labels
          [position]="'outsideEnd'"
          [content]="labelContent.bind(this)"
          [color]="strokeColor" background="none">
        </kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <div class="overlay" *ngIf="(data | apDonutPieChart | getStatisticKeys).length === 0">
      <div>
        {{'Global__NoData' | translate}}
      </div>
    </div>
  </kendo-chart>
</div>
