<kendo-upload #uploadFileComponent
              kendoUploadDropzone
              class="file-upload"
              [id]="id"
              [formControl]="control"
              [autoUpload]="autoUpload"
              [saveUrl]="saveUrl"
              [removeUrl]="removeUrl"
              [withCredentials]="withCredentials"
              [restrictions]="restrictions"
              [multiple]="multiple"
              [class.k-disabled]="disabled"
              [style]="style"
              (clear)="onFilesClear($event)"
              (remove)="onFileRemove($event)"
              (uploadProgress)="onFileUploadProgress($event)"
              (success)="onFileUploadSuccess($event)"
              (upload)="onFileUpload($event)"
              (select)="onFileSelect($event)"
              (error)="onFileUploadError($event)">
  <kendo-upload-messages fileUploadMessages
                         [dropFilesHere]="dropFilesHereText | translate"
                         [select]="selectText | translate"
                         [uploadSelectedFiles]="uploadSelectedFilesText | translate"
                         [clearSelectedFiles]="clearSelectedFilesText | translate"
                         [fileIsNotUnique]="'SameFileIsAlreadyInUse' | translate"
  ></kendo-upload-messages>
</kendo-upload>
<!-- Show full media Dialog -->
<kendo-dialog #dialogComponent
              *ngIf="filePreviewModalDialogData.ShowDialog"
              [class]="'file-preview-dialog'"
              [width]="'90%'"
              [height]="'auto'"
              (close)="onFilePreviewDialogClose()">
  <kendo-dialog-titlebar class="title-bar">
    <ap-responsive-text [text]="'FilePreview' | translate"
    ></ap-responsive-text>
  </kendo-dialog-titlebar>
  <div class="preview-content"
       [style.height.px]="filePreviewModalDialogData.Height$ | async">
    <kendo-scrollview [data]="previewFilesData"
                      [activeIndex]="filePreviewModalDialogData.ActiveIndex"
                      [width]="'100%'"
                      [height]="'100%'"
                      [arrows]="true"
                      (itemChanged)="selectedFilePreview($event)">
      <ng-template let-item="item">
        <ng-container *ngIf="!(item.IsError$ | async); else errorMessage">
          <ng-container *ngIf="item.IsFileLoaded$ | async; else loading">
            <ng-container [ngSwitch]="item.FileInfoData.FileType">
              <video *ngSwitchCase="FileType.Video"
                     [class]="'content-styles'"
                     [controls]="true">
                <source [src]="getSafeUrl(item.ContentDataUrl)" [type]="item.FileInfoData.MimeType">
                <source [src]="getSafeUrl(item.ContentDataUrl)" [type]="'video/mp4'">
              </video>
              <img *ngSwitchCase="FileType.Image"
                   [src]="getSafeUrl(item.ContentDataUrl)"
                   [alt]="''"
                   [class]="'content-styles'"
                   [draggable]="false">
              <div *ngSwitchCase="FileType.Other"
                   [class]="'content-styles'">
                <ap-responsive-text [text]="item.FileInfoData.name"
                ></ap-responsive-text>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #loading>
          <div [class]="'centered-container'">
            <kendo-loader [size]="'large'"></kendo-loader>
          </div>
        </ng-template>
        <ng-template #errorMessage>
          <div [class]="'centered-container'">
            <ap-responsive-text [class]="'error-message'"
                                [text]="'FailedToLoadFilePreview' | translate"
            ></ap-responsive-text>
          </div>
        </ng-template>
      </ng-template>
    </kendo-scrollview>
  </div>
</kendo-dialog>
