/**
 * List of Layer Names
 */
export class LayerNames {
  /**
   * Fields Layer Name
   */
  static readonly FIELDS = 'fields';
  static readonly SOIL_SAMPLE_FIELDS = 'soil_sample_fields';
  static readonly FIELDS_DESCRIPTION = 'fields_description';
  static readonly SOIL_SAMPLE_FIELDS_DESCRIPTION = 'soil_sample_fields_description';
  static readonly FIELDS_CROP = 'fields_crop';

  static readonly EDIT = 'edit';
  static readonly EDIT_VIEW = 'edit_view';

  static readonly FUNGIDETECT = 'fungidetect';

  /**
   * action slices last hour tcp downtimes Layer Name
   */
  static readonly ACTIONSLICESLASTHOURTCPDOWNTIME = 'action_slices_last_hour_tcp_downtimes';

  /**
   * Locations Layer Name
   */
  static readonly LOCATIONS = 'locations';
  static readonly USER_LOCATION = 'user_location';
  static readonly GEOPOINTS = 'geo_points';
  /**
   * Soil Sample Points Layer
   */
  static readonly SOIL_SAMPLES = 'soil_samples';
  static readonly INVALID_SOIL_SAMPLES = 'invalid_soil_samples';
  /**
   * Soil Sample Distribution Layer Name (Shapes of the Nutrient Distribution)
   */
  static readonly DISTRIBUTIONS = 'field_distributions';
  static readonly SOIL_SAMPLE_DISTRIBUTIONS = 'sample_field_distributions';
  static readonly SOIL_GROUP_MAP = 'soil_group_map';
  static readonly NEED = 'need';

  static readonly MULTIPOINT = 'multipoint';
  static readonly TRACK = 'track';
  static readonly CONTACT = 'contact';

  static readonly NUTRIENT_PLANNINGS = 'nutrient_plannings';
  static readonly TASK_MANAGEMENT = 'task_management';
  static readonly TASK_MANAGEMENT_NUTRIENT = 'task_management_nutrient';
  static readonly SENSOR_POINTS = 'sensor_points';
  static readonly N_RASTER = 'n_raster';
  static readonly PP_RASTER = 'pp_raster';
  static readonly BING_MAP = 'BingMap';
  static readonly NDI = 'ndi';
  static readonly N_APPLICATION_MAP = 'n_application_map';
  static readonly PP_APPLICATION_MAP = 'pp_applictaion_map';
  static readonly N_UPTAKE = 'nuptake_sat';
}

export class LayerGroupNames {
  static readonly NONE = 'none';
  static readonly BACKGROUND = 'background';
  static readonly BASIC = 'basic';
  static readonly BASE_FERTILISATION = 'base_fertilisation';
  static readonly N_FERTILISATION = 'n_fertilisation';
  static readonly PLANT_PROTECTION = 'plant_protection';
  static readonly SEED_MAPS = 'seed_maps';
  static readonly FIELD_WORK = 'field_work';
  static readonly TASK_MANAGEMENT = 'task_management';
  static readonly FUNGIDETECT = 'fungidetect';
  static readonly LOGFILES = 'logfiles';
}
