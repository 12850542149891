import {Injectable}               from '@angular/core';
import {FileService}              from './file.service';
import {FileType, ThumbnailParam} from '../../types/file.types';
import {ApDateService}            from '../../ap-core/services/ap-date-service';

@Injectable({providedIn: 'root'})
export class MediaFileService {
  constructor(private fileService: FileService,
              private dateService: ApDateService) {
  }

  public async generateThumbnail(file: File, thumbnail: ThumbnailParam): Promise<File | undefined> {
    return new Promise<File | undefined>((resolve) => {
      const fileReader = new FileReader();
      let blobUrl: string;
      fileReader.onload = () => {
        blobUrl = URL.createObjectURL(file);
        const fileType = this.fileService.getFileType(file.type);
        switch (fileType) {
          case FileType.Image:
            this._getImageThumbnailFileData(blobUrl, thumbnail)
              .then(x => {
                resolve(x);
                URL.revokeObjectURL(blobUrl);
              });
            break;
          case FileType.Video:
            this._getVideoThumbnailFileData(blobUrl, file.type, thumbnail)
              .then(x => {
                resolve(x);
                URL.revokeObjectURL(blobUrl);
              });
            break;
        }
      };
      fileReader.onerror = () => {
        console.error('File cannot be read.');
        if (!!blobUrl) {
          URL.revokeObjectURL(blobUrl);
        }
        resolve(undefined);
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  private _getImageThumbnailFileData(blobFileUrl: string, thumbnail: ThumbnailParam): Promise<File> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(this._generateThumbnailFileData(img, thumbnail));
      img.onerror = () => reject(new Error('Error loading image'));
      img.src = blobFileUrl;
    });
  }

  private _getVideoThumbnailFileData(blobFileUrl: string, mimeType: string, thumbnail: ThumbnailParam): Promise<File> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.onerror = () => reject(new Error('Error loading image'));
      video.onloadedmetadata = () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        video.currentTime = 1;
      };
      video.onseeked = () => resolve(this._generateThumbnailFileData(video, thumbnail));
      const source = document.createElement('source');
      source.type = this._isValidMimeType(mimeType) ? mimeType : 'video/mp4';
      source.src = blobFileUrl;
      video.appendChild(source);
    });
  }

  private _generateThumbnailFileData(mediaElement: HTMLVideoElement | HTMLImageElement, thumbnail: ThumbnailParam): Promise<File> {
    return new Promise((resolve, reject) => {
      const canvas = this._generateHtmlCanvas(mediaElement, thumbnail.Width, thumbnail.Height);
      canvas.toBlob((blob) => {
        if (blob) {
          const resizedFile = new File([blob], thumbnail.Name, {
            type: thumbnail.MimeType,
            lastModified: this.dateService.getNow()
          });
          resolve(resizedFile);
        } else {
          reject(new Error('Canvas to Blob conversion failed'));
        }
      }, thumbnail.MimeType, thumbnail.Quality);
    });
  }

  private _generateHtmlCanvas(mediaElement: HTMLVideoElement | HTMLImageElement,
                              width: number, height: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('Canvas context not available');
    }
    ctx.drawImage(mediaElement, 0, 0, canvas.width, canvas.height);
    return canvas;
  }

  private _isValidMimeType(mimeType: string): boolean {
    return mimeType !== 'video/quicktime';
  }
}
