import {Keys, MapInteraction, MapInteractions} from './interactions';
import {OlHelper, StyleDefinition}             from './ol.helper';
import {ApEditStyles}                          from './layers/ap-edit-styles';
import GeometryType                            from 'ol/geom/GeometryType';
import * as OlGeom                             from 'ol/geom';
import LinearRing                              from 'ol/geom/LinearRing';
import {ApMapInstance}                         from './ap-map.instance';
import Polygon                                 from 'ol/geom/Polygon';
import LineString                              from 'ol/geom/LineString';
import {cloneDeep}                             from 'lodash';
import OlFeature                               from 'ol/Feature';
import Feature                                 from 'ol/Feature';
import {ApGuidUtil}                            from '../ap-utils';
import * as NTSGeom                            from 'jsts/org/locationtech/jts/geom';
import UnionOp                                 from 'jsts/org/locationtech/jts/operation/union/UnionOp';
import LineStringExtracter                     from 'jsts/org/locationtech/jts/geom/util/LineStringExtracter';
import Polygonizer                             from 'jsts/org/locationtech/jts/operation/polygonize/Polygonizer';
import VectorLayer                             from 'ol/layer/Vector';
import {Vector}                                from 'ol/source';
import {getUid}                                from 'ol/util';
import GeometrySnapper                         from 'jsts/org/locationtech/jts/operation/overlay/snap/GeometrySnapper';
import Select                                  from 'ol/interaction/Select';
import VectorSource                            from 'ol/source/Vector';
import OL3Parser                               from 'jsts/org/locationtech/jts/io/OL3Parser';
import {MAP_PROJECTION}                        from './layers/ap-map.settings';
import Point                                   from 'ol/geom/Point';
import {FieldStore}                            from '../stores/farm/field.store';
import Interaction                             from 'ol/interaction/Interaction';
import Modify                                  from 'ol/interaction/Modify';
import {defaultStyle}                          from './layers/ap-fields.style';
import {Guid}                                  from 'ts-tooling';
import {MapEditStore}                          from '../stores/map/map.edit.store';
import {ApFieldsDescriptionLayer}              from './layers/ap-fields.layer';
import {LayerSyncStrategy}                     from './layers/ap-base-vector.layer';
import {ApPolygonEditLayer}                    from './layers/ap-polygon-edit.layer';
import {EditorService}                         from '../map/components/edit/editor.service';
import IApValidationResult = Data.Api.Validation.IApValidationResult;
import {NotifyStore}                           from '../stores/dialog/notify.store';
import {Translate}                             from 'ol/interaction';

const Ol3Parser = new OL3Parser();
Ol3Parser['inject'](OlGeom.Point, OlGeom.LineString, LinearRing, OlGeom.Polygon, OlGeom.MultiPoint,
  OlGeom.MultiLineString, OlGeom.MultiPolygon);

export class GeometryEditor {
  static DrawHole(geometryType: GeometryType, vectorSource: Vector): Interaction {
    return MapInteraction.Add(OlHelper.DrawVector(geometryType, vectorSource, ApEditStyles.selectedStyle));
  }

  static DeleteHole(geometryType: GeometryType, vectorSource: Vector): void {
    if (!ApMapInstance.mapRef) {
      console.warn('missing map reference');
      return;
    }
    ApMapInstance.mapRef.on('singleclick', c => {
      const coo = ApMapInstance.mapRef.getCoordinateFromPixel(c.pixel);
      const features = vectorSource.getFeatures().filter(f => {
        const pg = f.getGeometry() as Polygon;
        const pgCompare = new OlGeom.Polygon([pg.getLinearRing(0).getCoordinates()]);
        if (pgCompare.intersectsCoordinate(coo)) {
          return f;
        }
        return undefined;
      });
      if (features.length === 1) {
        const oldPoly = features[0].getGeometry() as Polygon;
        const newPoly = new OlGeom.Polygon([oldPoly.getLinearRing(0).getCoordinates()]);
        let i;
        let intersected = false;
        for (i = 1; i < oldPoly.getLinearRingCount(); i++) {
          const lr: LinearRing = oldPoly.getLinearRing(i);
          const shellPg = new OlGeom.Polygon([lr.getCoordinates()]);
          if (!shellPg.intersectsCoordinate(coo)) {
            newPoly.appendLinearRing(lr);
          } else {
            intersected = true;
          }
        }
        if (intersected) {
          features[0].setGeometry(newPoly);
          features[0].set('tag', '');
          // TODO: refactor after Openlayers fix
          // this.mapStore.SetEditFeature(features[0]);
          MapInteraction.ClearSelection();
        }
      }
    });
  }

  static SnapToFeature(geometryType: GeometryType, vectorSource: Vector, tolerance = 10): Interaction {
    return MapInteraction.Add(OlHelper.Snap(vectorSource, tolerance));
  }

  static ModifyLayerSourceFeature(geometryType: GeometryType, layer: VectorLayer, features: Feature[] = null, ignoreSource: boolean = false): Modify {
    MapInteraction.Remove(MapInteractions.MODIFY);
    let modify: Modify;
    if (Array.isArray(features)) {
      modify = OlHelper.ModifySelected(features, ignoreSource ? null : layer.getSource(), ApEditStyles.selectedStyle);
    } else {
      modify = OlHelper.ModifySelected(layer.getSource().getFeatures(), layer.getSource(), ApEditStyles.selectedStyle);
    }
    MapInteraction.Add(modify);
    return modify;
  }

  static TranslateLayerSourceFeature(geometryType: GeometryType, layer: VectorLayer, features: Feature[] = null): Translate {
    MapInteraction.Remove(MapInteractions.TRANSLATE);
    let translate: Translate;
    if (Array.isArray(features)) {
      translate = OlHelper.TranslateSelected(features);
    } else {
      translate = OlHelper.TranslateSelected(layer.getSource().getFeatures());
    }
    MapInteraction.Add(translate);
    return translate;
  }

  static SplitByLine(geometryType: GeometryType, editLayer: ApPolygonEditLayer, descriptionLayer: ApFieldsDescriptionLayer, mapEditStore: MapEditStore, notifyStore: NotifyStore): Interaction {
    const vectorSource = editLayer.innerSource;
    const originalField = vectorSource.getFeatures()[0];
    const drawline = MapInteraction.Add(OlHelper.DrawVector(geometryType, vectorSource, ApEditStyles.selectedStyle));
    if (!drawline) {
      console.warn('Missing drawline');
      return undefined;
    }

    const restoreOriginalState = function(): void {
      EditorService.SplitLineDrawn$.next(false);
      vectorSource.clear();
      vectorSource.addFeature(originalField);
      descriptionLayer.clear();
      descriptionLayer.SyncFeatures(vectorSource.getFeatures(), LayerSyncStrategy.FORCE);
    };

    const splitFields = function(event: any): void {
      const features = vectorSource.getFeatures();
      const line = event.feature.getGeometry() as LineString;
      const parser = new OL3Parser();
      parser['inject'](OlGeom.Point, OlGeom.LineString, LinearRing, OlGeom.Polygon, OlGeom.MultiPoint,
        OlGeom.MultiLineString, OlGeom.MultiPolygon);
      // @ts-ignore
      const splitLine = parser.read(line);
      const lineString = { Type: 'LineString', Coordinates: line.getCoordinates() };

      mapEditStore.SplitFieldGeometry(features[0].getId(), lineString, (result) => {
        if (result.length !== 2) {
          EditorService.ValidSplitLine$.next(false);
          return;
        }

        EditorService.SplitLineDrawn$.next(true);
        EditorService.ValidSplitLine$.next(true);
        GeometryEditor.RemoveFeatureSafely(vectorSource, features[0]);
        GeometryEditor.RemoveFeatureSafely(vectorSource, event.feature);
        mapEditStore.UpdateSplitData(result);
        let fIndex = 1;
        result.forEach((r) => {
          const poly = new Polygon(JSON.parse(r[0]).coordinates);
          const areaSizeRounded = ApMapInstance.roundNumericPipe.transform(r[1] ? +r[1] : 0, ApMapInstance.settingsStore.FirstSetting.DigitsAfterDecimalPoint);
          const feat = new OlFeature({
            name: 'Polygon',
            geometry: poly,
            label: `Field ${fIndex}\n${areaSizeRounded} ${ApMapInstance.translationService.FindTranslationForSelectedLanguage('Base__UnitHa')}`
          });
          feat.setId(ApGuidUtil.generateNewGuid());
          vectorSource.addFeature(feat);
          fIndex++;
        });
        descriptionLayer.clear();
        descriptionLayer.SyncFeatures(vectorSource.getFeatures(), LayerSyncStrategy.FORCE);
        event.feature.setId(ApGuidUtil.generateNewGuid());
        vectorSource.addFeature(event.feature);
        editLayer.selectFeature(event.feature.getId(), ApEditStyles.selectedStyle);
      });
    };

    drawline.on('drawstart', event => {
      MapInteraction.Remove(MapInteractions.MODIFY);
      restoreOriginalState();
    });
    drawline.on('drawend', event => {
      splitFields(event);

      const modify = GeometryEditor.ModifyLayerSourceFeature(GeometryType.LINE_STRING, editLayer.layer, [event.feature], true);
      modify.on('modifyend', async (modEvent) => {
        const lineFeature = modEvent.target.features_.getArray()[0];
        restoreOriginalState();
        vectorSource.addFeature(lineFeature);
        splitFields({feature: lineFeature});
      });

    });
    // this.SnapToFeature(GeometryType.POLYGON, vectorSource, 10);
    return drawline;
  }

  static MergePolygon(vectorLayer: VectorLayer): Interaction {
    return MapInteraction.Add(OlHelper.SelectByClick(vectorLayer, ApEditStyles.selectedStyle)) as Select;
  }

  static DrawFeature(geometryType: GeometryType, vectorSource: VectorSource, style?: StyleDefinition): Interaction {
    const draw = OlHelper.DrawVector(geometryType, vectorSource, style);
    draw.on('drawend', f => {
      f.feature.setStyle(defaultStyle);
    });
    return MapInteraction.Add(draw);
  }

  static ClosestPointOnFeature(feat: Feature, point: Point): [number, number] {
    const ntsFeature = Ol3Parser.read(feat.getGeometry()) as NTSGeom.Polygon;
    const ntsPoint = Ol3Parser.read(point) as NTSGeom.Point;
    const borders = ntsFeature.getExteriorRing();
    let closest: NTSGeom.Point = null;
    let minDistance = Infinity;
    for (let i = 0; i < borders.getNumPoints(); i++) {
      const ringPoint = borders.getPointN(i);
      const currentDistance = ringPoint.distance(ntsPoint);
      if (currentDistance < minDistance) {
        minDistance = currentDistance;
        closest = ringPoint;
      }
    }
    return [closest.getX(), closest.getY()];
  }

  static PointInPolygon(feat: Feature, pt: number[], borderTolerance = 0.0039): boolean {
    const ntsFeature = Ol3Parser.read(feat.getGeometry()) as NTSGeom.Polygon;
    const featureRing = ntsFeature.getExteriorRing().buffer(borderTolerance, 1, 1);
    const ntsPoint = Ol3Parser.read(new Point(pt)) as NTSGeom.Point;
    const border = ntsFeature.difference(featureRing);
    return ntsPoint.within(border);
  }

  /**
   * looking for overlapping in the source and modify the feature to not overlap any feature in the source
   *
   * @param feat the Feature to integrate in the Vector Source
   * @param fieldStore the Field Store
   */
  static async IntegrateNewFeature(feat: Feature, fieldStore: FieldStore): Promise<{ Feature: Feature, Error: Error }> {
    const fieldGeomId = Guid.Validate(feat.getId()?.toString()) ? feat.getId()?.toString() : Guid.Empty.ToString();
    const geom = feat.getGeometry() as Polygon;
    const res = await fieldStore.integrateFeature({
      Type: 'Polygon',
      Coordinates: geom.getCoordinates(),
    }, MAP_PROJECTION, fieldGeomId);
    if (res.ErrorMessage) {
      return {Feature: feat, Error: new Error(res.ErrorMessage)};
    }
    feat.setGeometry(new Polygon(res.Geom.Coordinates));
    return {Feature: feat, Error: null};
  }

  static ExtractPolygons(geom: NTSGeom.Geometry): NTSGeom.Geometry[] {
    const lines = LineStringExtracter.getLines(geom);
    const polygonizer = new Polygonizer();
    polygonizer.add(lines);
    const polys = polygonizer.getPolygons();
    return NTSGeom.GeometryFactory.toGeometryArray(polys);
  }

  static Split(line: NTSGeom.LineString, foundFields: NTSGeom.Polygon[]): NTSGeom.Polygon[] {
    let t = 0;
    let fid = 0;
    let fgeom: NTSGeom.Geometry = null;
    let splitPgs = new Array<NTSGeom.Polygon>();
    for (const f of foundFields) {
      const nodedLinework = UnionOp.union(f.getBoundary(), line);
      const polys = GeometryEditor.ExtractPolygons(nodedLinework);
      if (polys.length > 1) {
        t++;
        fid = f.fid;
        fgeom = f.getGeometryN(0);
        splitPgs = cloneDeep(polys);
      }
    }

    if (t > 1) { // Message more than one Area
      return null;
    }
    if (t === 0) { // Message no complete Area
      return null;
    }
    // only keep polygons which are inside the input
    const insidePg: NTSGeom.Polygon[] = [];
    splitPgs.forEach(pg => {
      if (fgeom.intersects(pg.getInteriorPoint())) {
        insidePg.push(pg);
      }
    });

    if (insidePg.length !== 2) {
      return null;
    }
    insidePg[0].fid = fid;
    return insidePg;
  }

  static RemoveFeatureSafely(source: Vector<OlGeom.Geometry>, feat: OlFeature): void {
    if (feat != null && source.getFeatureByUid(getUid(feat)) != null) {
      source.removeFeature(feat);
    }
  }
}
