import {IAction, IApInvokerArguments} from '../../interfaces';

export enum AgriportSessionActions {
  AGRIPORT_SESSION_UPDATED = '[agriport session] updated'
}

export class AgriportSessionUpdated implements IAction {
  readonly type = AgriportSessionActions.AGRIPORT_SESSION_UPDATED;

  constructor(public payload: IApInvokerArguments[]) {
  }
}
