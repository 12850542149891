import {IStateStore}         from '../../ap-interface';
import {Injectable}          from '@angular/core';
import {Store}               from '../index';
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';
import {
  ApCustomTypes,
  MonitoringDetailAttachmentUpload,
  MonitoringDetailCreate,
  MonitoringDetailDelete,
  MonitoringDetailEdit,
  MonitoringDetailLoad,
  MonitoringDetailLoadFail,
  MonitoringDetailLoadSuccess,
  NetTypes
}                            from 'invoker-transport';
import {SafeBehaviorSubject} from 'ts-tooling';
import {
  MonitoringDetailAttachmentDownload,
  MonitoringDetailAttachmentDownloadFail,
  MonitoringDetailAttachmentDownloadSuccess,
  MonitoringDetailAttachmentUploadFail,
  MonitoringDetailAttachmentUploadSuccess
}                            from '../../../../projects/invoker-transport/src/lib/actions/fields';
import IGuid = System.IGuid;
import IMonitoringDetail = Data.Fields.IMonitoringDetail;
import IMonitoringDetailAttachment = Data.Fields.IMonitoringDetailAttachment;

interface IMonitoringDetailStore extends IStateStore<IMonitoringDetail> {
}

@Injectable({providedIn: 'root'})
export class MonitoringDetailStore extends Store<IMonitoringDetailStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: []
    });
    backend.registerObservable(MonitoringDetailLoad).subscribe(_ => {
      super.SetLoadState();
    });
    backend.registerObservable(MonitoringDetailLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(MonitoringDetailLoadFail).subscribe(_ => {
      super.SetLoadFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public get MonitoringDetails$(): SafeBehaviorSubject<IMonitoringDetail[]> {
    return this.Listen((s) => s.data);
  }

  public get MonitoringDetails(): IMonitoringDetail[] {
    return this.MonitoringDetails$.getValue();
  }

  public loadMonitoringDetails(): void {
    this.DispatchBackend(new MonitoringDetailLoad([]));
  }

  public createMonitoringDetail(monitoringDetail: IMonitoringDetail): void {
    this.DispatchBackend(new MonitoringDetailCreate([
      {Name: 'monitoringDetail', Type: ApCustomTypes.Data_Fields_MonitoringDetail, Value: monitoringDetail}
    ]));
  }

  public editMonitoringDetail(monitoringDetail: IMonitoringDetail): void {
    this.DispatchBackend(new MonitoringDetailEdit([
      {Name: 'monitoringDetail', Type: ApCustomTypes.Data_Fields_MonitoringDetail, Value: monitoringDetail}
    ]));
  }

  public deleteMonitoringDetail(monitoringDetailId: IGuid): void {
    this.DispatchBackend(new MonitoringDetailDelete([
      {Name: 'monitoringDetailId', Type: NetTypes.GUID, Value: monitoringDetailId}
    ]));
  }

  public uploadAttachment(attachment: IMonitoringDetailAttachment): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DispatchBackend(new MonitoringDetailAttachmentUpload([
        {Name: 'attachment', Type: ApCustomTypes.Data_Fields_MonitoringDetailAttachment, Value: attachment}
      ]))
        .watchStream({
          action: MonitoringDetailAttachmentUploadSuccess,
          todo: () => resolve()
        })
        .watchStream({
          action: MonitoringDetailAttachmentUploadFail,
          todo: _ => reject()
        });
    });
  }

  public downloadAttachment(attachmentId: IGuid): Promise<IMonitoringDetailAttachment> {
    return new Promise((resolve, reject) => {
      this.DispatchBackend(new MonitoringDetailAttachmentDownload([
        {Name: 'attachmentId', Type: NetTypes.GUID, Value: attachmentId}
      ]))
        .watchStream({
          action: MonitoringDetailAttachmentDownloadSuccess,
          todo: (result) => resolve(result.Data)
        })
        .watchStream({
          action: MonitoringDetailAttachmentDownloadFail,
          todo: _ => reject()
        });
    });
  }
}
