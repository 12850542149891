<ng-container *ngIf="data?.length <= 0">
  <div class="empty-message">
    <p>{{emptyMessage | translate}}</p>
  </div>
</ng-container>
<ng-container *ngIf="data?.length > 0">
  <div class="ap-fields-crop-rotation-stats-dropdown">
    <kendo-combobox [data]="dropdownSelectionItems"
                    [textField]="'Text'"
                    [valueField]="'Value'"
                    [allowCustom]="false"
                    [clearButton]="false"
                    [value]="selectedCropPriority | async"
                    (valueChange)="selectedCropPriority.next($event)"
    ></kendo-combobox>
  </div>
  <div class="ap-fields-crop-rotation-stats">
    <div class="ap-fields-crop-rotation-table">
      <h3>{{title | translate}}</h3>
      <div class="ap-fields-crop-rotation-stat-bars">
        <kendo-chart>
          <kendo-chart-area [width]="chartWidth | async"
                            [height]="chartHeight | async"
          >
            <kendo-chart-series>
              <kendo-chart-series-item *ngFor="let item of chartBarData$ | async"
                                       [data]="item.Data"
                                       [name]="item.Name"
                                       [color]="item.Color"
                                       [field]="'Percent'"
                                       [border]="{ width: 0 }"
                                       type="column"
                                       stack="true">
                <kendo-chart-series-item-tooltip [background]="backgroundColor"
                                                 [border]="border"
                                                 [color]="strokeColor"
                >
                  <ng-template kendoChartSeriesTooltipTemplate
                               let-currentItem="dataItem">
                    <div [innerHTML]="this.getTooltipText(item.Name, currentItem)"></div>
                  </ng-template>
                </kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-legend position="bottom"
                                orientation="horizontal"
                                [labels]="{ color: strokeColor }" [inactiveItems]="{labels:{color: inactiveColor}}"
            ></kendo-chart-legend>
            <kendo-chart-value-axis>
              <kendo-chart-value-axis-item [color]="strokeColor"
                                           [min]="chartBarRange.min"
                                           [max]="chartBarRange.max"
              ></kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [categories]="cropYears"
                                              [color]="strokeColor"
              ></kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
          </kendo-chart-area>
        </kendo-chart>
      </div>
      <div>
        <ap-dyngrids class="ap-statistic-grid ap-fields-crop-rotation-stats-grid"
                     [canCreate]="false"
                     [canSearch]="false"
                     [checkboxDisabled]="true"
                     [pageable]="false"
                     [scrollable]="'none'"
                     [columns]="cropStatsColumns"
                     [items]="cropTableData$ | async"
                     [groupable]="false"
                     [reorderable]="false"
                     [selectable]="gridSelectableSettings">
        </ap-dyngrids>
      </div>
    </div>
  </div>
</ng-container>
