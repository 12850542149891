import {ReactiveFormsModule}                 from '@angular/forms';
import {NgModule}                            from '@angular/core';
import {ApDynformsComponent}                 from './ap-dynforms.component';
import {ApDynformsElementComponent}          from './ap-dynforms-element.component';
import {ApTranslationModule}                 from '../translation/ap-translation.module';
import {DropDownsModule}                     from '@progress/kendo-angular-dropdowns';
import {DateInputsModule}                    from '@progress/kendo-angular-dateinputs';
import {InputsModule}                        from '@progress/kendo-angular-inputs';
import {CommonModule}                        from '@angular/common';
import {ApUtilsModule}                       from '../ap-utils';
import {ApSectionHeaderModule}               from '../ap-section-header/ap-section-header.module';
import {ApResponsiveTextModule}              from '../ap-responsive-text/ap-responsive-text.module';
import {ApDynformsArrayComponent}            from './ap-dynforms-array.component';
import {LayoutModule}                        from '@progress/kendo-angular-layout';
import {SharedModule, UploadModule}          from '@progress/kendo-angular-upload';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SanitizeIdPipe}                      from './sanitize-id.pipe';
import {ApDynformsDateRangeComponent}        from './ap-dynforms-date-range.component';
import {PasswordStrengthComponent}           from './components/password-strength.component';
import {ButtonsModule}                       from '@progress/kendo-angular-buttons';
import {ApFileUploadComponent}               from './components/ap-file-upload/ap-file-upload.component';
import {DialogModule}                        from '@progress/kendo-angular-dialog';
import {IndicatorsModule}                    from '@progress/kendo-angular-indicators';
import {ApFileUploadInterceptor}             from './components/ap-file-upload/ap-file-upload.interceptor';
import {ApFileUploadMessagesDirective}       from './components/ap-file-upload/ap-file-upload.derectives';
import {ScrollViewModule}                    from '@progress/kendo-angular-scrollview';


@NgModule({
  imports: [
    ReactiveFormsModule,
    HttpClientModule,
    ApTranslationModule,
    DropDownsModule,
    DateInputsModule,
    InputsModule,
    ApSectionHeaderModule,
    CommonModule,
    ApUtilsModule,
    ApResponsiveTextModule,
    LayoutModule,
    UploadModule,
    ButtonsModule,
    SharedModule,
    DialogModule,
    IndicatorsModule,
    ScrollViewModule
  ],
  declarations: [
    ApDynformsComponent,
    ApDynformsElementComponent,
    ApDynformsArrayComponent,
    SanitizeIdPipe,
    ApDynformsDateRangeComponent,
    PasswordStrengthComponent,
    ApFileUploadComponent,
    ApFileUploadMessagesDirective
  ],
  exports: [
    ApDynformsComponent,
    ApDynformsElementComponent,
    PasswordStrengthComponent,
    ApFileUploadComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApFileUploadInterceptor,
      multi: true
    },
  ]
})
export class ApDynformsModule {
}
