import {MapStore}                                            from './map.store';
import {
  ApContactLayer,
  ApFieldsCropLayer,
  ApFieldsDescriptionLayer,
  ApFieldsLayer,
  ApFungiDetectLayer,
  ApLocationLayer,
  ApMapInstance,
  BingImagery,
  LayerGroupNames,
  LayerNames,
  LayerSyncStrategy
}                                                            from '../../ap-map';
import {MapFactoryLayer}                                     from '../../ap-map/layers/map-factory-layer';
import {ApPolygonEditLayer}                                  from '../../ap-map/layers/ap-polygon-edit.layer';
import {ApWMTSLayer}                                         from '../../ap-map/layers/ap-wmts-layer';
import {ApXYZLayer}                                          from '../../ap-map/layers/ap-xyz-layer';
import {
  ApTaskManagementLayer
}                                                            from '../../ap-map/layers/nutrients/ap-task-management.layer';
import {MapLayerControlStore}                                from './map.layer.control.store';
import {
  ILayerControlLayer,
  ILayerGroup
}                                                            from '../../ap-interface/map/layer-control-layer.interface';
import {ObjectFactory, SafeBehaviorSubject, StringFactory}   from 'ts-tooling';
import {BehaviorSubject, combineLatest}                      from 'rxjs';
import {TranslationStore}                                    from '../translation/translation.store';
import {LanguageStore}                                       from '../translation/language.store';
import {EventEmitter}                                        from '@angular/core';
import {delay, filter}                                       from 'rxjs/operators';
import {
  FieldDistributionLegendSelectorComponent
}                                                            from '../../entry-components/color-legend-entry-components/field-distribution-legend-selector.component';
import {ColorLegendStore}                                    from './color.legend.store';
import {
  GetSoilSampleClassColorService
}                                                            from '../../ap-utils/service/get-soil-sample-class-color.service';
import {APP_CONFIGURATION}                                   from '../../ap-core/config';
import {SelectAllCheckboxState}                              from '@progress/kendo-angular-grid';
import {
  ISelectionChangeEvent
}                                                            from '../../map/components/layer-controller/ap-legend-scroller-item.component';
import OlStyle                                               from 'ol/style/Style';
import OlIcon                                                from 'ol/style/Icon';
import IconAnchorUnits                                       from 'ol/style/IconAnchorUnits';
import {
  FieldNutrientDistributionChanged,
  FieldNutrientNeedChanged
}                                                            from '../../../../projects/invoker-transport/src/lib/actions/nutrients';
import {ApMenuUrls}                                          from '../../ap-interface';
import {ApSampleFieldsDescriptionLayer, ApSampleFieldsLayer} from '../../ap-map/layers/ap-sample-fields.layer';
import {GeometryEditorTool}                                  from '../../map/components/edit/ap-edit-tooltype';
import {ILegend}                                             from './map.legend.store';
import {FieldStore}                                          from '../farm/field.store';
import {ApUserLocationLayer}                                 from '../../ap-map/layers/ap-user-location-layer';
import {
  GetRoundNumericService
}                                                            from '../../ap-utils/service/get-round-numeric.service';
import {ISoilGroupFeature, ISoilSampleFeature}               from './interfaces/soil-sample-feature.interface';
import {LayerZIndex}                                         from '../../ap-map/layers/layer-z-index';
import IField = Data.FieldManagement.IField;
import IGuid = System.IGuid;
import {ApGeoPointsLayer}                                    from '../../ap-map/layers/ap-geo-points.layer';

export type MapLayerControlItem = {
  groups: ILayerGroup[];
  activeLayers: ILayerControlLayer[];
};

export type MapLayerControlItemsMap = {
  [key: string]: MapLayerControlItem;
};

export interface ILayerState {
  backgroundMap: BingImagery;
  nutrientPlanningUrl: string;
  distributionUrl: string;
  soilSampleDistributionUrl: string;
  rbUrl: string;
  soilGroupUrl: string;
  soilSampleUrl: string;
  nSensorLogPointsUrl: string;
  trackUrl: string;
  ndiLayerUrl: string;
  nRasterUrl: string;
  ppRasterUrl: string;
  activeLayerGroups: ILayerGroup[];
  layerMapControlItems: MapLayerControlItemsMap;
  nPlanningUrl: string;
  nUptakeUrl: string;
}

export class MapLayerStore {
  public static DefaultState: ILayerState = {
    backgroundMap: BingImagery.HYBRID,
    distributionUrl: '',
    soilSampleDistributionUrl: '',
    nutrientPlanningUrl: '',
    rbUrl: '',
    soilGroupUrl: '',
    soilSampleUrl: '',
    nSensorLogPointsUrl: '',
    trackUrl: '',
    ndiLayerUrl: '',
    nRasterUrl: '',
    ppRasterUrl: '',
    activeLayerGroups: [],
    layerMapControlItems: {},
    nPlanningUrl: '',
    nUptakeUrl: '',
  };

  public OnLayerChange = new EventEmitter();
  public FieldDistributionLegendNutrientChange = new EventEmitter<string>();
  public NeedLegendChange = new EventEmitter<ISelectionChangeEvent>();
  public NutrientPlaningLegendChange = new EventEmitter<ISelectionChangeEvent>();
  public onChangeTrackLayerStyle = new EventEmitter<ISelectionChangeEvent>();

  private _fieldLayer: ApFieldsLayer = null;
  private _fieldDescriptionLayer: ApFieldsDescriptionLayer = null;
  private _fieldCropLayer: ApFieldsCropLayer = null;
  private _sampleFieldLayer: ApSampleFieldsLayer = null;
  private _sampleFieldDescriptionLayer: ApSampleFieldsDescriptionLayer = null;
  private _locationLayer: ApLocationLayer = null;
  private _userLocationLayer: ApUserLocationLayer = null;
  private _geoPointsLayer: ApGeoPointsLayer = null;
  private _trackLayer: MapFactoryLayer = null;
  private _fungiDetectLayer: ApFungiDetectLayer = null;
  private _contactLayer: ApContactLayer = null;
  private _taskManagementLayer: ApTaskManagementLayer = null;
  private _polygonEditLayer: ApPolygonEditLayer = null;
  private _polygonEditViewLayer: ApPolygonEditLayer = null;
  private _wmtsLayer: ApWMTSLayer = null;
  private _xyzLayer: ApXYZLayer = null;
  private _soilSampleLayer: MapFactoryLayer = null;
  private _soilGroupLayer: MapFactoryLayer = null;
  private _needLayer: MapFactoryLayer = null;
  private _distributionLayer: MapFactoryLayer = null;
  private _soilSampleDistributionLayer: MapFactoryLayer = null;
  private _nutrientPlanningLayer: MapFactoryLayer = null;
  private _sensorPointLayer: MapFactoryLayer = null;
  private _nRaster: MapFactoryLayer = null;
  private _taskManagementNutrientLayer: MapFactoryLayer = null;
  private _ndiLayer: MapFactoryLayer = null;
  private _nApplicationMapLayer: MapFactoryLayer = null;
  private _nUptakeLayer: MapFactoryLayer = null;
  private _ppRaster: MapFactoryLayer = null;
  private _ppApplicationMapLayer: MapFactoryLayer = null;

  constructor(private mapStore: MapStore,
              private fieldStore$: BehaviorSubject<FieldStore>,
              private roundNumericService: GetRoundNumericService,
              private mapLayerControl: MapLayerControlStore,
              private colorLegendStore: ColorLegendStore,
              private getSoilSampleClassColorService: GetSoilSampleClassColorService,
              private languageStore: LanguageStore,
              private translationStore: TranslationStore) {
    this._fieldLayer = new ApFieldsLayer(mapStore, fieldStore$);
    this._fieldCropLayer = new ApFieldsCropLayer(mapStore, fieldStore$);
    this._fieldDescriptionLayer = new ApFieldsDescriptionLayer(mapStore, fieldStore$);
    this._sampleFieldLayer = new ApSampleFieldsLayer(mapStore);
    this._sampleFieldDescriptionLayer = new ApSampleFieldsDescriptionLayer(mapStore);
    this._locationLayer = new ApLocationLayer(mapStore);
    this._userLocationLayer = new ApUserLocationLayer(mapStore);
    this._geoPointsLayer = new ApGeoPointsLayer(mapStore);
    this._fungiDetectLayer = new ApFungiDetectLayer(mapStore);
    this._contactLayer = new ApContactLayer(mapStore);
    this._taskManagementLayer = new ApTaskManagementLayer(mapStore, fieldStore$);
    this._polygonEditLayer = new ApPolygonEditLayer(mapStore);
    this._polygonEditViewLayer = new ApPolygonEditLayer(mapStore, [], LayerNames.EDIT_VIEW);
    this._wmtsLayer = new ApWMTSLayer(mapStore);
    this._xyzLayer = new ApXYZLayer(mapStore);
    this._nutrientPlanningLayer = new MapFactoryLayer(LayerNames.NUTRIENT_PLANNINGS, LayerGroupNames.BASE_FERTILISATION, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.NutrientPlanningLegend,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }
          const rawValue = +JSON.parse(featureProperties.data)?.value;
          let value = this.roundNumericService.roundValue(rawValue);
          const selectedLegendValue = this.mapStore.Legends.NutrientPlanningLegend.selectionLists[0]?.selectedValue.getValue();
          if (!value || value?.length <= 0) {
            return undefined;
          }
          if (selectedLegendValue.transformFunction) {
            value = this.roundNumericService.round(selectedLegendValue.transformFunction(rawValue), 0);
          }
          return `${selectedLegendValue.text}: ${value} ${selectedLegendValue.suffix ?? ''}`;
        }
      });
    this._distributionLayer = new MapFactoryLayer(LayerNames.DISTRIBUTIONS, LayerGroupNames.BASE_FERTILISATION, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      { legendGetter: () => this.mapStore.Legends.NutrientDistributionLegend });
    this._soilSampleDistributionLayer = new MapFactoryLayer(LayerNames.SOIL_SAMPLE_DISTRIBUTIONS, LayerGroupNames.BASE_FERTILISATION, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      { legendGetter: () => this.mapStore.Legends.NutrientDistributionLegend });
    this._soilSampleLayer = new MapFactoryLayer(LayerNames.SOIL_SAMPLES, LayerGroupNames.BASE_FERTILISATION, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => null,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }
          const soilSampleFeature: ISoilSampleFeature = JSON.parse(featureProperties.data);
          const tooltipLines = [
            `${this.translationStore.FindTranslationForSelectedLanguage('Global__SampleDate')}: ${soilSampleFeature?.sampledate ?? '-'}`,
            `${this.translationStore.FindTranslationForSelectedLanguage('GPS_Number')}: ${soilSampleFeature?.gpsnumber ?? '-'}`,
            `${this.translationStore.FindTranslationForSelectedLanguage('Global__Nutrient_P_Short')}: ${soilSampleFeature?.p ?? 0} [${soilSampleFeature?.class_p ?? '-'}]`,
            `${this.translationStore.FindTranslationForSelectedLanguage('Global__Nutrient_K_Short')}: ${soilSampleFeature?.k ?? 0} [${soilSampleFeature?.class_k ?? '-'}]`,
            `${this.translationStore.FindTranslationForSelectedLanguage('Global__Nutrient_Mg_Short')}: ${soilSampleFeature?.mg ?? 0} [${soilSampleFeature?.class_mg ?? '-'}]`,
            `${this.translationStore.FindTranslationForSelectedLanguage('Global__Nutrient_pH_Short')}: ${soilSampleFeature?.ph ?? 0} [${soilSampleFeature?.class_ph ?? '-'}]`
          ];
          return tooltipLines.join('\n');
        }
      });
    this._soilGroupLayer = new MapFactoryLayer(LayerNames.SOIL_GROUP_MAP, LayerGroupNames.BASIC, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.SoilGroupLegend,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }
          const soilGroupFeature: ISoilGroupFeature = JSON.parse(featureProperties.data);
          return `${this.translationStore.FindTranslationForSelectedLanguage('Nutrients__Soilgroup')}: ${soilGroupFeature?.group ?? '-'}`;
        }}, LayerZIndex.SOIL_GROUPS);
    this._needLayer = new MapFactoryLayer(LayerNames.NEED, LayerGroupNames.BASE_FERTILISATION, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.NeedLegend,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }
          let rawValue = +JSON.parse(featureProperties.data)?.value;
          const mapLegendTypeElement = this.mapStore.Legends.NeedLegend.selectionLists[1]?.selectedValue.getValue();
          if (!isFinite(rawValue) || !mapLegendTypeElement?.text) {
            return undefined;
          }
          if (mapLegendTypeElement.transformFunction) {
            rawValue = mapLegendTypeElement.transformFunction(rawValue);
          }
          const value = this.roundNumericService.roundValue(rawValue);
          return `${mapLegendTypeElement.text}: ${value} ${this.mapStore.Legends.NeedLegend.unit}`;
        }
      });
    this._taskManagementNutrientLayer = new MapFactoryLayer(LayerNames.TASK_MANAGEMENT_NUTRIENT, null, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      { legendGetter: () => null });
    this._trackLayer = new MapFactoryLayer(LayerNames.TRACK, LayerGroupNames.FIELD_WORK, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.TrackLegend,
        featureFilter: f => f.getGeometry().getType() === 'LineString'
      });
    this._sensorPointLayer = new MapFactoryLayer(LayerNames.SENSOR_POINTS, LayerGroupNames.LOGFILES, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.LogLegend,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }
          const value = this.roundNumericService.round(+JSON.parse(featureProperties.data)?.value, 0);
          if (!value || value?.length <= 0) {
            return undefined;
          }
          const logPoints = this.translationStore.FindTranslationForSelectedLanguage('Global__SamplePoints');
          const nUptake = this.translationStore.FindTranslationForSelectedLanguage('NUptake');
          const unit = this.translationStore.FindTranslationForSelectedLanguage('Global__Unit_kgN_ha');
          return `${logPoints} ${nUptake}: ${value} ${unit}`;
        }
      });
    this._nRaster = new MapFactoryLayer(LayerNames.N_RASTER, LayerGroupNames.N_FERTILISATION, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.nLogInterpolationLegend,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }
          const rawValue = +JSON.parse(featureProperties.data)?.value;
          if (!isFinite(rawValue)) {
            return undefined;
          }
          const value = this.roundNumericService.roundValue(rawValue);
          const selectedLegendValue = this.mapStore.Legends.nLogInterpolationLegend.selectionLists[0]?.selectedValue.getValue();
          const text = this.translationStore.FindTranslationForSelectedLanguage(selectedLegendValue.text ?? '');
          const suffix = this.translationStore.FindTranslationForSelectedLanguage(selectedLegendValue.suffix ?? '');
          return `${text}: ${value} ${suffix}`;
        }
      });
    this._ndiLayer = new MapFactoryLayer(LayerNames.NDI, LayerGroupNames.BASIC, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.NdiLegend,
        tooltipGenerator: featureProperties => {
          const value = JSON.parse(featureProperties.data)?.ndi;
          if (!value) {
            return undefined;
          }
          return `${this.translationStore.FindTranslationForSelectedLanguage('Global__NDI_Name')}: ${value}`;
        }
      }, LayerZIndex.N_INDEX);
    this._nApplicationMapLayer = new MapFactoryLayer(LayerNames.N_APPLICATION_MAP, LayerGroupNames.N_FERTILISATION, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.NPlanningLegend,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }

          const rawValue = +JSON.parse(featureProperties.data)?.value;
          let value = this.roundNumericService.roundValue(rawValue);
          const selectedLegendValue = this.mapStore.Legends.NPlanningLegend.selectionLists[0]?.selectedValue.getValue();
          if (!value || value?.length <= 0) {
            return undefined;
          }
          if (selectedLegendValue.transformFunction) {
            value = this.roundNumericService.round(selectedLegendValue.transformFunction(rawValue), 0);
          }
          return `${selectedLegendValue.text}: ${value} ${selectedLegendValue.suffix ?? ''}`;
        }
      });
    this._nUptakeLayer = new MapFactoryLayer(LayerNames.N_UPTAKE, LayerGroupNames.BASIC, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.NUptakeLegend,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }
          const nUptake = +JSON.parse(featureProperties.data)?.nuptake;
          const tooltipValue = this.roundNumericService.round((nUptake < 0 ? 0 : nUptake), 0);
          if (!tooltipValue || tooltipValue?.length <= 0) {
            return undefined;
          }
          return `${this.translationStore.FindTranslationForSelectedLanguage('NUptake_Sat')}: ${tooltipValue}`;
        }
      }, LayerZIndex.N_UPTAKE);

    this._ppRaster = new MapFactoryLayer(LayerNames.PP_RASTER, LayerGroupNames.PLANT_PROTECTION, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.PpLogInterpolationLegend,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }
          const rawValue = +JSON.parse(featureProperties.data)?.value;
          if (!isFinite(rawValue)) {
            return undefined;
          }
          const value = this.roundNumericService.roundValue(rawValue);
          const selectedLegendValue = this.mapStore.Legends.PpLogInterpolationLegend.selectionLists[0]?.selectedValue.getValue();
          const text = this.translationStore.FindTranslationForSelectedLanguage(selectedLegendValue.text ?? '');
          const suffix = this.translationStore.FindTranslationForSelectedLanguage(selectedLegendValue.suffix ?? '');
          return `${text}: ${value} ${suffix}`;
        }
      });
    this._ppApplicationMapLayer = new MapFactoryLayer(LayerNames.PP_APPLICATION_MAP, LayerGroupNames.PLANT_PROTECTION, APP_CONFIGURATION.MapFactory.Address, '', this.mapStore,
      {
        legendGetter: () => this.mapStore.Legends.NPlanningLegend,
        tooltipGenerator: featureProperties => {
          if (!featureProperties) {
            return undefined;
          }
          const value = this.roundNumericService.roundValue(+JSON.parse(featureProperties.data)?.value);
          if (!value || value?.length <= 0) {
            return undefined;
          }
          return `${this.translationStore.FindTranslationForSelectedLanguage('Global__Plant_Protection')}: ${value}`;
        }
      });

    this._trackLayer.featureLayer.setStyle(f => {
      const props = f.getProperties();
      let url = '/assets/img/start_drop.png';
      if (props?.data && typeof props?.data === typeof '') {
        const d = JSON.parse(props.data);
        url = d.data.markerType === 'startMarker' ? '/assets/img/start_drop.png' : '/assets/img/stop_drop.png';
      }
      return new OlStyle({
        image: new OlIcon({
          anchor: [0.5, 73],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.PIXELS,
          src: url,
        }),
      });
    });

    // start the Listeners
    this.listenChangeTranslation();
    this.listenChangeVisibility();
    this.listenChangeOrder();
    this.listenChangeBackgroundMap();
    this.listenChangeLayer();
    this.listenChangeFieldDistributionUrl();
    this.listenContoursChanges();
    this.AllLayers$.pipe(filter(v => Array.isArray(v) && v.length > 0)).subscribe(() => this.loadLayerMapControlItems());
  }

  public AllLayerGroups$ = new BehaviorSubject<ILayerGroup[]>([
    {id: 100, name: LayerGroupNames.BACKGROUND, visibility: 'unchecked'},
    {id: 200, name: LayerGroupNames.BASIC, visibility: 'unchecked'},
    {id: 300, name: LayerGroupNames.BASE_FERTILISATION, visibility: 'unchecked'},
    {id: 400, name: LayerGroupNames.LOGFILES, visibility: 'unchecked'},
    {id: 500, name: LayerGroupNames.PLANT_PROTECTION, visibility: 'unchecked'},
    {id: 600, name: LayerGroupNames.SEED_MAPS, visibility: 'unchecked'},
    {id: 700, name: LayerGroupNames.FIELD_WORK, visibility: 'unchecked'},
    {id: 800, name: LayerGroupNames.TASK_MANAGEMENT, visibility: 'unchecked'},
    {id: 900, name: LayerGroupNames.FUNGIDETECT, visibility: 'unchecked'},
    {id: 1000, name: LayerGroupNames.N_FERTILISATION, visibility: 'unchecked'},
  ]);

  public AllLayers$ = new BehaviorSubject<ILayerControlLayer[]>([]);

  public get AllLayers(): ILayerControlLayer[] {
    return this.AllLayers$.getValue();
  }

  public get VisibleLegendLayer(): ILegend[] {
    const result = this.AllLayers
      .filter((layer) => (layer instanceof MapFactoryLayer)).map((layer) => layer as MapFactoryLayer)
      .filter((layer: MapFactoryLayer) => layer.Visibility && !!layer.legend())
      .map((layer: MapFactoryLayer) => layer.legend());
    if (this._fieldCropLayer.Visibility && !!this.mapStore.Legends && !!this.mapStore.Legends.CropTypeLegend) {
      result.push(this.mapStore.Legends.CropTypeLegend);
    }
    if (this._taskManagementLayer.Visibility && this.mapStore.Legends && !!this.mapStore.Legends.TaskMgmtLegend) {
      result.push(this.mapStore.Legends.TaskMgmtLegend);
    }
    return result;
  }

  public get LayerMapControlItems$(): SafeBehaviorSubject<MapLayerControlItemsMap> {
    return this.mapStore.Listen(s => s.layer.layerMapControlItems);
  }

  public get LayerMapControlItems(): MapLayerControlItemsMap {
    return this.LayerMapControlItems$.getValue();
  }

  public get ActiveLayerGroups$(): SafeBehaviorSubject<ILayerGroup[]> {
    return this.mapStore.Listen(s => s.layer.activeLayerGroups);
  }

  public get ActiveLayerGroups(): ILayerGroup[] {
    return this.ActiveLayerGroups$.getValue();
  }

  public get BackgroundMap$(): SafeBehaviorSubject<BingImagery> {
    return this.mapStore.Listen(s => s.layer.backgroundMap);
  }

  public get NdiLayer(): MapFactoryLayer {
    return this._ndiLayer;
  }

  public get SoilGroupLayer(): MapFactoryLayer {
    return this._soilGroupLayer;
  }

  public set SoilGroupLayer(value: MapFactoryLayer) {
    this._soilGroupLayer = value;
  }

  public get ContactLayer(): ApContactLayer {
    return this._contactLayer;
  }

  /**
   * get the Fungi Detect Layer
   */
  public get FungiDetectLayer(): ApFungiDetectLayer {
    return this._fungiDetectLayer;
  }

  public get NutrientPlanningLayer(): MapFactoryLayer {
    return this._nutrientPlanningLayer;
  }

  public set NutrientPlanningLayer(value: MapFactoryLayer) {
    this._nutrientPlanningLayer = value;
  }

  public get NutrientPlanningUrl(): string {
    return this.NutrientPlanningUrl$.getValue();
  }

  public get NutrientPlanningUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.nutrientPlanningUrl);
  }

  public get NPlanningUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.nPlanningUrl);
  }

  public get NApplicationMapLayer(): MapFactoryLayer {
    return this._nApplicationMapLayer;
  }

  public get NRaster(): MapFactoryLayer {
    return this._nRaster;
  }

  public get NRasterUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.nRasterUrl);
  }

  public get PpRasterUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.ppRasterUrl);
  }

  public get SensorPoints(): MapFactoryLayer {
    return this._sensorPointLayer;
  }

  public get SensorPointsUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.nSensorLogPointsUrl);
  }

  public get PpApplicationMapLayer(): MapFactoryLayer {
    return this._ppApplicationMapLayer;
  }

  public get PpRaster(): MapFactoryLayer {
    return this._ppRaster;
  }

  public get DistributionLayer(): MapFactoryLayer {
    return this._distributionLayer;
  }

  public set DistributionLayer(value: MapFactoryLayer) {
    this._distributionLayer = value;
  }

  public get SoilSampleDistributionLayer(): MapFactoryLayer {
    return this._soilSampleDistributionLayer;
  }

  public set SoilSampleDistributionLayer(value: MapFactoryLayer) {
    this._soilSampleDistributionLayer = value;
  }

  public get DistributionUrl(): string {
    return this.DistributionUrl$.getValue();
  }

  public get DistributionUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.distributionUrl);
  }

  public get SoilSampleDistributionUrl(): string {
    return this.SoilSampleDistributionUrl$.getValue();
  }

  public get SoilSampleDistributionUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.soilSampleDistributionUrl);
  }

  /**
   * get the Fields Layer
   */
  public get FieldsLayer(): ApFieldsLayer {
    return this._fieldLayer;
  }

  public get FieldsCropLayer(): ApFieldsCropLayer {
    return this._fieldCropLayer;
  }

  public get FieldDescriptionLayer(): ApFieldsDescriptionLayer {
    return this._fieldDescriptionLayer;
  }

  public get SampleFieldLayer(): ApSampleFieldsLayer {
    return this._sampleFieldLayer;
  }

  public get SampleFieldDescriptionLayer(): ApSampleFieldsDescriptionLayer {
    return this._sampleFieldDescriptionLayer;
  }

  public get NeedLayer(): MapFactoryLayer {
    return this._needLayer;
  }

  public set NeedLayer(value: MapFactoryLayer) {
    this._needLayer = value;
  }

  public get NeedUrl(): string {
    return this.NeedUrl$.getValue();
  }

  public get NeedUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.rbUrl);
  }

  public get NdiUrl(): string {
    return this.NdiUrl$.getValue();
  }

  public get NdiUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.ndiLayerUrl);
  }

  public get NUptakeLayer(): MapFactoryLayer {
    return this._nUptakeLayer;
  }

  public get NUptakeUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.nUptakeUrl);
  }

  /**
   * get the Locations Layer
   */
  public get LocationsLayer(): ApLocationLayer {
    return this._locationLayer;
  }

  /**
   * User location layer (the one that displays user/client position)
   */
  public get UserLocationLayer(): ApUserLocationLayer {
    return this._userLocationLayer;
  }

  /**
   * Layer with geo points
   */
  public get GeoPointsLayer(): ApGeoPointsLayer {
    return this._geoPointsLayer;
  }

  /**
   * get the polygon edit layer
   */
  public get PolygonEditLayer(): ApPolygonEditLayer {
    return this._polygonEditLayer;
  }

  public get PolygonEditViewLayer(): ApPolygonEditLayer {
    return this._polygonEditViewLayer;
  }

  public get SoilGroupUrl(): string {
    return this.SoilGroupUrl$.getValue();
  }

  public get SoilGroupUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.soilGroupUrl);
  }

  public get TrackUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.trackUrl);
  }

  public get TrackUrl(): string {
    return this.TrackUrl$.getValue();
  }

  /**
   * get the SoilSampleLayer
   */
  public get SoilSampleLayer(): MapFactoryLayer {
    return this._soilSampleLayer;
  }

  public set SoilSampleLayer(value: MapFactoryLayer) {
    this._soilSampleLayer = value;
  }

  public get SoilSampleUrl(): string {
    return this.SoilSampleUrl$.getValue();
  }

  public get SoilSampleUrl$(): SafeBehaviorSubject<string> {
    return this.mapStore.Listen(s => s.layer.soilSampleUrl);
  }

  /**
   * get the Task-Management Layer
   */
  public get TaskManagementLayer(): ApTaskManagementLayer {
    return this._taskManagementLayer;
  }

  /**
   * get the Task-Management nutrients Layer
   */
  public get TaskManagementNutrientLayer(): MapFactoryLayer {
    return this._taskManagementNutrientLayer;
  }

  public get TrackLayer(): MapFactoryLayer {
    return this._trackLayer;
  }

  public set TrackLayer(value: MapFactoryLayer) {
    this._trackLayer = value;
  }

  public get WMTSLayer(): ApWMTSLayer {
    return this._wmtsLayer;
  }

  public get XYZLayer(): ApXYZLayer {
    return this._xyzLayer;
  }

  /**
   * write new Field Geometries into the Field Layers with Field Model
   *
   * @param data the list of new Fields
   * @param fieldStore the fieldStore instance
   */
  public AddToFieldLayers(data: IField[], fieldStore: FieldStore): void {
    this.FieldsLayer.SyncFeatures(this.FieldsLayer.ReadFeature(data, fieldStore), LayerSyncStrategy.RESTRAINED);
    if ((this.mapStore.Editor.ActiveTool !== GeometryEditorTool.NONE)
      && (this.mapStore.Editor.ActiveTool !== GeometryEditorTool.SPLIT)) {
      // call ReadFeature again to have copy of Fields for Polygonedit Layer
      this.mapStore.Editor.InsertInPolygonEdit(this.FieldsLayer.ReadFeature(data, fieldStore));
    }
  }

  public ChangeMapFactoryLayerUrl(provider: string, map: string, farmId: number, year: number, mutate: (s: MapStore, url: string) => void): void {
    let url = '';
    if (!StringFactory.IsNullOrEmpty(provider) && !StringFactory.IsNullOrEmpty(map) && farmId > 0 && year > 0) {
      url = `/map/${provider}/${map}/{z}/{x}/{y}?params=${farmId}#${year}`;
    }
    this.tryMutateUrl(url, mutate);
  }

  public ChangeMapFactoryLayerUrlNdi(provider: string, map: string, farmId: number, year: number, mutate: (s: MapStore, url: string) => void): void {
    let url = '';
    if (!StringFactory.IsNullOrEmpty(provider) && !StringFactory.IsNullOrEmpty(map) && farmId > 0 && year > 0) {
      url = `/map/${provider}/${map}/{z}/{x}/{y}?params=${farmId}#${year}#${1}`;
    }
    this.tryMutateUrl(url, mutate);
  }

  public ChangeMapFactoryLayerUrlNUptake(provider: string, map: string, farmId: number, year: number, mutate: (s: MapStore, url: string) => void): void {
    let url = '';
    if (!StringFactory.IsNullOrEmpty(provider) && !StringFactory.IsNullOrEmpty(map) && farmId > 0 && year > 0) {
      url = `/map/${provider}/${map}/{z}/{x}/{y}?params=${farmId}#${year}#${1}`;
    }
    this.tryMutateUrl(url, mutate);
  }

  public ChangeMapFactoryLayerUrlTrack(farmId: number, machines: { machineId: string, fieldId?: string, from: string, to: string }[], mutate: (s: MapStore, url: string) => void): void {
    let url = '';
    if (farmId > 0) {
      url = `/map/pro_slice/map_slice/{z}/{x}/{y}?params=${farmId}#${JSON.stringify(machines)}`;
    }
    this.tryMutateUrl(url, mutate);
  }

  public NeedLeftLayerSourceInit(provider: string, map: string, fieldGeomIds: IGuid[]): void {
    this.changePlanningMapFactoryLayerUrl(provider, map, fieldGeomIds, (store, sourceUrl) => store.Mutate(s => s.layer.rbUrl, () => sourceUrl));
  }

  private changePlanningMapFactoryLayerUrl(provider: string, map: string, planIds: IGuid[], mutate: (s: MapStore, url: string) => void): void {
    let url = '';
    if (!StringFactory.IsNullOrEmpty(provider) && !StringFactory.IsNullOrEmpty(map) && planIds.Count() > 0) {
      url = `/map/${provider}/${map}/{z}/{x}/{y}?params=${JSON.stringify(planIds)}`;
    }
    this.tryMutateUrl(url, mutate);
  }

  /**
   * Validates the given mapFactory url and mutates the mapStore's url
   * @param url to switch to
   * @param mutate the mutation delegate
   * @private
   */
  private tryMutateUrl(url: string, mutate: (s: MapStore, url: string) => void): void {
    // APV49-4550: There are rare cases when mapFactory url contains 'undefined'
    // The reason is unknown for now. In order to get an idea of when this happens
    // ==> console error for developers for such usecases.
    if (url?.Contains('undefined')) {
      if (ApMapInstance.hasDeveloperRole) {
        console.error(`Invalid map factory url: ${url} at ${Error().stack}`);
      }
      return;
    }
    mutate(this.mapStore, url);
  }

  public NutrientPlanningLayerSourceInit(url: string, nutrient: string, planIds: IGuid[]): void {
    let provider = '';
    let map = '';
    if (url.EndsWith('nutrients/planning') || url.EndsWith('nutrients/booking') || url.EndsWith('nutrients/overview'))  {
      provider = `pro_nutrient_planning_${nutrient.ToLower()}`;
      map = `map_nutrient_planning_${nutrient.ToLower()}`;
    } else {
      return;
    }
    this.changePlanningMapFactoryLayerUrl(provider, map, planIds, (store, sourceUrl) => store.Mutate(s => s.layer.nutrientPlanningUrl, () => sourceUrl));
  }

  public NPlanningLayerSourceInit(url: string, planIds: IGuid[]): void {
    let provider = '';
    let map = '';
    if (url.Contains('/n-fertilization/')) {
      provider = `pro_n_planning`;
      map = `map_n_planning`;
    } else {
      return;
    }
    this.changeNPlanningMapFactoryLayerUrl(provider, map, planIds, (store, sourceUrl) => store.Mutate(s => s.layer.nPlanningUrl, () => sourceUrl));
  }

  private changeNPlanningMapFactoryLayerUrl(provider: string, map: string, planIds: IGuid[], mutate: (s: MapStore, url: string) => void): void {
    let url = '';
    if (!StringFactory.IsNullOrEmpty(provider) && !StringFactory.IsNullOrEmpty(map) && planIds.Count() > 0) {
      url = `/map/${provider}/${map}/{z}/{x}/{y}?params=${JSON.stringify(planIds)}`;
    }
    this.tryMutateUrl(url, mutate);
  }

  public RemoveContactLayer(): void {
    if (this.ContactLayer && typeof this.ContactLayer.clear === typeof function (): void {
    }) {
      this.ContactLayer.clear();
    }
  }

  /**
   * remove Field Geometries from Field Layers with Field Model
   */
  public RemoveFromFieldLayers(data: IField[], fieldStore: FieldStore): void {
    const tmp = this.FieldsLayer.ReadFeature(data, fieldStore);
    this.FieldsLayer.SyncFeatures(tmp, LayerSyncStrategy.DELETE);
    this.FieldDescriptionLayer.SyncFeatures(tmp, LayerSyncStrategy.DELETE);
    if ((this.mapStore.Editor.ActiveTool !== GeometryEditorTool.NONE)
      && (this.mapStore.Editor.ActiveTool !== GeometryEditorTool.SPLIT)) {
      this.mapStore.Editor.RemoveFromPolygonEditLayer(tmp);
    }
  }

  public async setActiveLayerGroups(groups: ILayerGroup[]): Promise<void> {
    groups = ObjectFactory.Copy(groups);
    for (const g of groups) {
      g.visibility = await this.getLayerGroupCheckboxState(g.name);
    }
    this.mapStore.Mutate(s => s.layer.activeLayerGroups, () => groups);
  }

  public async refreshActiveLayerGroups(layer: ILayerControlLayer): Promise<void> {
    const tmp = [];
    for (const activeGroup of ObjectFactory.Copy(this.ActiveLayerGroups)) {
      if (activeGroup.name === layer.Group) {
        activeGroup.visibility = await this.getLayerGroupCheckboxState(layer.Group);
      }
      tmp.Add(activeGroup);
    }
    this.setActiveLayerGroups(tmp).then();
  }

  public getLayerByName(name: string): ILayerControlLayer {
    if (!name) {
      return null;
    }
    return this.AllLayers.Find(l => l.name === name);
  }

  public async getLayerGroupCheckboxState(group: string): Promise<SelectAllCheckboxState> {
    return new Promise(resolve => {
      setTimeout(() => {
        const groupLayers = this.mapStore.Layers.getLayersByGroup(group);
        let allLayersChecked = true;
        let allLayersUnchecked = true;
        for (const l of groupLayers) {
          if (!l.Visibility) {
            allLayersChecked = false;
          } else {
            allLayersUnchecked = false;
          }
        }
        if (groupLayers.length < 1) {
          allLayersChecked = false;
          allLayersUnchecked = false;
        }
        resolve(allLayersChecked ? 'checked' : allLayersUnchecked ? 'unchecked' : 'indeterminate');
      }, 0);
    });
  }

  public getLayersByGroup(group: string): ILayerControlLayer[] {
    return !group ? [] : this.AllLayers.FindAll(l => l.Group === group);
  }

  private setBackgroundMap(layer: BingImagery): void {
    this.mapStore.Mutate(s => s.layer.backgroundMap, () => layer);
  }

  private listenChangeBackgroundMap(): void {
    this.mapLayerControl.onBackgroundMapChanged.subscribe(v => {
      this.setBackgroundMap(v);
    });
    // refresh the Data in the Layer Control
    this.BackgroundMap$.subscribe(() => {
      /*
      this.mapLayerControl.refresh({
        map,
        layers: this.AllLayers,
      });
       */
    });
  }

  private listenChangeFieldDistributionUrl(): void {
    this.DistributionUrl$.pipe(delay(50)).subscribe(url => {
      if (url) {
        this.colorLegendStore.setColorLegend(this.DistributionLayer, [
          {label: 'A-', color: this.getSoilSampleClassColorService.getColor('A-')},
          {label: 'A+', color: this.getSoilSampleClassColorService.getColor('A+')},
          {label: 'B-', color: this.getSoilSampleClassColorService.getColor('B-')},
          {label: 'B+', color: this.getSoilSampleClassColorService.getColor('B+')},
          {label: 'C-', color: this.getSoilSampleClassColorService.getColor('C-')},
          {label: 'C+', color: this.getSoilSampleClassColorService.getColor('C+')},
          {label: 'D-', color: this.getSoilSampleClassColorService.getColor('D-')},
          {label: 'D+', color: this.getSoilSampleClassColorService.getColor('D+')},
          {label: 'E', color: this.getSoilSampleClassColorService.getColor('E')},
        ], '', {
          component: {
            type: FieldDistributionLegendSelectorComponent.type,
            data: {
              currentNutrient: 'P',
              selectionChanged: nutrient => this.FieldDistributionLegendNutrientChange.emit(nutrient),
            },
          },
        });
        return;
      }
      this.colorLegendStore.resetColorLegend();
    });
  }

  private listenChangeLayer(): void {
    this.OnLayerChange.subscribe(() => {
      this.initBaseMaps();
    });
  }

  public initBaseMaps(): void {
    this.mapLayerControl.refresh({
      map: this.BackgroundMap$.getValue(),
      layers: this.AllLayers,
    });
  }

  private listenChangeTranslation(): void {
    combineLatest([
      this.languageStore.Listen(s => s.selectedLanguage),
      this.translationStore.Listen(s => s.loading)
    ]).subscribe(([language, translationLoading]) => {
      if (language && !translationLoading) {
        this.LocationsLayer.setUnknown(this.translationStore.FindTranslationForSelectedLanguage('Docu_Ini__Unknown'));
      }
    });
  }

  private listenChangeOrder(): void {
    this.mapLayerControl.onOrderChanged.subscribe(v => {
      const layer = this.AllLayers.Find(l => l.name === v.layer.name);
      if (!layer) {
        return;
      }
      layer.Order = v.order;
      this.OnLayerChange.emit();
    });
  }

  private listenChangeVisibility(): void {
    this.mapLayerControl.onVisibilityChanged.subscribe(v => {
      const layer = this.AllLayers.Find(l => l.name === v.layer.name);
      if (!layer) {
        return;
      }
      if (layer.name === LayerNames.FIELDS) {
        this.FieldDescriptionLayer.Visibility = v.visibility;
      }
      layer.Visibility = v.visibility;
      this.OnLayerChange.emit();
    });
  }

  /**
   * Clean up map from dynamic maps (like plans, tracks etc.)
   * static maps like distributions or soilgroups will remain
   */
  public Clear(): void {
    this.mapStore.Mutate(s => s.layer.trackUrl, () => '');
    this.mapStore.Layers.TrackLayer.clear();
    this.mapStore.Mutate(s => s.layer.nutrientPlanningUrl, () => '');
    this.mapStore.Layers.NutrientPlanningLayer.clear();
    this.mapStore.Mutate(s => s.layer.nPlanningUrl, () => '');
    this.mapStore.Layers.NApplicationMapLayer.clear();
  }

  /**
   * Resets all farm specific layers
   */
  public Reset(): void {
    this.mapStore.Mutate(s => s.layer.trackUrl, () => '');
    this.mapStore.Layers.TrackLayer.clear();
    this.mapStore.Mutate(s => s.layer.distributionUrl, () => '');
    this.mapStore.Layers.DistributionLayer.clear();
    this.mapStore.Mutate(s => s.layer.soilGroupUrl, () => '');
    this.mapStore.Layers.SoilGroupLayer.clear();
    this.mapStore.Mutate(s => s.layer.soilSampleUrl, () => '');
    this.mapStore.Layers.SoilSampleLayer.clear();
    this.mapStore.Mutate(s => s.layer.rbUrl, () => '');
    this.mapStore.Layers.NeedLayer.clear();
    this.mapStore.Mutate(s => s.layer.nutrientPlanningUrl, () => '');
    this.mapStore.Layers.NutrientPlanningLayer.clear();
    this.mapStore.Mutate(s => s.layer.nPlanningUrl, () => '');
    this.mapStore.Layers.NApplicationMapLayer.clear();
    this.mapStore.Mutate(s => s.layer.ndiLayerUrl, () => '');
    this.mapStore.Layers.NdiLayer.clear();
    this.mapStore.Mutate(s => s.layer.nUptakeUrl, () => '');
    this.mapStore.Layers.NUptakeLayer.clear();
    this.mapStore.Mutate(s => s.layer.nSensorLogPointsUrl, () => '');
    this.mapStore.Layers.SensorPoints.clear();
  }

  private listenContoursChanges(): void {
    this.mapStore.backend.registerObservable(FieldNutrientNeedChanged).subscribe(() => {
      this.mapStore.Layers.NeedLayer.reload();
    });
    this.mapStore.backend.registerObservable(FieldNutrientDistributionChanged).subscribe(() => {
      this.mapStore.Layers.DistributionLayer.reload();
    });
  }

  private loadLayerMapControlItems(): void {
    this.mapStore.Mutate<MapLayerControlItemsMap>(s => s.layer.layerMapControlItems, () => ({
      [ApMenuUrls.FIELD_MANAGEMENT_OVERVIEW]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_CROP),
        ],
      },
      [ApMenuUrls.FIELD_MANAGEMENT_CROP_ROTATION]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_CROP),
        ],
      },
      // TODO: hier müsste eigentlich Bodengruppe stehen aber den Menüpunkt gibt es gar nicht
      [ApMenuUrls.FIELD_MANAGEMENT_SOIL_SAMPLE_FIELDS]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASE_FERTILISATION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.SOIL_SAMPLE_FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.SOIL_GROUP_MAP),
          this.AllLayers.Find(l => l.name === LayerNames.SOIL_SAMPLE_DISTRIBUTIONS),
        ],
      },
      [ApMenuUrls.FIELD_MANAGEMENT_SOIL_SCANNER]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          // TODO: Anbindung des Bodenscannerlayers fehlt noch da noch keine Daten vorliegen
        ],
      },
      [ApMenuUrls.FIELD_MANAGEMENT_ELEVATION]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          // TODO: Anbindung des Höhendatenlayers fehlt noch da die Daten noch nicht vorliegen
        ],
      },
      [ApMenuUrls.FIELD_MANAGEMENT_MONITORING]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC)
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION)
        ]
      },
      [ApMenuUrls.FIELD_MANAGEMENT_SATELLITE]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.NDI),
        ],
      },
      [ApMenuUrls.FIELD_WORK_LIVE_VIEW]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.FIELD_WORK),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.LOCATIONS),
        ],
      },
      [ApMenuUrls.FIELD_WORK_OVERVIEW]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.FIELD_WORK),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.TRACK),
        ],
      },
      [ApMenuUrls.FIELD_WORK_PLANNING]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.FIELD_WORK),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.TRACK),
        ],
      },
      [ApMenuUrls.FIELD_WORK_MEASURES]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.FIELD_WORK),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.TRACK),
        ],
      },
      [ApMenuUrls.FIELD_WORK_BOOKING]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.FIELD_WORK),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.TRACK),
        ],
      },
      [ApMenuUrls.FIELD_WORK_ACTION_LOOKUP]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.FIELD_WORK),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.TRACK),
        ],
      },
      [ApMenuUrls.NUTRIENTS_OVERVIEW]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASE_FERTILISATION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.NEED),
        ],
      },
      [ApMenuUrls.NUTRIENTS_PLANNING]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASE_FERTILISATION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.NUTRIENT_PLANNINGS),
        ],
      },
      [ApMenuUrls.NUTRIENTS_LOG_FILES]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASE_FERTILISATION),
        ],
        activeLayers: [],
      },
      [ApMenuUrls.NUTRIENTS_BOOKING]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASE_FERTILISATION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.NUTRIENT_PLANNINGS),
        ],
      },
      [ApMenuUrls.NUTRIENTS_DISTRIBUTION]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASE_FERTILISATION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.DISTRIBUTIONS),
        ],
      },
      [ApMenuUrls.NUTRIENTS_P_NEED]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASE_FERTILISATION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_CROP),
        ],
      },
      [ApMenuUrls.NUTRIENTS_SOIL_SAMPLE_PLANNING_OVERVIEW]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASE_FERTILISATION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_CROP),
          this.AllLayers.Find(l => l.name === LayerNames.SOIL_SAMPLES),
        ],
      },
      [ApMenuUrls.N_FERTILIZATION_OVERVIEW]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.N_FERTILISATION)
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.N_RASTER)
        ]
      },
      [ApMenuUrls.N_FERTILIZATION_PLANNING]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.N_FERTILISATION)
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.N_APPLICATION_MAP),
        ]
      },
      [ApMenuUrls.N_FERTILIZATION_BOOKING]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.N_FERTILISATION)
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
        ]
      },
      [ApMenuUrls.N_FERTILIZATION_LOG_FILES]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.LOGFILES),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
        ],
      },
      [ApMenuUrls.N_FERTILIZATION_N_NEED]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.N_FERTILISATION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_CROP),
        ],
      },
      [ApMenuUrls.PLANT_PROTECTION_OVERVIEW]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.PLANT_PROTECTION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.PP_APPLICATION_MAP),
        ],
      },
      [ApMenuUrls.PLANT_PROTECTION_PLANNING]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.PLANT_PROTECTION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.PP_APPLICATION_MAP),
        ],
      },
      [ApMenuUrls.PLANT_PROTECTION_LOG_FILES]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.LOGFILES),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
        ],
      },
      [ApMenuUrls.PLANT_PROTECTION_BOOKING]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.PLANT_PROTECTION),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_CROP),
        ],
      },
      [ApMenuUrls.TASK_MANAGEMENT_OVERVIEW]: {
        groups: [
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.BASIC),
          this.AllLayerGroups$.getValue().Find(gl => gl.name === LayerGroupNames.TASK_MANAGEMENT),
        ],
        activeLayers: [
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS),
          this.AllLayers.Find(l => l.name === LayerNames.FIELDS_DESCRIPTION),
          this.AllLayers.Find(l => l.name === LayerNames.TASK_MANAGEMENT_NUTRIENT),
          this.AllLayers.Find(l => l.name === LayerNames.TASK_MANAGEMENT),
        ],
      },
    }));
  }
}
