import {ApBaseVectorLayer}           from './ap-base-vector.layer';
import {MapStore}                        from '../../stores/map/map.store';
import {LayerGroupNames, LayerNames}     from './layer-names';
import {Coordinate}                      from 'ol/coordinate';
import Feature                           from 'ol/Feature';
import Point                             from 'ol/geom/Point';
import Style                             from 'ol/style/Style';
import OlIcon                            from 'ol/style/Icon';
import {LayerZIndex}                     from './layer-z-index';
import {GeometryEditor}                  from '../geometry.editor';
import GeometryType                      from 'ol/geom/GeometryType';
import IGuid = System.IGuid;
import {MapInteraction, MapInteractions} from '../interactions';
import {MAP_PROJECTION}                  from './ap-map.settings';
import Modify                            from 'ol/interaction/Modify';
import {BehaviorSubject}                 from 'rxjs';
import {Translate}                       from 'ol/interaction';

export class ApGeoPointsLayer extends ApBaseVectorLayer {
  /**
   * the Projection of the Data comes from the Backend
   */
  static readonly projection: string = 'EPSG:4326';

  private modifyInteraction: Translate;
  public editLayer: ApBaseVectorLayer;
  public modifyTriggered: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(mapStore: MapStore) {
    super(LayerNames.GEOPOINTS, LayerGroupNames.NONE, mapStore, false);
    this.FixedZIndex = LayerZIndex.GEO_POINTS;

    this.editLayer = new ApBaseVectorLayer(LayerNames.GEOPOINTS + 'Edit', LayerGroupNames.NONE, mapStore, false);
    this.editLayer.FixedZIndex = LayerZIndex.GEO_POINTS;
  }

  addPoint(id: IGuid, coordinate: Coordinate, addToEditLayer: boolean = false, transformCoordinates: boolean = true): void {
    const feature = new Feature();
    feature.setId(id.toString());
    feature.setGeometry(new Point(coordinate));
    if (transformCoordinates) {
      feature.getGeometry().transform(ApGeoPointsLayer.projection, MAP_PROJECTION);
    }

    feature.setStyle(
      new Style({
        image:  new OlIcon(({
          src: addToEditLayer ? '/assets/svg/position_pin.svg' : '/assets/svg/position_pin_grey.svg',
          scale: addToEditLayer ? 1 : 0.5,
          opacity: 1,
          offset: [0, 0],
          anchor: [0.5, 0.94], // X offset in fraction (0-1) - half to the right, Y offset - full icon height to the top.
          ...(addToEditLayer && {color: 'rgba(176,203,31)'})
        }))
      }),
    );

    if (addToEditLayer) {
      this.editLayer.layer.getSource().addFeature(feature);
    } else {
      this.layer.getSource().addFeature(feature);
    }

  }

  deleteAllPoints(): void {
    this.editLayer.layer.getSource().clear();
    this.layer.getSource().clear();
  }

  activateEditMode(): void {
    this.editLayer.Visibility = true;
    this.modifyTriggered.next(false);
    this.modifyInteraction = GeometryEditor.TranslateLayerSourceFeature(GeometryType.POINT, this.editLayer.layer);
    this.modifyInteraction.on('translateend', () => {
      this.modifyTriggered.next(true);
    });
  }

  deactivateEditMode(): void {
    this.editLayer.Visibility = false;
    this.modifyInteraction?.un('translateend', () => {});
    MapInteraction.Remove(MapInteractions.TRANSLATE);
  }
}
