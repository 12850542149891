import {Injectable}         from '@angular/core';
import {SettingsStore}      from '../../stores/base-data/settings.store';
import * as moment          from 'moment-timezone';
import {Moment}             from 'moment';
import {DateTime, TimeSpan} from 'ts-tooling';
import ISettings = Data.BaseData.ISettings;

export enum DateDiffType {
  Days,
  Hours,
  Minutes,
  Seconds
}

export enum DateSort {
  None,
  Ascending,
  Descending
}

/**
 * Service for proper date/timezone handling
 */
@Injectable({providedIn: 'root'})
export class ApDateService {
  constructor(private settingsStore: SettingsStore) {
  }

  /**
   * Retrieves current UTC date and time.
   * @returns Current UTC date as JavaScript Date object
   * @public
   */
  public getUtc(): Date {
    return moment.utc().toDate();
  }

  /**
   * Gets current timestamp in milliseconds since Unix epoch.
   * @returns Number of milliseconds since January 1, 1970, 00:00:00 UTC
   * @public
   */
  public getNow(): number {
    return Date.now();
  }

  /**
   * Converts JavaScript Date object to Moment instance.
   * @param date - Date to convert
   * @returns Moment instance representing the input date
   * @public
   */
  public dateToMoment(date: Date): moment.Moment {
    return moment(date);
  }

  /**
   * Calculates time difference between two dates in specified units.
   * @param firstDate - First date for comparison
   * @param secondDate - Second date for comparison
   * @param diffType - Unit of time for difference calculation (Days/Hours/Minutes/Seconds)
   * @returns Difference between dates in specified units
   * @remarks Defaults to seconds if diffType is not recognized
   * @public
   */
  public getDateDiff(firstDate: Date, secondDate: Date, diffType: DateDiffType): number {
    switch (diffType) {
      case DateDiffType.Days:
        return this.dateToMoment(firstDate).diff(secondDate, 'days');
      case DateDiffType.Hours:
        return this.dateToMoment(firstDate).diff(secondDate, 'hours');
      case DateDiffType.Minutes:
        return this.dateToMoment(firstDate).diff(secondDate, 'minutes');
      case DateDiffType.Seconds:
        return this.dateToMoment(firstDate).diff(secondDate, 'seconds');
      default:
        return this.dateToMoment(firstDate).diff(secondDate, 'seconds');
    }
  }

  /**
   * Filters array of dates to unique values with optional sorting.
   * @param dates - Array of dates to process
   * @param sort - Sort direction (None/Ascending/Descending)
   * @returns Array of unique dates or null if input is empty
   * @remarks Dates are considered unique if they differ by at least 1 second
   * @public
   */
  public getUniqDates(dates: Date[], sort: DateSort = DateSort.None): Date[] | null {
    if (!dates || dates.length <= 0) {
      return null;
    }
    const result = dates.reduce((uniqueDates, currentDate) => {
      if (uniqueDates.length === 0) {
        return [currentDate];
      }
      if (uniqueDates.every(x => Math.abs(this.getDateDiff(x, currentDate, DateDiffType.Seconds)) > 0)) {
        uniqueDates.push(currentDate);
      }
      return uniqueDates;
    }, []);
    if (sort !== DateSort.None) {
      return result.sort((firstDate, secondDate) => {
        if (sort === DateSort.Descending) {
          return secondDate.getTime() - firstDate.getTime();
        }
        return firstDate.getTime() - secondDate.getTime();
      });
    }
    return result;
  }

  /**
   * Sets time to noon (12:00) for given date.
   * @param date - Source date
   * @param day - Optional day of month to set
   * @returns New Date object set to noon, or null if input is invalid
   * @public
   */
  public getDateNoon(date: Date, day?: number): Date {
    if (!date) {
      return null;
    }
    const dayNumber: number = day ? day : date.getDate();
    return new Date(date.getFullYear(), date.getMonth(), dayNumber, 12);
  }

  /**
   * Converts date to farm timezone and sets time to noon.
   * @param date - Source date
   * @param day - Optional day of month to set
   * @returns Date object in farm timezone set to noon, or null if input is invalid
   * @public
   */
  public getDateNoonToFarmTime(date: Date, day?: number): Date {
    const noonDate: Date = this.getDateNoon(date, day);
    if (!noonDate) {
      return null;
    }
    return this.toFarmDate(noonDate).toDate();
  }

  /**
   * Sets time to start of day (00:00:00.000) for given date.
   * @param date - Source date
   * @returns New Date object set to midnight, or null if input is invalid
   * @public
   */
  public getDateMidnight(date: Date): Date {
    if (!date) {
      return null;
    }
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  /**
   * Sets time to end of day (23:59:59.999) for given date.
   * @param date - Source date
   * @returns New Date object set to end of day, or null if input is invalid
   * @public
   */
  public getEndOfDayDate(date: Date): Date {
    if (!date) {
      return null;
    }
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return new Date(date.setHours(23, 59, 59, 999));
  }

  /**
   * Converts date to UTC timezone with optional formatting.
   * @param date - Date to convert (Date object or string)
   * @param format - Optional date format string
   * @param settings - Application settings containing timezone information
   * @returns Moment object in UTC timezone
   * @public
   */
  public getUtcDate(date: Date | string, format: string, settings: ISettings): Moment {
    if (date === null) {
      return moment.tz(date, settings.FarmTime).tz('utc');
    } else if (typeof (date) === typeof (' ')) {
      if (format) {
        return moment.tz(date as string, format, settings.FarmTime).tz('utc');
      } else {
        return moment.tz(date, settings.FarmTime).tz('utc');
      }
    } else {
      return moment.tz(date, settings.FarmTime).tz('utc');
    }
  }

  /**
   * Converts Moment object to JavaScript Date.
   * @param givenMoment - Moment object to convert
   * @returns JavaScript Date object
   * @public
   */
  public toJsDate(givenMoment: Moment): Date {
    return new Date(
      givenMoment.year(),
      givenMoment.month(),
      givenMoment.date(),
      givenMoment.hour(),
      givenMoment.minutes(),
      givenMoment.second()
    );
  }

  /**
   * Converts date to farm timezone.
   * @param date - Source date
   * @returns Moment object in farm timezone
   * @remarks Handles browser/machine timezone conversion to farm timezone
   * @public
   */
  public toFarmDate(date: Date): Moment {
    return moment(date).tz(this.settingsStore.FirstSetting.FarmTime, true);
  }

  /**
   * Converts UTC date to farm timezone.
   * @param date - UTC date (Date object or string)
   * @param format - Optional date format string
   * @returns Moment object in farm timezone
   * @public
   */
  public toFarmDateFromUtc(date: Date | string, format?: string): Moment {
    const farmTime = this.settingsStore.FirstSetting.FarmTime;
    if (date === null) {
      return moment.utc(moment()).tz(farmTime);
    } else if (typeof (date) === typeof (' ')) {
      if (format) {
        return moment(date as string, format).tz('utc', true).tz(farmTime);
      } else {
        return moment(date).tz('utc', true).tz(farmTime);
      }
    }
    return moment(date).tz('utc', true).tz(farmTime);
  }

  /**
   * Gets current date in farm timezone.
   * @returns Current date converted to farm timezone
   * @public
   */
  public getFarmDate(): Date {
    return this.toFarmDateFromUtcGetJsDate(this.getUtc());
  }

  /**
   * Converts UTC date to JavaScript Date in farm timezone.
   * @param date - UTC date (Date object or string)
   * @param format - Optional date format string
   * @returns JavaScript Date object in farm timezone
   * @public
   */
  public toFarmDateFromUtcGetJsDate(date: Date | string, format?: string): Date {
    return this.toJsDate(this.toFarmDateFromUtc(date, format));
  }

  /**
   * Converts date from farm timezone to UTC.
   * @param date - Source date (Date object or string)
   * @param format - Optional date format string
   * @returns Moment object in UTC timezone
   * @public
   */
  public toUtc(date: Date | string, format?: string): Moment {
    const farmTime = this.settingsStore.FirstSetting.FarmTime;
    if (date instanceof Date) {
      date = moment(date).format('YYYY-MM-DD HH:mm:ss');
      format = 'YYYY-MM-DD HH:mm:ss';
    }
    if (format && typeof date === 'string') {
      return moment(date, format).tz(farmTime, true).tz('utc');
    } else {
      return moment(date).tz(farmTime, true).tz('utc');
    }
  }

  /**
   * Calculates time difference between two UTC dates.
   * @param date1 - First UTC date
   * @param date2 - Second UTC date
   * @returns TimeSpan object representing absolute difference
   * @remarks Supports ISO string format (2020-01-01T00:00:00.000)
   * @public
   */
  public getNowDifferenceUTC(date1: Date | string, date2: Date | string): TimeSpan {
    if (!date1 || !date2) {
      return TimeSpan.FromMilliseconds(0);
    }
    const ds1 = this._parseDateToISOString(date1);
    const ds2 = this._parseDateToISOString(date2);
    if (!ds1 || !ds2) {
      return TimeSpan.FromMilliseconds(0);
    }

    const n = DateTime.FromISOString(ds1);
    const d = DateTime.FromISOString(ds2);
    const ms = Math.abs(n.ToUnixTimestamp() - d.ToUnixTimestamp());
    return TimeSpan.FromMilliseconds(ms);
  }

  /**
   * Creates Date object with time set from milliseconds.
   * @param timeInMilliseconds - Time in milliseconds to set
   * @returns Date object with specified time
   * @public
   */
  public getDateWithTimeFromMilliseconds(timeInMilliseconds: number): Date {
    const timeSpan = TimeSpan.FromMilliseconds(timeInMilliseconds);
    const date = new Date();
    date.setHours(timeSpan.Hour);
    date.setMinutes(timeSpan.Minute);
    date.setSeconds(timeSpan.Second);
    date.setMilliseconds(timeSpan.Millisecond);
    return date;
  }

  /**
   * Creates Date object from day/month string and year.
   * @param dateStr - Date string in format 'DD.MM'
   * @param year - Year to use for date creation
   * @returns Date object or undefined if parsing fails
   * @public
   */
  public createDateFromStringAndYear(dateStr: string, year: number): Date {
    const dateParts = dateStr.split('.');
    if (dateParts.length !== 2) {
      return undefined;
    }
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    if (isNaN(day) || isNaN(month) || month < 0 || month > 11) {
      return undefined;
    }
    const date = new Date(year, month, day);
    if (date.getDate() !== day || date.getFullYear() !== year) {
      return undefined;
    }
    return date;
  }

  /**
   * Converts date to ISO string format.
   * @param date - Date to convert (Date object or string)
   * @returns ISO string representation of date
   * @private
   */
  private _parseDateToISOString(date: Date | string): string {
    if (typeof date === typeof '') {
      return date as string;
    } else {
      return (date as Date).toISOString();
    }
  }
}
