import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
}                                                             from '@angular/core';
import {FormGroup}                                            from '@angular/forms';
import {ApDynformsControltype}                                from './config/ap-dynforms-config-base';
import * as moment                                            from 'moment';
import {ApDynformsConfigCheckbox}                             from './config/ap-dynforms-config-checkbox';
import {ApDynformsConfigComboBox}                             from './config/ap-dynforms-config-combobox';
import {ApDynformsConfigMultiSelect}                          from './config/ap-dynforms-config-multiselect';
import {ApDynformsConfigDatepicker}                           from './config/ap-dynforms-config-datepicker';
import {ApDynformsConfigNumerictextbox}                       from './config/ap-dynforms-config-numerictextbox';
import {ApDynformsConfigPlaceholder}                          from './config/ap-dynforms-config-placeholder';
import {ApDynformsConfigTextbox, ApDynformsConfigTextboxType} from './config/ap-dynforms-config-textbox';
import {ApDynformsConfigTimepicker}                           from './config/ap-dynforms-config-timepicker';
import {BehaviorSubject, combineLatest, merge, Subscription}  from 'rxjs';
import {ApDynformsConfigLabelIcon}                            from './config/ap-dynforms-config-label-icon';
import {LanguageStore}                                        from '../stores/translation/language.store';
import {ApDynformsConfigFileSelect}                           from './config/ap-dynforms-config-file-select';
import {CalendarComponent, DatePickerComponent}               from '@progress/kendo-angular-dateinputs';
import {
  CalendarView
}                                                             from '@progress/kendo-angular-dateinputs/dist/es2015/calendar/models/view.type';
import {delay, filter, map, mergeMap}                         from 'rxjs/operators';
import {TextBoxComponent}                                     from '@progress/kendo-angular-inputs';
import ILanguage = Data.Language.ILanguage;
import {ApDynformsConfigUpload}                               from './config/ap-dynforms-config-upload';

/**
 * component for a single dynamic form element/control
 */
@Component({
  selector: 'ap-dynforms-element',
  templateUrl: './ap-dynforms-element.component.html',
  styleUrls: ['./ap-dynforms-element.component.scss']
})
/**
 * component for a single dynamic form element/control
 */
export class ApDynformsElementComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
  @ViewChild(TextBoxComponent) public textBoxComponent: TextBoxComponent;
  @Input() config: ApDynformsConfigCheckbox |
    ApDynformsConfigComboBox |
    ApDynformsConfigDatepicker |
    ApDynformsConfigNumerictextbox |
    ApDynformsConfigPlaceholder |
    ApDynformsConfigTextbox |
    ApDynformsConfigMultiSelect |
    ApDynformsConfigTimepicker |
    ApDynformsConfigLabelIcon |
    ApDynformsConfigFileSelect |
    ApDynformsConfigUpload;
  @Input() form: FormGroup;
  @Input() fontSize = 16;
  @Input() darkMode = false;
  @Input() parentKey: string;
  @Input() parentIndex: number;
  @Input() asyncTranslation = false;
  protected readonly ApDynformsConfigTextboxType = ApDynformsConfigTextboxType;
  public ApDynformsControltype = ApDynformsControltype;
  public language: ILanguage;
  public disabled$ = new EventEmitter<boolean>(true);
  public label$ = new BehaviorSubject<string>('');
  public languageKey$ = this.languageStore.Listen(s => s.selectedLanguage).pipe(
    filter((l) => !!l),
    map((l) => l.Key)
  );
  public calendarView$ = new BehaviorSubject(undefined);
  public key$ = new EventEmitter<string>(true);

  private _datePickerComponent = new BehaviorSubject<DatePickerComponent>(undefined);
  private _datePickerPopupOpened = combineLatest([
    this._datePickerComponent.pipe(
      filter((d) => !!d)
    ),
    this._datePickerComponent.pipe(
      filter((d) => !!d),
      mergeMap((d) => d?.open)
    )
  ]).pipe(
    delay(1),
    map(([d]) => d?.popupRef?.popupOpen)
  );

  private _calendarComponent = new BehaviorSubject<CalendarComponent>(undefined);
  private _subscriptions: Subscription[] = [];

  /**
   * Constructor
   */
  constructor(private languageStore: LanguageStore) {
    this.language = this.languageStore.SelectedLanguage;
  }

  @ViewChild(DatePickerComponent, {static: false})
  set DatePicker(c: DatePickerComponent) {
    if (c) {
      this._datePickerComponent.next(c);
    }
  }

  ngOnInit(): void {
    this.key$.emit(this.config.key);
    this._subscriptions.push(
      combineLatest([
        this._datePickerComponent.pipe(filter((d) => !!d)),
        this._datePickerPopupOpened
      ]).pipe(
        map(([d]) => d.calendar)
      ).subscribe((c) => this._calendarComponent.next(c)));

    this._subscriptions.push(
      combineLatest([
        merge(
          this._calendarComponent.pipe(
            filter((c) => !!c),
            map((c) => c.activeView)
          ),
          this._calendarComponent.pipe(
            filter((c) => !!c),
            mergeMap((c) => c.activeViewChange),
            map((v) => v as CalendarView)
          )
        ),
        this.languageKey$
      ]).pipe(
        filter(([view, languageKey]) => !!view && !!languageKey),
      ).subscribe((v) => this.calendarView$.next(v)));
  }

  ngAfterViewInit(): void {
    if (!this.isPasswordTextBox() || !this.textBoxComponent?.input?.nativeElement) {
      return;
    }
    const textBoxConfig = this.config as ApDynformsConfigTextbox;
    if (!textBoxConfig) {
      return;
    }
    // we need to set the type explicitly here because the <input> element
    // is nested and cannot be modified in template. In later versions of kendo
    // there will be an 'inputAttributes' property on TextBoxComponent which allows
    // setting these properties in the template
    this.textBoxComponent.input.nativeElement.type = ApDynformsConfigTextboxType.Password;
    this.textBoxComponent.input.nativeElement.autocomplete = textBoxConfig.autocomplete;
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      if (this.config) {
        if (this.config.validators) {
          this.config.validators.forEach(v => {
            if (v.invalidIds) {
              v.invalidIds.next([]);
            }
          });
        }
        if (this.config.asyncValidators) {
          this.config.asyncValidators.forEach(v => {
            if (v.invalidIds) {
              v.invalidIds.next([]);
            }
          });
        }
      }
    }, 1);
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterContentInit(): void {
    if (this.config.listenUpdate.length !== 0) {
      this._subscriptions.push(combineLatest(this.config.listenUpdate).subscribe(
        () => this.form.get(this.config.key).updateValueAndValidity()
      ));
    }
    if (this.config.disabled$) {
      this._subscriptions.push(this.config.disabled$.subscribe((v) => {
        this.disabled$.emit(v);
        if (v) {
          this.form.get(this.config.key).disable();
        } else {
          this.form.get(this.config.key).enable();
        }
      }));
    }
    if (this.config.value$) {
      this._subscriptions.push(this.config.value$.subscribe((v) => {
        this.form.get(this.config.key).setValue(v);
      }));
    }

    if (this.config.label$) {
      this._subscriptions.push(this.config.label$.subscribe(v => this.currentLabelChanged(v)));
    }
  }

  /**
   * determins current validation errors(translation key)
   * for this dynamic form element/control
   */
  getErrors(): string[] {
    const validationMessages: string[] = [];
    const control = this.form.get(this.config.key);
    if (control && control.errors) {
      for (const validator of this.config.validators) {
        if ((validator.always || control.touched || control.dirty) && control.errors[validator.errorKey]) {
          validationMessages.push(validator.errorKey);
        }
      }

      for (const validator of this.config.asyncValidators) {
        if ((validator.always || control.touched || control.dirty) && control.errors[validator.errorKey]) {
          validationMessages.push(validator.errorKey);
        }
      }
    }

    if (this.form.errors) {
      for (const formErrorKey of this.config.formErrors) {
        const formError = this.form.errors[formErrorKey];
        if (formError) {
          const validatorFnKeys = Object.keys(formError);
          // Die Form Validator Errors müssen auf ihr Ergebnis überprüft werden
          // Das Ergebnis (any) kommt aus der ValidatorFn
          // Es gibt Validierungen auf Formebene, die den gleichen
          // Fehler-key benutzen, aber nicht alle gleichzeitig
          // angezeigt werden dürfen.
          // Usecase: Fehlerkey ist "ungültige Eingabe". Das kann aus unterschiedlichen
          // Gründen an mehreren Controls gesetzt werden. Würde die nachfolgende Überprüfung
          // nicht stattfinden, würde die Fehlermeldung bei alle Controls erscheinen,
          // denen dieser 'formErrors'-Key in der Config zugewiesen wurde.
          // Nun gibt es spezielle Validierungen (z.B. ApNumberRangeValidator), die als
          // Validierungsergebnis nicht einfach nur einen boolschen Wert setzen,
          // sondern ein string[] erzeugen, welches die Namen der fehlerhaften Controls enthält
          // => Nur dort soll der Fehler angezeigt werden
          for (const validatorFnKey of validatorFnKeys) {
            const validatorFnResult = formError[validatorFnKey];
            if (Array.isArray(validatorFnResult) && validatorFnResult.length > 0) {
              if (validatorFnResult.indexOf(this.config.key) > -1) {
                validationMessages.push(formErrorKey);
              }
            } else {
              // die ValidatorFn hat kein Array zurückgegeben (sondern einen beliebigen anderen Wert, z.B. true)
              validationMessages.push(formErrorKey);
            }
          }
        }
      }
    }

    return validationMessages.filter((message) => message !== '');
  }

  public GetCalendarItem(calendarView, date, format): string {
    return calendarView.pipe(
      filter((v) => !!v),
      map(([view, languageKey]) => {
        switch (view) {
          case 'year':
            return `${date.getFullYear()}`;
          case 'decade':
            return `${Math.floor(date.getFullYear() / 10) * 10}`;
          case 'century':
            return `${Math.floor(date.getFullYear() / 100) * 100}`;
        }
        return moment(date).locale(languageKey).format(format);
      })
    );
  }

  public currentLabelChanged(v: string): void {
    this.label$?.next(v);
    if (v) {
      this.config.label = v;
    } else {
      this.form.get(this.config.label)?.enable();
    }
  }

  private isPasswordTextBox(): boolean {
    return this.config.controlType === ApDynformsControltype.Textbox &&
      (this.config as ApDynformsConfigTextbox)?.type === ApDynformsConfigTextboxType.Password;
  }

  /**
   * toggles the preview (plain text) of the password input
   */
  public togglePasswordVisibility(): void {
    if (!this.isPasswordTextBox()) {
      return;
    }

    const nativePasswordTextbox = this.textBoxComponent?.input?.nativeElement;
    if (!nativePasswordTextbox) {
      return;
    }

    nativePasswordTextbox.type = nativePasswordTextbox.type === ApDynformsConfigTextboxType.Password ?
      ApDynformsConfigTextboxType.Text : ApDynformsConfigTextboxType.Password;
  }
}
