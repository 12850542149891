import {Injectable}                                           from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable}                                    from 'rxjs';
import {DefaultRemoveRequestUrl, DefaultUploadRequestUrl}     from './ap-file-upload.types';

@Injectable()
export class ApFileUploadInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === DefaultUploadRequestUrl || req.url === DefaultRemoveRequestUrl) {
      return EMPTY;
    }
    return next.handle(req);
  }
}
