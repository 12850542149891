import {AfterContentInit, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Observable, Subscription}                                            from 'rxjs';
import {IBarLineAreaChartData}                                               from '../../../ap-interface';
import {GetRoundNumericPipe}                                                 from '../../../ap-utils';
import {SettingsStore}                                                       from '../../../stores/base-data/settings.store';
import {StatisticStore}                                                      from '../../../stores/statistic/statistic.store';
import {TranslationStore}                                                    from '../../../stores/translation/translation.store';
import ISettings = Data.BaseData.ISettings;
import {SeriesType}                                                          from '@progress/kendo-angular-charts';

@Component({
  selector: 'ap-bar-line-area-chart',
  templateUrl: 'ap-bar-line-area-chart.component.html',
  styleUrls: ['ap-bar-line-area-chart.component.scss']
})
export class ApBarLineAreaChartComponent implements OnDestroy, AfterContentInit {
  @Input()
  data: IBarLineAreaChartData[];
  @Input()
  title: string;
  @Output()
  dataChange = new EventEmitter<any>();
  private subscriptions: Array<Subscription> = [];
  public types = ['bar', 'line', 'area', 'waterfall'];
  public strokeColor = '#212529';
  public inactiveColor = 'rgba(33, 37, 41, 0.4)';
  public typesFilter = this.types;
  public type: SeriesType = 'bar';
  public settings: ISettings;

  public categories: [] = [];

  public statstic: { [key: string]: IBarLineAreaChartData[] } = {};

  constructor(private settingsStore: SettingsStore,
              private roundNumericPipe: GetRoundNumericPipe,
              private translationStore: TranslationStore,
              private statisticStore: StatisticStore) {
    this.subscriptions.Add(this.settingsStore.FirstSetting$.subscribe(s => this.settings = s));
  }

  getStatisticKeys(): string[] {
    return Object.keys(this.statstic || {});
  }

  /**
   * when the component is loaded
   */
  ngAfterContentInit(): void {
    this.subscriptions.Add(this.statisticStore.Data$.subscribe(statisticData => {
      if (statisticData == null) {
        this.statstic = {};
        return;
      }
      this.statstic = {};
      for (const sd of statisticData) {
        if (!sd.chartData) {
          console.warn(`sd.data is missing: `, sd);
          continue;
        }
        if (!Object.keys(this.categories).Contains(sd.chartData.categories)) {
          this.categories = sd.chartData.categories;
        }
        if (!this.statstic[sd.chartData.category]) {
          this.statstic[sd.chartData.category] = [];
        }
        this.statstic[sd.chartData.category].Add(sd.chartData );
      }
    }));

  }

  /**
   * cleanup subscriptions
   */
  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  /**
   * on column dropdown value changed
   */
  selectedValueChanged($event): void {
    this.type = $event;
  }

  filterChange($event: string): void {
    this.typesFilter = this.types.FindAll((f: string) => f.toLowerCase().indexOf($event.toLowerCase()) !== -1);
  }

  public labelContent(e: any): string {
    if (e == null) {
      return '';
    }
    if (e.value === undefined) {
      return '';
    }
    const category = e.series.name;
    const value = this.roundNumericPipe.transform(e.value, this.settings.DigitsAfterDecimalPoint);
    const unit = this.translationStore.FindTranslationForSelectedLanguage('Base__UnitHa');
    return `${category}: ${value} ` + unit;
  }
}
