<div #apStatisticArea
     [ngStyle]="statisticsStyles">
  <ng-container *ngFor="let chart of (data$ | async)">
    <div [ngStyle]="statisticItemStyles">
      <ap-box-plot-chart *ngIf="chart.type === apChartType.Boxplot" [data]="chart.chartData" [title]="chart.title"></ap-box-plot-chart>
      <ap-bar-line-area-chart *ngIf="chart.type === apChartType.AreaBarLine" [data]="chart.chartData" [title]="chart.title"></ap-bar-line-area-chart>
      <ap-donut-pie-chart *ngIf="chart.type === apChartType.DonutPie" [data]="chart.chartData" [title]="chart.title"></ap-donut-pie-chart>
      <ap-main-second-crop-chart *ngIf="chart.type === apChartType.MainSecondCrop" [data]="chart.chartData" [title]="chart.title" [selection$]="selection$" [selected$]="selected$" (selectedChange)="yearChange($event)"></ap-main-second-crop-chart>
      <ap-datetime-selection-chart *ngIf="chart.type === apChartType.DateTimeSelection" [data]="chart.chartData" [title]="chart.title"></ap-datetime-selection-chart>
      <ap-histogram *ngIf="chart.type === apChartType.Histogram" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-histogram>
      <ap-nutrients-stats *ngIf="chart.type === apChartType.Nutrients" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-nutrients-stats>
      <ap-planning-stats *ngIf="chart.type === apChartType.Planning" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-planning-stats>
      <ap-generic-bar-chart *ngIf="chart.type === apChartType.GenericBars" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-generic-bar-chart>
      <ap-base-fert-overview-stats *ngIf="chart.type === apChartType.BaseFertStats" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-base-fert-overview-stats>
      <ap-planning-wizard-overview-stats *ngIf="chart.type === apChartType.PlanningWizardOverviewStats" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-planning-wizard-overview-stats>
      <ap-n-fertilization-logfile-stats *ngIf="chart.type === apChartType.NFertilizationLogfileStats" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-n-fertilization-logfile-stats>
      <ap-n-fertilization-planning-wizard-selection-stats *ngIf="chart.type === apChartType.NFertilizationPlanningWizardSelectionStats" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-n-fertilization-planning-wizard-selection-stats>
      <ap-n-fertilization-n-need-stats *ngIf="chart.type === apChartType.NFertilizationNNeedStats" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-n-fertilization-n-need-stats>
      <ap-fields-crop-rotation-stats *ngIf="chart.type === apChartType.FieldsCropRotationStats" [data]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-fields-crop-rotation-stats>
      <ap-dashboard-stats *ngIf="chart.type === apChartType.DashboardStats"></ap-dashboard-stats>
      <ap-generic-chart *ngIf="chart.type === apChartType.GenericChart" [chartConfigs]="chart.chartData" [title]="chart.title" [emptyMessage]="chart.emptyMessage"></ap-generic-chart>
    </div>
  </ng-container>
</div>

