export type ThumbnailParam = {
  Name: string;
  Width: number,
  Height: number,
  Quality: number,
  MimeType: string
};

export enum FileType {
  Other = 0,
  Image = 1,
  Video = 2
}
