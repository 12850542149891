export enum AgriportConstantsEnum {
  BaseFertLicenseAmountAbs = 'BF_LICENSE_AMOUNT_ABS',
  BaseFertLicenseAmountDiff = 'BF_LICENSE_AMOUNT_DIFF',
  HistogrammExcludeLimit = 'HISTOGRAMM_EXCLUDE_LIMIT',
  SatAutumnScanDate = 'SAT_AUTUMN_SCAN_DATE',
  SatRegularScanDate = 'SAT_REGULAR_SCAN_DATE',
  RegularScanUsageFactor = 'REGULAR_SCAN_USAGE_FACTOR'
}

export enum BaseFertLicenseAmountAbs {
  Hectare = '0',
  Percent = '1'
}
