import {Injectable} from '@angular/core';
import {FieldNutrientDistributionStore} from '../../stores/nutrients/field-nutrient-distributions.store';
import {TranslationStore} from '../../stores/translation/translation.store';
import {UnitsStore} from '../../stores/common/units.store';

@Injectable({providedIn: 'root'})
export class SampleMethodService {
  constructor(private fieldNutrientDistributionStore: FieldNutrientDistributionStore,
              private translationStore: TranslationStore,
              private unitsStore: UnitsStore) {
  }

  public getUnitWithMethod(method: string, translateMethod: boolean = true): string {
    const sampleMethod = this.fieldNutrientDistributionStore.SampleMethods.Find(e => e.Method === method);
    if (sampleMethod && sampleMethod.Unit) {
      const unit = this.unitsStore.getUnitWithUnit(sampleMethod.Unit);
      return `[${unit.Description}] ${translateMethod ? this.translationStore.FindTranslationForSelectedLanguage(method) : method}`;
    } else {
      return translateMethod ? this.translationStore.FindTranslationForSelectedLanguage(method) : method;
    }
  }
}
