import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
}                                                                                   from '@angular/core';
import {
  ApTranslationPipe
}                                                                                   from '../../../translation/pipes/ap-translation.pipe';
import {ApGridPagerType, IGridPagerData, IWizardChoiceData, IWizardChoiceDataValue} from '../../../ap-interface';
import {
  ApDynformsConfigFieldset
}                                                                                   from '../../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApGridMultiSelectComponent
}                                                                                   from '../ap-grid-multi-select/ap-grid-multi-select.component';
import {
  ApDynformsConfigTabs
}                                                                                   from '../../../ap-dynforms/config/ap-dynforms-config-tabs';
import {
  ItemDisabledFn
}                                                                                   from '@progress/kendo-angular-dropdowns';
import {
  PagerIconButton,
  PagerLowerButton
}                                                                                   from '../../../ap-dyngrids/config/ap-dyn-grid-pager-config';
import {PagerContextService}                                                        from '@progress/kendo-angular-grid';
import {BehaviorSubject, Observable, Subscription}                                  from 'rxjs';
import {APP_CONFIGURATION}                                                          from '../../config';

@Component({
  selector: 'ap-grid-pager',
  templateUrl: 'ap-grid-pager.component.html',
  styleUrls: ['ap-grid-pager.component.scss'],
})
export class ApGridPagerComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('gridMultiSelectComponent', {static: false}) gridMultiSelect: ApGridMultiSelectComponent;

  public selectedColumn: IWizardChoiceData;
  public selectedValue: IWizardChoiceDataValue;
  public type: ApGridPagerType;
  public values: IWizardChoiceDataValue[];
  public numOfPages = 0;
  public buttonCountOffset = 0;
  public dimensions: number[] = [1];

  @Input() public formConfig: ApDynformsConfigFieldset[] | ApDynformsConfigTabs[] = null;
  @Input() public formDefaultValue = {};
  @Input() public currentPage: number;
  /**
   * Columns shown in the select box
   */
  @Input() public columns: string[];
  /**
   * Columns shown in the select box
   */
  @Input() public objects: Observable<IWizardChoiceData[]>;
  /**
   * Indicates if default delete option is available.
   */
  @Input() public showOnlyValues = true;
  @Input() public hasMultiSelect = true;
  @Input() public showWithoutSelection = false;
  /**
   * hasPermission
   */
  @Input() public hasPermission = true;
  @Input() public selectedKeys: any[];
  @Input() public pageSize = 25;
  @Input() public itemCount: number;
  @Input() public preselect = true;
  @Input() public buttonCount = 3;
  @Input() public iconButtons: PagerIconButton[];
  @Input() public lowerButtons: PagerLowerButton[];
  @Output() public applyClicked = new EventEmitter<IGridPagerData>();
  @Output() public deleteClicked = new EventEmitter();
  @Output() public currentPageChange = new EventEmitter<number>();
  @Output() public startMultiSelectInAnimation = new EventEmitter();
  @Output() public endMultiSelectInAnimation = new EventEmitter();
  @Output() public startMultiSelectOutAnimation = new EventEmitter();
  @Output() public endMultiSelectOutAnimation = new EventEmitter();
  @Output() public selectedColumnChange = new EventEmitter<IWizardChoiceData>();
  @Output() public selectedValueChange = new EventEmitter<IWizardChoiceDataValue>();
  @Output() public pageSizeChanged = new BehaviorSubject<number>(APP_CONFIGURATION.GridPageSize);

  private _subscriptions: Subscription[] = [];
  private _objects: IWizardChoiceData[] = [];

  /**
   * constructor of component
   */
  constructor(@Inject(forwardRef(() => ApTranslationPipe)) private translationPipe: ApTranslationPipe,
              public pagerContextService: PagerContextService) {
  }

  @Input() public columnItemDisabled: ItemDisabledFn = () => false;
  @Input() public valueItemDisabled: ItemDisabledFn = () => false;

  public get Objects(): IWizardChoiceData[] {
    return this._objects;
  }

  public get filteredIconButtons(): PagerIconButton[] {
    return this.iconButtons?.filter(x => !!x.callback && !!x.icon) ?? [];
  }

  ngOnInit(): void {
    this._subscriptions.push(this.objects.subscribe((objects) => this._objects = objects));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemCount) {
      this.getDimensions();
    }
  }

  changePage(page: number): boolean {
    this.buttonCountOffset = (Math.floor(page / this.buttonCount) * this.buttonCount);
    this.currentPageChange.emit(page);
    this.getDimensions();
    return false;
  }

  onSelectedColumnChange(e: IWizardChoiceData): void {
    this.selectedColumn = e || null;
    this.selectedColumnChange.emit(e);
  }

  onSelectedValueChange(e: IWizardChoiceDataValue): void {
    this.selectedValue = e || null;
    this.selectedValueChange.emit(e);
  }

  setSelectedColumn(column: IWizardChoiceData): void {
    this.gridMultiSelect.selectedColumn = column;
    this.gridMultiSelect.selectedColumnChanged(column);
  }

  setSelectedValue(columnValue: IWizardChoiceDataValue): void {
    this.gridMultiSelect.selectedValue = columnValue;
    this.gridMultiSelect.selectedValueChanged(columnValue);
  }

  private getDimensions(): void {
    if (this.itemCount < 1 || this.pageSize < 1) {
      return;
    }
    this.numOfPages = Math.ceil(this.itemCount / this.pageSize);
    const tmp = [];
    for (let i = this.buttonCountOffset; i < this.numOfPages; i++) {
      tmp.push(i + 1);
      if (tmp.length === this.buttonCount) {
        break;
      }
    }
    this.dimensions = tmp;
  }

  /**
   * Handles pageSizeChanged event of the gridpager.
   * Occurs whenever user changed the pageSize from the corresponding dropdown
   */
  public onPageSizeChanged(pageSize: number): void {
    this.pageSizeChanged.next(pageSize);
  }
}
