import {OperationModesEnum}                                          from '../../../stores/common/operation-modes.store';
import {Injectable}                                                  from '@angular/core';
import {
  ApNFertilizationCommonService
}                                                                    from '../../../n-fertilization/services/ap-n-fertilization.common.service';
import {
  NFertilizationApplicationSettingsService
}                                                                    from './n-fertilization-application-settings.service';
import {CropTypeStore}                                               from '../../../stores/base-data/crop.types.store';
import {FieldSensorData, GetAreaWeightedAverage, GetGrowthStageData} from '../types/n-fertilization-entry-common.types';
import {ApplicationModesEnum}                                        from '../../../stores/common/appl-modes.store';
import IApplicationSettings = Data.Agronomic.IApplicationSettings;
import ISensorFieldData = Data.Sensor.ISensorFieldData;

@Injectable()
export class NFertilizationCommonEntryService {
  constructor(private cropTypeStore: CropTypeStore,
              private nFertilizationCommonService: ApNFertilizationCommonService,
              private nFertilizationApplicationSettingsService: NFertilizationApplicationSettingsService) {
  }

  // TODO refactoring
  public isRapeCrop(selectedCropIds: number[]): boolean {
    return selectedCropIds.every(cropId => [311, 312].includes(this.cropTypeStore.getDefaultCroptype(cropId)?.CropKey));
  }

  public getAreaWeightedAverage(items: GetAreaWeightedAverage[]): number {
    const selectedFieldsArea = items.map(item => ({
      Area: item?.Area,
      Average: item?.SensorData?.Average
    }));
    return this.nFertilizationCommonService.calculateAreaWeightedAverage(selectedFieldsArea);
  }

  public isApplicationMapsOrConstant(operationMode: OperationModesEnum): boolean {
    if (this.isApplicationMapOrAbsolute(operationMode)) {
      return true;
    }
    return operationMode === OperationModesEnum.Constant;
  }

  public getOperationModeForPlan(operationMode: OperationModesEnum, fieldSensorData: FieldSensorData,
                                 applicationSettings: IApplicationSettings | undefined): OperationModesEnum {
    if (applicationSettings && this.isApplicationMapOrAbsolute(operationMode)) {
      return this.nFertilizationApplicationSettingsService.isApplicationSettingValidForField(fieldSensorData.EcValue, applicationSettings)
        ? operationMode
        : OperationModesEnum.Constant;
    }
    return this.isApplicationMapsOrConstant(operationMode) && fieldSensorData.Status !== 1
      ? OperationModesEnum.Constant
      : operationMode;
  }

  public getApplicationModeForPlan(operationMode: OperationModesEnum, applicationMode: ApplicationModesEnum,
                                   fieldSensorData: FieldSensorData): ApplicationModesEnum {
    if (operationMode === OperationModesEnum.Constant
      || (this.isApplicationMapOrAbsolute(operationMode) && fieldSensorData.Status !== 1)) {
      return ApplicationModesEnum.Constant;
    }
    return applicationMode;
  }

  public isApplicationMapOrAbsolute(operationModeKey: OperationModesEnum): boolean {
    if (!operationModeKey) {
      return false;
    }
    return operationModeKey === OperationModesEnum.ApplicationMapAbsolute
      || operationModeKey === OperationModesEnum.ApplicationMap
      || operationModeKey === OperationModesEnum.Pp_Target_Map;
  }

  public nUptake(operationModeKey: OperationModesEnum, fieldSensorData: FieldSensorData): number | undefined {
    switch (operationModeKey) {
      case OperationModesEnum.ApplicationMap:
        return fieldSensorData.Average;
      case OperationModesEnum.ApplicationMapAbsolute:
        return 0;
      default:
        return undefined;
    }
  }

  public getSlope(operationModeKey: OperationModesEnum, fromValues: any, isRape: boolean,
                  applicationSettings: IApplicationSettings | undefined): number {
    if (applicationSettings && this.isApplicationMapOrAbsolute(operationModeKey)) {
      return fromValues['ControlFactor'];
    }
    return operationModeKey === OperationModesEnum.Constant
      ? 0
      : isRape ? 1 : fromValues['ControlFactor'];
  }

  public getGrowStages(items: GetGrowthStageData[]): number[] {
    const ec: number[] = [];
    items.map(item => {
      if (item?.SensorData?.EcValue != null && item.SensorData.EcValue >= 0
        && ec.every(x => x !== item.SensorData.EcValue)) {
        ec.push(item.SensorData.EcValue);
      }
      if (item?.CropData?.EcValue != null && item.CropData.EcValue >= 0
        && ec.every(x => x !== item.CropData.EcValue)) {
        ec.push(item.CropData.EcValue);
      }
    });
    return ec;
  }

  public getThreshold(growStage: number | undefined, isRape: boolean): number {
    if (isRape) {
      if (growStage == null || growStage <= 25) {
        return 25;
      }
      // EC > 25
      return 30;
    } else {
      if (growStage == null || growStage <= 25) {
        return 8;
      }
      if (growStage > 25 && growStage <= 27) {
        return 11;
      }
      // EC > 27
      return 14;
    }
  }
}
