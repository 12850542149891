import {Injectable}  from '@angular/core';
import {toByteArray} from 'base64-js';
import {FileType}    from '../../types/file.types';

@Injectable({providedIn: 'root'})
export class FileService {
  public readFileToDataUrl(file: File | undefined): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      if (!file || file.size <= 0) {
        resolve(undefined);
      }
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        resolve(event.target.result as string);
        reader.onload = null;
      };
      reader.onerror = () => {
        reject(new Error('Failed to read file'));
      };
      reader.readAsDataURL(file);
    });
  }

  public getBase64FromDataUrl(dataUrl: string): string {
    return dataUrl.split(';base64,')[1];
  }

  public getFileType(mimeType: string): FileType {
    if (mimeType.includes('video/')) {
      return FileType.Video;
    }
    if (mimeType.startsWith('image/')) {
      return FileType.Image;
    }
    return FileType.Other;
  }

  public getFileExtensionByMimeType(mimeType: string): string {
    if (!mimeType) {
      return '';
    }
    const fileExtension = mimeType.split('/').pop();
    return fileExtension ?? '';
  }

  public base64StringToFileData(fileName: string, mimeType: string, base64String?: string): File {
    const uint8Array = toByteArray(base64String);
    const blob = new Blob([uint8Array], {type: mimeType});
    return new File([blob], fileName, {type: mimeType});
  }
}
