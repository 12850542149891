import {ElementRef, Injectable}        from '@angular/core';
import {FileInfoData, PreviewFileData} from '../components/ap-file-upload/ap-file-upload.types';
import {FileState}                     from '@progress/kendo-angular-upload';
import {ApTranslationService}          from '../../ap-utils/service/ap-translation.service';

@Injectable({providedIn: 'root'})
export class FileUploadHtmlService {
  constructor(private translationService: ApTranslationService) {
  }

  public addMediaFileThumbnail(uploadComponentElementRef: ElementRef,
                               mediaFileData: PreviewFileData,
                               onClickCallback: (mediaFileData: PreviewFileData) => void): void {
    const fileHtmlElement: any = this._getFileHtmlElement(uploadComponentElementRef, mediaFileData.FileInfoData.uid);
    const childElement = fileHtmlElement?.querySelector('.k-file-group-wrapper');
    if (!childElement) {
      return;
    }
    const fileElementForPreview = document.createElement('img');
    fileElementForPreview.alt = 'thumbnail';
    fileElementForPreview.src = mediaFileData.ThumbnailDataUrl;
    fileElementForPreview.style.cssText = 'width: 50px; height: 50px; cursor: zoom-in';
    fileElementForPreview.onclick = () => onClickCallback(mediaFileData);
    childElement.replaceWith(fileElementForPreview);
  }

  public addFileIconClick(uploadComponentElementRef: ElementRef,
                          mediaFileData: PreviewFileData,
                          onClickCallback: (mediaFileData: PreviewFileData) => void): void {
    const fileHtmlElement: any = this._getFileHtmlElement(uploadComponentElementRef, mediaFileData.FileInfoData.uid);
    const fileElementForPreview = fileHtmlElement?.querySelector('.k-file-group-wrapper');
    if (!fileElementForPreview) {
      return;
    }
    fileElementForPreview.style.cssText = 'cursor: zoom-in';
    fileElementForPreview.onclick = () => onClickCallback(mediaFileData);
  }

  public addUploadDateToFile(uploadComponentElementRef: ElementRef,
                             fileInfoData: FileInfoData): void {
    const fileHtmlElement: any = this._getFileHtmlElement(uploadComponentElementRef, fileInfoData.uid);
    const fileInfoSpanContainer = fileHtmlElement?.querySelector('.k-file-name-size-wrapper');
    if (!fileInfoSpanContainer) {
      return;
    }
    const uploadedAtTranslation = this.translationService.translate('UploadedAt');
    const spanForUploadedAt = document.createElement('span');
    spanForUploadedAt.className = 'k-file-information file-upload-date';
    spanForUploadedAt.innerHTML = `${uploadedAtTranslation} ${fileInfoData.UploadDate.toLocaleDateString()}`;
    fileInfoSpanContainer.append(spanForUploadedAt);
    if (fileInfoData.state === FileState.Uploaded) {
      const fileStatusSpan = fileHtmlElement?.querySelector('.k-text-success.k-file-information');
      if (!!fileStatusSpan) {
        fileInfoSpanContainer.removeChild(fileStatusSpan);
      }
    }
  }

  public addReadyToUploadInfo(uploadComponentElementRef: ElementRef,
                              fileInfoData: FileInfoData,
                              className: string): void {
    const fileHtmlElement: any = this._getFileHtmlElement(uploadComponentElementRef, fileInfoData.uid);
    const fileNameSpan = fileHtmlElement?.querySelector('.k-file-name');
    if (!fileNameSpan) {
      return;
    }
    const spanForReadyToUpload = document.createElement('span');
    spanForReadyToUpload.className = `k-file-information ${className}`;
    spanForReadyToUpload.innerHTML = this.translationService.translate('ReadyToUpload');
    fileNameSpan.after(spanForReadyToUpload);
  }

  public addDownloadButton(uploadComponentElementRef: ElementRef,
                           mediaFileData: PreviewFileData,
                           onClickCallback: (mediaFileData: PreviewFileData) => void): void {
    const fileHtmlElement: any = this._getFileHtmlElement(uploadComponentElementRef, mediaFileData.FileInfoData.uid);
    const fileInfoSpanContainer = fileHtmlElement?.querySelector('.k-file-name-size-wrapper');
    if (!fileInfoSpanContainer) {
      return;
    }
    const downloadIcon = document.createElement('span');
    downloadIcon.className = 'download-file-icon k-icon k-i-download k-i-x';
    const downloadButton = document.createElement('button');
    downloadButton.className = 'k-button k-button-icon k-flat download-file-button';
    downloadButton.appendChild(downloadIcon);
    downloadButton.onclick = () => onClickCallback(mediaFileData);
    fileInfoSpanContainer.after(downloadButton);
  }

  public downloadButtonState(uploadComponentElementRef: ElementRef, fileId: string, isLoading: boolean): void {
    const fileHtmlElement: any = this._getFileHtmlElement(uploadComponentElementRef, fileId);
    const downloadButton = fileHtmlElement?.querySelector('.download-file-button');
    if (!downloadButton) {
      return;
    }
    downloadButton.disabled = isLoading;
  }

  public removeAllReadyToUpload(uploadComponentElementRef: ElementRef, className: string): void {
    const readyToUploadSpans = uploadComponentElementRef.nativeElement?.querySelectorAll(
      `.${className}`);
    if (!!readyToUploadSpans && readyToUploadSpans.length > 0) {
      for (const readyToUploadSpan of readyToUploadSpans) {
        readyToUploadSpan.remove();
      }
    }
  }

  private _getFileHtmlElement(uploadComponentElementRef: ElementRef, fileId: string): any {
    return uploadComponentElementRef.nativeElement?.querySelector(`[data-uid="${fileId}"]`);
  }
}
