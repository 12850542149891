import {Injectable}                  from '@angular/core';
import {ApDateService, DateDiffType} from '../../ap-core/services/ap-date-service';
import {GetRoundNumericService}      from '../../ap-utils/service/get-round-numeric.service';
import {IGridStatus}                 from '../../ap-interface/interfaces/ap-grid-status.interface';
import {ApColumnState}               from '../../ap-interface/enums/ap-column-state.enum';
import {AgriportConstantsService}    from '../../services/common/agriport-constants.service';
import {CampaignYearStore}           from '../../stores/login/campaignyear.store';
import {AgriportConstantsEnum}       from '../../ap-interface/enums/ap-agriport-constants.enum';
import {CampaignYearService}         from '../../services/data/campaign-year.service';
import INdiStatistic = Data.NDI.INdiStatistic;
import ScanType = Data.NDI.Enums.ScanType;

/**
 * Service for n-index and n-uptake calculations
 */
@Injectable({
  providedIn: 'root'
})
export class NdiService {
  constructor(private campaignYearStore: CampaignYearStore,
              private dateService: ApDateService,
              private campaignYearService: CampaignYearService,
              private roundNumericService: GetRoundNumericService,
              private agriportConstantsService: AgriportConstantsService) {
  }

  /**
   * Retrieves the regular scan date based on configured constants.
   * @returns The regular scan date or undefined if not found.
   * @public
   */
  public getRegularScanDate(): Date | undefined {
    return this._createScanDate(AgriportConstantsEnum.SatRegularScanDate);
  }

  /**
   * Retrieves the autumn scan date based on configured constants.
   * @returns The autumn scan date or undefined if not found.
   * @public
   */
  public getAutumnScanDate(): Date | undefined {
    return this._createScanDate(AgriportConstantsEnum.SatAutumnScanDate);
  }

  /**
   * Validates if N-Index statistics are suitable for calculations and further usage.
   * @param ndiStatistic - The best N-Index statistic object to validate
   * @returns Boolean indicating whether the statistics are valid
   * @remarks Validation includes checking:
   * - Creation date exists and is after year 2000
   * - N-Index status is valid (Green or Yellow)
   * @public
   */
  public isValidNIndexStatistic(ndiStatistic: INdiStatistic): boolean {
    const createdDate = !!ndiStatistic?.Created ? new Date(ndiStatistic.Created) : undefined;
    const nIndexStatus = this.getNIndexStatus(createdDate);
    const hasValidNdiData = this.isNdiStatisticDateValid(createdDate);
    const hasValidNIndexStatus = this.isNIndexStatusValid(nIndexStatus);
    return hasValidNdiData && hasValidNIndexStatus;
  }

  /**
   * Checks if the creation date of the NDI statistic is valid.
   * @param ndiStatisticCreatedDate - Date when the NDI statistic was created.
   * @returns Boolean indicating whether the date is after the year 2000.
   * @public
   */
  public isNdiStatisticDateValid(ndiStatisticCreatedDate: Date | undefined): boolean {
    return !!ndiStatisticCreatedDate ? ndiStatisticCreatedDate.getFullYear() > 2000 : false;
  }

  /**
   * Calculates cloud coverage percentage from histogram data.
   * @param histogram Key-value pairs where keys represent histogram groups and values represent counts.
   * @param totalCells Total number of cells observations for percentage calculation.
   * @returns Rounded percentage of target value (0.0) occurrences in the histogram.
   * @public
   */
  public getCloudsPercent(histogram: { [key: string]: number }, totalCells: number): number {
    const value = 0.0;
    let targetValueCounter = 0;
    for (const key of Object.keys(histogram)) {
      const histGroup = parseFloat(key);
      if (isNaN(histGroup)) {
        continue;
      }
      if (histGroup === value) {
        targetValueCounter += histogram[key];
      }
    }
    return this.roundNumericService.roundAsNumber(targetValueCounter * 100 / totalCells);
  }

  /**
   * Determines N-Index status based on data freshness from creation date.
   * @param createdDate - Creation date of best N-Index statistics
   * @returns Grid status object containing state, class, and description
   * @remarks Status rules:
   * - Grey: No creation date available
   * - Red: Data older than 14 days
   * - Yellow: Data between 7-14 days old
   * - Green: Data 7 days or newer
   * @public
   */
  public getNIndexStatus(createdDate: Date | undefined): IGridStatus {
    const dateTimeUtc = this.dateService.getUtc();
    const dateDiffInDays = Math.abs(this.dateService.getDateDiff(dateTimeUtc, createdDate, DateDiffType.Days));
    const status: IGridStatus = {state: undefined, class: undefined, description: undefined};
    if (!createdDate) {
      status.state = ApColumnState.Grey;
      status.class = 'ap-status-grey';
      status.description = 'Global__NDI_Status_Desc_grey';
    } else if (dateDiffInDays > 14) {
      status.state = ApColumnState.Red;
      status.class = 'ap-status-red';
      status.description = 'Global__NDI_Status_Desc_red';
    } else if (dateDiffInDays > 7 && dateDiffInDays <= 14) {
      status.state = ApColumnState.Yellow;
      status.class = 'ap-status-yellow';
      status.description = 'Global__NDI_Status_Desc_yellow';
    } else {
      status.state = ApColumnState.Green;
      status.class = 'ap-status-green';
      status.description = 'Global__NDI_Status_Desc_green';
    }
    return status;
  }

  /**
   * Selects best satellite scan from available field geometry scans.
   * @param ndiStatistics - Array of NDI statistics filtered by Field Geom Id
   * @returns The best matching NDI statistic or undefined if none found
   * @remarks Selection criteria:
   * 1. Campaign start to autumn scan date: Use autumn scan
   * 2. Between autumn and regular scan dates:
   *    - Use regular scan if newer than autumn scan AND
   *    - Regular scan average >= RegularScanUsageFactor * Autumn scan average
   *    - Otherwise use autumn scan
   * 3. After regular scan date to campaign end: Use regular scan
   * @public
   */
  public selectBestSatScan(ndiStatistics: INdiStatistic[] | undefined): INdiStatistic | undefined {
    if (ndiStatistics?.length <= 0) {
      return undefined;
    }
    const currentDate = this.dateService.getFarmDate();
    const currentCampaignYear = this.campaignYearStore.getSelectedCampaignYear().Year;
    const campaignYearRange = this.campaignYearService.getCampaignYearRange(currentCampaignYear);
    const satAutumnScanDate = this.getAutumnScanDate();
    const satRegularScanDate = this.getRegularScanDate();
    if (!satAutumnScanDate || !satRegularScanDate) {
      return undefined;
    }
    const latestAutumnScan = this._getLatestScanOfType(ndiStatistics, ScanType.Autumn);
    const latestRegularScan = this._getLatestScanOfType(ndiStatistics, ScanType.Regular);
    // Period 1: From campaign start till autumn scan date
    if (currentDate >= campaignYearRange.StartYear && currentDate <= satAutumnScanDate) {
      return latestAutumnScan;
    }
    // Period 2: Between autumn scan date and regular scan date
    if (currentDate > satAutumnScanDate && currentDate < satRegularScanDate) {
      // Check if both scans exist
      if (latestAutumnScan && latestRegularScan) {
        const regularScanUsageFactor = +this.agriportConstantsService.GetConstant(AgriportConstantsEnum.RegularScanUsageFactor);
        if (!Number.isFinite(regularScanUsageFactor)) {
          return undefined;
        }
        const isRegularScanNewer = latestRegularScan.Created > latestAutumnScan.Created;
        const meetsThreshold = latestRegularScan.Mean >= (regularScanUsageFactor * latestAutumnScan.Mean);
        // Compare creation dates and averages
        if (isRegularScanNewer && meetsThreshold) {
          return latestRegularScan;
        }
      }
      return latestAutumnScan;
    }
    // Period 3: After regular scan date till campaign end
    if (currentDate >= satRegularScanDate && currentDate <= campaignYearRange.EndYear) {
      return latestRegularScan;
    }
    return undefined;
  }

  /**
   * Validates if N-Index status is acceptable for operations.
   * @param nIndexStatus - Status object to validate
   * @returns Boolean indicating if status is valid
   * @remarks Only Green and Yellow states are considered valid
   * @public
   */
  public isNIndexStatusValid(nIndexStatus: IGridStatus): boolean {
    return nIndexStatus.state === ApColumnState.Green || nIndexStatus.state === ApColumnState.Yellow;
  }

  /**
   * Generates status for field satellite data.
   * @param ndiStatistic - Best NDI statistic for current field
   * @param fieldStatus - Current field status
   * @param nIndexStatus - Current N-Index status
   * @returns Grid status object containing state, class, and description
   * @remarks Status rules:
   * - Green if autumn scan and field status is green
   * - For non-autumn scans with green field status:
   *   - Green if before regular scan date
   *   - N-Index status if after regular scan date
   *   - Red for all other cases
   * @public
   */
  public getStatusForNdiRecord(ndiStatistic: INdiStatistic, fieldStatus: IGridStatus, nIndexStatus: IGridStatus): IGridStatus {
    if (ndiStatistic?.ScanType === ScanType.Autumn && fieldStatus.state === ApColumnState.Green) {
      return {state: ApColumnState.Green, class: 'ap-status-green', description: ''};
    }
    if (ndiStatistic?.ScanType !== ScanType.Autumn && fieldStatus.state === ApColumnState.Green) {
      const currentDate = this.dateService.getFarmDate();
      const satRegularScanDate = this.agriportConstantsService.GetConstant(AgriportConstantsEnum.SatRegularScanDate);
      const currentCampaignYear = this.campaignYearStore.getSelectedCampaignYear().Year;
      const regularScanDate = new Date(`${currentCampaignYear}.${satRegularScanDate}`);
      if (currentDate <= regularScanDate) {
        return {state: ApColumnState.Green, class: 'ap-status-green', description: ''};
      } else {
        return {state: nIndexStatus.state, class: nIndexStatus.class, description: ''};
      }
    }
    return {
      state: ApColumnState.Red,
      class: 'ap-status-red',
      description: ''
    };
  }

  /**
   * Creates a scan date based on the provided constant and the current campaign year.
   * @param agriportConstantsEnum - Enum value for selecting the constant.
   * @returns The generated scan date or undefined if it can't be created.
   * @private
   */
  private _createScanDate(agriportConstantsEnum: AgriportConstantsEnum.SatRegularScanDate | AgriportConstantsEnum.SatAutumnScanDate): Date | undefined {
    const currentCampaignYear = this.campaignYearStore.getSelectedCampaignYear().Year;
    const satScanDate = this.agriportConstantsService.GetConstant(agriportConstantsEnum);
    return this.dateService.createDateFromStringAndYear(satScanDate, currentCampaignYear);
  }

  /**
   * Retrieves the most recent scan of specified type from NDI statistics.
   * @param ndiStatistics - Array of NDI statistics filtered by Field Geom Id
   * @param scanType - Type of scan to filter by
   * @returns Most recent matching NDI statistic or undefined if none found
   * @remarks Expects only one scan per scanType, but includes filtering as safety measure
   * @private
   */
  private _getLatestScanOfType(ndiStatistics: INdiStatistic[], scanType: ScanType): INdiStatistic | undefined {
    const typedScans = ndiStatistics.filter(scan => scan.ScanType === scanType);
    if (typedScans.length === 0) {
      return undefined;
    }
    return typedScans.reduce((latest, current) =>
      current.Created > latest.Created ? current : latest
    );
  }
}
