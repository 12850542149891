import {BehaviorSubject}        from 'rxjs';
import {FileInfo}               from '@progress/kendo-angular-upload';
import {FileType}               from '../../../types/file.types';

export const DefaultUploadRequestUrl = 'uploadSaveUrl';
export const DefaultRemoveRequestUrl = 'uploadRemoveUrl';

export interface FileInfoData extends FileInfo {
  FileType: FileType;
  MimeType: string;
  ThumbnailFile: File | undefined;
  UploadDate: Date | undefined;
}

export type Base64FileData = {
  FileName: string;
  MimeType: string;
  Size: number;
  Base64: string | undefined;
};

export type FilePreviewModalDialogData = {
  ShowDialog: boolean,
  MediaFileData: PreviewFileData | undefined,
  ActiveIndex: number,
  Height$: BehaviorSubject<number>,
};

export type FileDownloadProcessCallback = (fileId: string, callback: (downloadedData: Base64FileData | undefined) => void) => void;

export type PreviewFileData = {
  FileInfoData: FileInfoData;
  ContentDataUrl: string | undefined,
  ThumbnailDataUrl: string | undefined,
  IsFileLoaded$: BehaviorSubject<boolean>,
  IsError$: BehaviorSubject<boolean>
};
