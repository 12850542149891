import {Directive, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Messages}                                           from '@progress/kendo-angular-upload';
import {LocalizationService}                                from '@progress/kendo-angular-l10n';
import {nameOf}                                             from '../../../ap-core/utils/ap-name-of';

@Directive({
  selector: '[fileUploadMessages]',
  providers: [
    {
      provide: Messages,
      useExisting: ApFileUploadMessagesDirective
    }
  ]
})
export class ApFileUploadMessagesDirective extends Messages implements OnInit, OnChanges {
  @Input() public fileIsNotUnique: string;

  constructor(protected localizationService: LocalizationService) {
    super();
  }

  public ngOnInit(): void {
    this._registerMessage();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes[nameOf<ApFileUploadMessagesDirective>('fileIsNotUnique')]) {
      this._registerMessage();
    }
  }

  private _registerMessage(): void {
    if (this.localizationService) {
      this.localizationService.register(nameOf<ApFileUploadMessagesDirective>('fileIsNotUnique'),
        this.fileIsNotUnique || 'Same file is already in use', true);
    }
  }
}
