import {Injectable}                   from '@angular/core';
import {FileService}                  from '../../services/common/file.service';
import {Base64FileData, FileInfoData} from '../components/ap-file-upload/ap-file-upload.types';
import {FileType}                     from '../../types/file.types';
import {FileState}                    from '@progress/kendo-angular-upload';

@Injectable({providedIn: 'root'})
export class ApFileUploadComponentService {
  constructor(private fileService: FileService) {
  }

  public createFileInfoData(id: string, uploadDate: Date | undefined,
                            content: Base64FileData, thumbnail?: Base64FileData): FileInfoData {
    const fileExtension = content.FileName.split('.').pop();
    const fileInfoData = {
      uid: id,
      name: `${content.FileName}`,
      extension: `.${fileExtension}`,
      size: content.Size,
      MimeType: content.MimeType,
      FileType: this.fileService.getFileType(content.MimeType),
      UploadDate: uploadDate
    } as FileInfoData;
    this.updateRawFileData(fileInfoData, content);
    if (!!thumbnail.MimeType && !!thumbnail.Base64) {
      const thumbnailFileExtension = this._getExtensionFromMimeType(thumbnail.MimeType);
      fileInfoData.ThumbnailFile = this.fileService.base64StringToFileData(`thumbnail.${thumbnailFileExtension}`,
        thumbnail.MimeType, thumbnail.Base64);
    } else {
      fileInfoData.ThumbnailFile = undefined;
    }
    return fileInfoData;
  }

  public updateRawFileData(fileInfoData: FileInfoData, content: Base64FileData): void {
    if (!!content.MimeType && !!content.Base64) {
      const file = this.fileService.base64StringToFileData(content.FileName, content.MimeType, content.Base64);
      fileInfoData.rawFile = file;
      fileInfoData.size = file.size;
    }
  }

  public isMediaFile(fileType: FileType): boolean {
    return fileType === FileType.Image || fileType === FileType.Video;
  }

  public isSameFileIsExist(fileToCompare: FileInfoData, compareWith: FileInfoData[]): boolean {
    if (!fileToCompare || !compareWith || compareWith.length <= 0) {
      return false;
    }
    return compareWith.some(x => {
      return fileToCompare.name === x.name
        && fileToCompare.size === x.size
        && fileToCompare.rawFile?.type === x.MimeType;
    });
  }

  public isFileHaveInitOrSelectState(fileInfoData: FileInfoData | undefined): boolean {
    return fileInfoData?.state === FileState.Initial || fileInfoData?.state === FileState.Selected;
  }

  private _getExtensionFromMimeType(mimeType: string): string {
    return mimeType.split('/')[1];
  }
}
