import {Component, Input}             from '@angular/core';
import {DomSanitizer, SafeHtml}                 from '@angular/platform-browser';
import {GenericChartConfig, GenericYAxisConfig} from './ap-generic-chart.types';

/**
 * Generic chart allows display of
 * - multiple charts
 * - each chart with multiple data series
 * - each series may have a different type (line, column, point, ...)
 * - multiple value axes to display complex data connections
 * https://www.telerik.com/kendo-angular-ui/components/charts/elements/axes
 */
@Component({
  selector: 'ap-generic-chart',
  templateUrl: 'ap-generic-chart.component.html',
  styleUrls: ['ap-generic-chart.component.scss']
})
export class ApGenericChartComponent {
  @Input() chartConfigs: Array<GenericChartConfig> = [];
  @Input() title = '';
  @Input() emptyMessage = '';
  public strokeColor = 'rgba(33, 37, 41, 1)';
  public inactiveColor = 'rgba(33, 37, 41, 0.4)';
  public backgroundColor = '#ffffffbf';

  constructor(private sanitizer: DomSanitizer) {
  }

  /**
   * method to retrieve content of the tooltip (if defined)
   * @param dataItem the current bar dataItem
   * @param chart the current chart
   */
  public getTooltip(dataItem: any, chart: GenericChartConfig): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      chart?.tooltipDelegate ? chart?.tooltipDelegate(dataItem, chart) : dataItem?.value ?? '');
  }

  /**
   * method to retrieve content of the note (if defined)
   * @param chartConfig the current chart
   */
  public getNote(chartConfig: GenericChartConfig): (p1: any) => string {
    return (item: any) => {
      if (item && chartConfig?.noteDelegate) {
        return chartConfig.noteDelegate(item?.dataItem, chartConfig);
      } else if (item && chartConfig?.noteField !== '') {
        return item?.dataItem[chartConfig?.noteField];
      }
      return '';
    };
  }

  /**
   * method to retrieve label of value axis (y-axis)
   * @param yAxisConfig the current yAxisConfig
   */
  public getLabel(yAxisConfig: GenericYAxisConfig): (p1: any) => string {
    return (item: any) => {
      if (item && yAxisConfig?.labelDelegate) {
        return yAxisConfig.labelDelegate(item?.value);
      }
      return item?.value?.toString() ?? '';
    };
  }

  /**
   * Calculates the chart's height depending on the amount of charts to be displayed.
   */
  public calculateChartHeight(): number {
    switch (this.chartConfigs?.length) {
      case 1: return 75;
      case 2: return 50;
      default: return 33;
    }
  }
}
