import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MapStore}                                                             from '../../../stores/map/map.store';
import {FormStore}                                                            from '../../../stores/layout/form.store';
import {FieldStore}                                                           from '../../../stores/farm/field.store';
import {BehaviorSubject, combineLatest, Subscription}                         from 'rxjs';
import {
  ApDynformsConfigFieldset
}                                                                             from '../../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  TranslationStore
}                                                                             from '../../../stores/translation/translation.store';
import {
  MonitoringTypeStore
}                                                                             from '../../../stores/fields/monitoring-type.store';
import {distinctUntilChanged, filter, map}                                    from 'rxjs/operators';
import {
  MonitoringDetailStore
}                                                                             from '../../../stores/fields/monitoring-detail.store';
import {MonitoringDetailEntryComponentData, MonitoringDetailEntryFormData}    from './monitoring-detail-entry.types';
import {
  CampaignYearStore
}                                                                             from '../../../stores/login/campaignyear.store';
import {
  ApDynformsComponent
}                                                                             from '../../../ap-dynforms/ap-dynforms.component';
import {ObjectFactory}                                                        from 'ts-tooling';
import {
  MonitoringFieldStore
}                                                                             from '../../../stores/fields/monitoring-field.store';
import {MonitoringDetailEntryConfig}                                          from './monitoring-detail-entry.config';
import {MapViewCurrentMenu, MapViewMode}                                      from '../../../ap-interface';
import {
  MapViewStore
}                                                                             from '../../../stores/layout/mapview.store';
import IMonitoringDetail = Data.Fields.IMonitoringDetail;
import IGuid = System.IGuid;
import IMonitoringDetailAttachment = Data.Fields.IMonitoringDetailAttachment;
import Point                                                                  from 'ol/geom/Point';
import IPoint = NetTopologySuite.Geometries.IPoint;
import {
  ApGeoPointsLayer
}                                                                             from '../../../ap-map/layers/ap-geo-points.layer';

@Component({
  selector: 'ap-monitoring-detail-entry',
  template: `
    <ap-dynforms [caption]="caption$ | async"
                 [fieldsets]="fieldSets$ | async"
                 [loading$]="loading$">
      <div class="ap-form-actions" dynforms.action>
        <button type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">
          <ap-responsive-text [key]="'Global__Cancel'"></ap-responsive-text>
        </button>
        <button type="button"
                class="k-button k-primary ap-form-button-right"
                [disabled]="(saveDisabled$ | async)"
                (click)="onSubmitClick()">
          <ap-responsive-text [key]="'Global__Save'"></ap-responsive-text>
        </button>
      </div>
    </ap-dynforms>
  `,
  styles: [],
  providers: [
    MonitoringDetailEntryConfig
  ]
})
export class ApMonitoringDetailEntryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ApDynformsComponent, {static: true})
  public dynForm: ApDynformsComponent;
  public loading$ = new BehaviorSubject<boolean>(true);
  public caption$ = new BehaviorSubject<string>('');
  public fieldSets$ = new BehaviorSubject<ApDynformsConfigFieldset[]>([]);
  public saveDisabled$ = new EventEmitter<boolean>(true);

  private _subscriptions: Subscription[] = [];
  private _initialFormData$ = new BehaviorSubject<MonitoringDetailEntryFormData | undefined>(undefined);
  private _isConfirmationWindowsShouldBeEnabled: boolean;

  constructor(private mapStore: MapStore,
              private formStore: FormStore,
              private fieldStore: FieldStore,
              private mapViewStore: MapViewStore,
              private translationStore: TranslationStore,
              private campaignYearStore: CampaignYearStore,
              private monitoringTypeStore: MonitoringTypeStore,
              private monitoringFieldStore: MonitoringFieldStore,
              private monitoringDetailStore: MonitoringDetailStore,
              private monitoringDetailEntryConfig: MonitoringDetailEntryConfig) {
  }

  public ngOnInit(): void {
    this._setLoading();
    this._formBuilder();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.dynForm.form.markAllAsTouched();
      this.mapStore.Layers.GeoPointsLayer.activateEditMode();
    }, 0);
    this._subscriptions.push(
      combineLatest([
        this._initialFormData$.asObservable(),
        this.dynForm.FormValues$.asObservable(),
        this.mapStore.Layers.GeoPointsLayer.modifyTriggered
      ]).pipe(
        filter(([initialFormData, formValues, modifyTriggered]) => !!initialFormData && !!formValues)
      ).subscribe(([initialFormData, formValues, modifyTriggered]): void => {
        const isAllEqual = Object.keys(formValues).every(key =>
          Array.isArray(formValues[key]) || typeof formValues[key] === 'object'
            ? ObjectFactory.Equal(formValues[key], initialFormData[key])
            : formValues[key] === initialFormData[key]
        );
        this._isConfirmationWindowsShouldBeEnabled = !isAllEqual;
        this.saveDisabled$.next(!this.dynForm.form.valid || (isAllEqual && !modifyTriggered));
      })
    );
    this._subscriptions.push(
      combineLatest([
        this.formStore.NewComponent$,
        this.monitoringFieldStore.MonitoringFields$
      ]).subscribe(([newComponent, monitoringFields]) => {
        const componentData = newComponent?.data as MonitoringDetailEntryComponentData;
        setTimeout(() => this.caption$.next(componentData?.MonitoringDetailId ? 'EditMonitoringDetail' : 'NewMonitoringDetail'));
        const monitoringField = monitoringFields.find(x => x.Id === componentData.MonitoringFieldId);
        if (monitoringField) {
          this.fieldStore.changeSelectedField([monitoringField.FieldId.toString()]);
          if (this.mapViewStore.getMapViewMode() !== MapViewMode.NORMAL) {
            this.mapViewStore.goIntoMapMenu();
          }
          if (this.mapViewStore.getMapViewCurrentMenu() !== MapViewCurrentMenu.MAP) {
            this.mapViewStore.goIntoMapMenu();
          }
        }
      })
    );
    this._subscriptions.push(this.dynForm?.formValueChanges.subscribe(valuesChangesArgs => {
      // retrieve monitoring id from componentData and find the matching monitoring object and its fieldId from monitoringFieldStore.
      // this is required because recommendation calculation is based on the field's current crop (eppoCode)
      const componentData = this.formStore.NewComponent$.getValue().data as MonitoringDetailEntryComponentData;
      const monitoringField = this.monitoringFieldStore.Listen(m => m.data).getValue()
        .FirstOrDefault(m => m.Id === componentData?.MonitoringFieldId);
      setTimeout(() => this.monitoringDetailEntryConfig.handleMonitoringRecommendation(
        this.dynForm,
        monitoringField?.FieldId,
        valuesChangesArgs), 0);
    }));
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(x => x?.unsubscribe());
  }

  /**
   * Handles form submission by saving data and closing the form.
   * Executes save process first to ensure data persistence before form closure.
   * @public
   */
  public onSubmitClick(): void {
    this._executeSaveProcess();
    this._closeForm();
  }

  public onCancelClick(): void {
    if (this._isConfirmationWindowsShouldBeEnabled) {
      this.monitoringDetailEntryConfig.onDeclineUnsavedChanges(() => {
        this._closeForm();
        this.monitoringDetailStore.SetLoadState();
        this.monitoringDetailStore.SetLoadFinishState();
      });
    } else {
      this._closeForm();
      this.monitoringDetailStore.SetLoadState();
      this.monitoringDetailStore.SetLoadFinishState();
    }
  }

  /**
   * Manages loading state by combining multiple store loading observables.
   * @private
   */
  private _setLoading(): void {
    this._subscriptions.push(
      combineLatest([
        this.translationStore.Loading$,
        this.campaignYearStore.Loading$,
        this.monitoringTypeStore.Loading$,
        this.monitoringFieldStore.Loading$,
        this.monitoringDetailStore.Loading$
      ]).pipe(
        map(loadings => loadings.some(loading => loading)),
        distinctUntilChanged(),
        map(loading => loading)
      ).subscribe(loading => {
        this.loading$.next(loading);
      })
    );
  }

  /**
   * Builds form configuration by combining monitoring details, types, and campaign data.
   * @private
   */
  private _formBuilder(): void {
    this._subscriptions.push(
      combineLatest([
        this.monitoringDetailStore.MonitoringDetails$,
        this.monitoringTypeStore.MonitoringTypes$,
        this.campaignYearStore.SelectedCampaignYear$,
        this.formStore.NewComponent$,
        this.fieldSets$.asObservable()
      ]).pipe(
        filter(([monitoringDetails, monitoringTypes, campaignYear, newComponent, formConfig]) => {
          const componentData = newComponent.data as MonitoringDetailEntryComponentData;
          if (monitoringTypes.length <= 0 || !campaignYear || !componentData || formConfig.length > 0) {
            return false;
          }
          return !(monitoringDetails.length <= 0 && componentData.MonitoringDetailId);
        }),
        map(([monitoringDetails, monitoringTypes, campaignYear, newComponent, _1]) => {
          const componentData = newComponent.data as MonitoringDetailEntryComponentData;
          const monitoringDetailData = monitoringDetails.find(x => x.Id === componentData.MonitoringDetailId);
          const formData = this.monitoringDetailEntryConfig.getFormData(campaignYear, monitoringDetailData, monitoringTypes);
          const formFields = this.monitoringDetailEntryConfig.generateFormConfig(formData, this.dynForm.FormValues$);
          return ([formData, formFields]);
        })
      ).subscribe(([formData, formFields]: [MonitoringDetailEntryFormData, ApDynformsConfigFieldset[]]): void => {
        this._initialFormData$.next(formData);
        this.fieldSets$.next(formFields);
      })
    );
  }

  /**
   * Executes the save process for monitoring detail data.
   * Handles both editing existing monitoring details and creating new ones:
   * - For existing details: Copies and updates the object with new values;
   * - For new details: Creates object with monitoring field ID and form values;
   * Delegates actual save operation to monitoring detail store.
   * @private
   */
  private _executeSaveProcess(): void {
    const monitoringDetailData = this.dynForm.form.getRawValue() as MonitoringDetailEntryFormData;
    const attachments = monitoringDetailData.Attachments.map(file => ({Id: file.uid as IGuid} as IMonitoringDetailAttachment));
    const componentData = this.formStore.NewComponent$.getValue().data as MonitoringDetailEntryComponentData;
    const monitoringType = this.monitoringTypeStore.MonitoringTypes.find(x => x.Id === monitoringDetailData.MonitoringTypeId);
    if (!!componentData.MonitoringDetailId) {
      const monitoringDetail = this.monitoringDetailStore.MonitoringDetails.find(x => x.Id === componentData.MonitoringDetailId);
      const copiedObject = ObjectFactory.Copy(monitoringDetail);
      copiedObject.TypeId = monitoringType.Id;
      copiedObject.Date = monitoringDetailData.Date;
      copiedObject.EcStage = monitoringDetailData.EcValue;
      copiedObject.Notes = monitoringDetailData.Notes;
      copiedObject.Value = monitoringDetailData.Value;
      copiedObject.ValueUnit = monitoringType.ValueUnit;
      copiedObject.Recommendation = monitoringDetailData.Recommendation;
      copiedObject.RecommendationUnit = monitoringType.RecommendationUnit;
      copiedObject.Attachments = attachments;
      const modifiedPoint = this.mapStore.Layers.GeoPointsLayer.editLayer.GetFeatureByIdAsJSON(ApGeoPointsLayer.projection, copiedObject.Id.toString());
      copiedObject.Position = modifiedPoint[Object.keys(modifiedPoint)[0]] as any;
      this.monitoringDetailStore.editMonitoringDetail(copiedObject);
    } else {
      const newMonitoringDetail = {
        MonitoringFieldId: componentData.MonitoringFieldId,
        TypeId: monitoringType.Id,
        Date: monitoringDetailData.Date,
        EcStage: monitoringDetailData.EcValue,
        Notes: monitoringDetailData.Notes,
        Value: monitoringDetailData.Value,
        ValueUnit: monitoringType.ValueUnit,
        Recommendation: monitoringDetailData.Recommendation,
        RecommendationUnit: monitoringType.RecommendationUnit,
        Attachments: attachments
      } as IMonitoringDetail;
      const modifiedPoint = this.mapStore.Layers.GeoPointsLayer.editLayer.GetFeatureByIdAsJSON(ApGeoPointsLayer.projection, 'NEW');
      newMonitoringDetail.Position = modifiedPoint[Object.keys(modifiedPoint)[0]] as any;
      this.monitoringDetailStore.createMonitoringDetail(newMonitoringDetail);
    }
  }

  /**
   * Closes the form with a slight delay to prevent UI issues.
   * Uses setTimeout to push closure to next event loop cycle.
   * @private
   */
  private _closeForm(): void {
    this.mapStore.Layers.GeoPointsLayer.deactivateEditMode();
    this.mapStore.Editor.CloseEditor(this.fieldStore);
    this.formStore.closeForm();
  }
}
